import { PublicFeature } from "@teylor-tools/Api";
import { Action, ConfigActions } from "../actions/configActions";

export interface ConfigState {
	currencies?: PublicFeature;
	defaultCurrency?: string;
	urlList?: PublicFeature;
}

const defaultState: ConfigState = {
	currencies: undefined,
	defaultCurrency: undefined,
	urlList: undefined,
};

const configReducer = function (state = defaultState, action: Action): ConfigState {
	switch (action.type) {
		case ConfigActions.SET_CONFIG:
			return {
				...state,
				currencies: action.currencies,
				defaultCurrency: action.currencies?.value.default,
				urlList: action.urlList,
			};
	}

	return state;
};

export default configReducer;
