import { useEffect, useState } from "react";

import styled from "styled-components";
import { Col, DatePicker, Divider, Form, Input, Modal, Radio, Row, Space } from "antd";
import Text from "antd/es/typography/Text";

import EmailFormItem from "../form/form-items/email-form-item/EmailFormItem";
import { SignatoryWithId } from "@teylor-tools/Api";
import CountrySelectFormItem from "../form/form-items/country-select-form-item/CountrySelectFormItem";
import PercentInput from "../form/inputs/percent-input/PercentInput";
import TinFormItem, { parseTin } from "../form/form-items/tin-form-item/TinFormItem";
import TelephoneFormItem from "../form/form-items/telephone-form-item/TelephoneFormItem";
import PostcodeFormItem from "../form/form-items/postcode-form-item/PostcodeFormItem";

import { rawDateFormat, dayMonthYear } from "@teylor-tools/utils/dateFormats";
import dayjs from "dayjs";

import { useTranslations } from "../translations/translations";

const SecondaryText = styled(Text)`
	font-size: 12px;
	display: block;
	margin-bottom: 16px;
`;

const FormItemHorizontal = styled(Form.Item)`
	flex-flow: row wrap !important;
	align-items: center;

	.ant-form-item-label {
		padding: 0 16px 0 0;
	}
`;

interface Props {
	save: (v: SignatoryWithId) => void;
	close: () => void;
	isVisible: boolean;
	representative?: SignatoryWithId;
}

interface RepresentativeForm extends Omit<SignatoryWithId, "birthdate"> {
	birthdate: Date;
}

const FORM = "company-representative-form";

const RepresentativeModal = ({ save, close, isVisible, representative }: Props) => {
	const t = useTranslations();
	const [form] = Form.useForm<RepresentativeForm>();
	const [showBeneficialOwnerAdditionalFields, setShowBeneficialOwnerAdditionalFields] =
		useState(false);
	const [showLegalSignatoryFields, setShowLegalSignatoryAdditionalFields] = useState(false);

	const okButtonText = representative ? t.save : t.add;
	const modalTitle = representative
		? t.representativeModal.editRepresentative
		: t.representativeModal.addRepresentative;

	const requiredFieldRule = { required: true, message: t.fieldRequired };

	const yesNoRadioItems = [
		{ label: t.yes, value: true },
		{ label: t.no, value: false },
	];

	const handleBeneficialOwnerChange = (value: boolean) =>
		setShowBeneficialOwnerAdditionalFields(value);

	const handleLegalSignatoryChange = (value: boolean) =>
		setShowLegalSignatoryAdditionalFields(value);

	const onFinish = (form: RepresentativeForm) => {
		const representative: SignatoryWithId = {
			...form,
			percentage_owned: form.percentage_owned?.toString(),
			birthdate: dayjs(form.birthdate).format(rawDateFormat),
			taxid: parseTin(form.taxid),
		};

		save(representative);

		closeModal();
	};

	const closeModal = () => {
		close();
		form.resetFields();
		setShowBeneficialOwnerAdditionalFields(false);
		setShowLegalSignatoryAdditionalFields(false);
	};

	useEffect(() => {
		if (representative) {
			form.setFieldsValue({
				...representative,
				birthdate: dayjs(representative.birthdate),
			});

			setShowBeneficialOwnerAdditionalFields(representative.is_beneficial_owner);
			setShowLegalSignatoryAdditionalFields(representative.is_legal_signatory);
		}
	}, [representative, form]);

	return (
		<>
			<Modal
				title={modalTitle}
				visible={isVisible}
				okButtonProps={{ htmlType: "submit", form: FORM }}
				onCancel={closeModal}
				okText={okButtonText}
				width={633}
				style={{ top: 24 }}
				maskClosable={false}
			>
				<Form id={FORM} onFinish={onFinish} form={form} layout="vertical">
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item
								label={t.representativeModal.firstName}
								name="first_name"
								rules={[requiredFieldRule]}
							>
								<Input />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								label={t.representativeModal.lastName}
								name="last_name"
								rules={[requiredFieldRule]}
							>
								<Input />
							</Form.Item>
						</Col>
					</Row>

					<Row gutter={16}>
						<Col span={12}>
							<EmailFormItem inputProps={{ placeholder: "" }} />
						</Col>
						<Col span={12}>
							<TelephoneFormItem
								label={t.representativeModal.phoneNumber}
								name="telephone"
								rules={[requiredFieldRule]}
							/>
						</Col>
					</Row>

					<SecondaryText type="secondary">
						{t.representativeModal.representativeNotContacted}
					</SecondaryText>

					<Row gutter={16}>
						<Col span={12}>
							<CountrySelectFormItem
								name="nationality"
								label={t.representativeModal.nationality}
								rules={[requiredFieldRule]}
								countries={t.countries}
							/>
						</Col>
						<Col span={12}>
							<Form.Item
								label={t.representativeModal.dateOfBirth}
								name="birthdate"
								rules={[
									{
										required: true,
										message: t.representativeModal.allFieldsRequired,
									},
								]}
							>
								<DatePicker
									style={{ width: "100%" }}
									placeholder={dayMonthYear}
									format={dayMonthYear}
								/>
							</Form.Item>
						</Col>
					</Row>

					<Row gutter={16}>
						<Col span={24}>
							<TinFormItem
								name="taxid"
								label={
									<Space size={4}>
										<Text>{t.representativeModal.taxNumber}</Text>
										<Text type="secondary" style={{ fontSize: 12 }}>
											{t.representativeModal.optional}
										</Text>
									</Space>
								}
								inputProps={{ placeholder: t.tinPlaceholder }}
								errorMsg={t.tinInvalid}
							/>
						</Col>
					</Row>

					<Divider />

					<Row gutter={16}>
						<Col span={12}>
							<Form.Item
								label={t.representativeModal.street}
								name="street"
								rules={[requiredFieldRule]}
							>
								<Input />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								label={t.representativeModal.houseNumber}
								name="street_number"
								rules={[requiredFieldRule]}
							>
								<Input />
							</Form.Item>
						</Col>
					</Row>

					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label={t.city} name="location" rules={[requiredFieldRule]}>
								<Input />
							</Form.Item>
						</Col>
						<Col span={12}>
							<PostcodeFormItem name="postcode" required />
						</Col>
					</Row>

					<Row gutter={16}>
						<Col span={24}>
							<CountrySelectFormItem
								label={t.country}
								rules={[requiredFieldRule]}
								countries={t.countries}
							/>
						</Col>
					</Row>

					<Divider />

					<Row gutter={24}>
						<Col span={12}>
							<FormItemHorizontal
								rules={[requiredFieldRule]}
								label={t.representativeModal.beneficialOwner}
								name="is_beneficial_owner"
							>
								<Radio.Group
									options={yesNoRadioItems}
									onChange={(e) => handleBeneficialOwnerChange(e.target.value as boolean)}
								/>
							</FormItemHorizontal>
						</Col>
						<Col span={12}>
							{showBeneficialOwnerAdditionalFields && (
								<FormItemHorizontal
									rules={[requiredFieldRule]}
									label={t.representativeModal.percentageOwnership}
									name="percentage_owned"
								>
									<PercentInput />
								</FormItemHorizontal>
							)}
						</Col>
					</Row>

					<Divider />

					<Row gutter={24}>
						<Col span={12}>
							<FormItemHorizontal
								rules={[requiredFieldRule]}
								label={t.representativeModal.legalSignatory}
								name="is_legal_signatory"
							>
								<Radio.Group
									options={yesNoRadioItems}
									onChange={(e) => handleLegalSignatoryChange(e.target.value as boolean)}
								/>
							</FormItemHorizontal>
						</Col>
						<Col span={12}>
							{showLegalSignatoryFields && (
								<>
									<FormItemHorizontal
										rules={[requiredFieldRule]}
										label={t.representativeModal.willingToSign}
										name="is_contract_signatory"
									>
										<Radio.Group options={yesNoRadioItems} />
									</FormItemHorizontal>
									<FormItemHorizontal
										rules={[requiredFieldRule]}
										label={t.representativeModal.canSignAlone}
										name="is_sole_signatory"
									>
										<Radio.Group options={yesNoRadioItems} />
									</FormItemHorizontal>
								</>
							)}
						</Col>
					</Row>
				</Form>
			</Modal>
		</>
	);
};

export default RepresentativeModal;
