import { Outlet } from "react-router-dom";

import PageHeader from "src/components/layout/page-header/PageHeader";
import { Layout, Content } from "src/components/layout/LayoutComponents";
import PageFooter from "src/components/layout/PageFooter";

const ApplicationPageLayout = () => {
	return (
		<Layout>
			<PageHeader />
			<Content>
				<Outlet />
			</Content>
			<PageFooter />
		</Layout>
	);
};

export default ApplicationPageLayout;
