import { ChangeEventHandler, useState } from "react";

import {
	AutoComplete,
	Button,
	Form,
	FormItemProps,
	Input,
	InputProps,
	Spin,
	Typography,
} from "antd";
import { ShopOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { CompanyNameSuggestion, CompanySearchResponse } from "@teylor-tools/Api";
import { AxiosInstance } from "@teylor-tools/utils/Axios";
import { useTranslations } from "../../../translations/translations";

const { Text } = Typography;

const CompanyListItem = styled.div`
	display: grid;
	grid-template-columns: auto 1fr;
	gap: 16px;
	cursor: pointer;
	padding: 12px 4px;
`;

const CompanyDetails = styled.div`
	display: flex;
	flex-direction: column;
	white-space: normal;
`;

const NotFound = styled.div`
	padding: 12px 4px;
	text-align: center;
	white-space: normal;
`;

export interface SelectedCompany {
	street: string;
	houseNumber: string;
	companyLocation: string;
	companyCountry: string;
	companyPostCode: string;
}

interface Props extends FormItemProps {
	setCompanyData: (companyData: SelectedCompany, isSelected: boolean) => void;
	axios: AxiosInstance;
	inputProps?: InputProps;
	linkColour?: string;
}

const CompanySelectFormItem = ({
	setCompanyData,
	axios,
	inputProps,
	linkColour,
	...props
}: Props) => {
	const t = useTranslations();
	const [loading, setLoading] = useState(false);
	const [firmsMatched, setFirmsMatched] = useState<CompanyNameSuggestion[]>([]);

	const getMatchedCompanies: ChangeEventHandler<HTMLInputElement> = (e) => {
		const companyName = e.target.value;

		resetCompanyData();

		if (companyName.length > 3) {
			setLoading(true);

			axios
				.get<CompanySearchResponse>(
					`/company_search?name=${encodeURIComponent(String(companyName))}`
				)
				.then(
					({ data }) => {
						if (data.companyNameSuggestions) {
							const results = data.companyNameSuggestions.slice(0, 4);

							setFirmsMatched(results);
						} else {
							setFirmsMatched([]);
						}
					},
					(err) => {
						console.error(err);
					}
				)
				.finally(() => {
					setLoading(false);
				});
		}
	};

	const onSelect = (selectedName: string) => {
		const selectedCompany = firmsMatched.find(({ name }) => name === selectedName);

		if (!selectedCompany) return resetCompanyData();

		const streetArray = selectedCompany.strasseHausnummer.split(" ");
		const houseNumber = streetArray.pop() || "";
		const street = streetArray.join().replace(/,/g, " ");

		setCompanyData(
			{
				street,
				houseNumber,
				companyPostCode: selectedCompany.plz,
				companyLocation: selectedCompany.ort,
				companyCountry: selectedCompany.land,
			},
			true
		);
	};

	const resetCompanyData = () => {
		setCompanyData(
			{
				street: "",
				houseNumber: "",
				companyPostCode: "",
				companyLocation: "",
				companyCountry: "",
			},
			false
		);
	};

	return (
		<Form.Item
			rules={[
				{
					required: true,
					min: 4,
					max: 255,
					message: t.companySelectFormItem.errorCompanyNameInvalid,
				},
			]}
			label={t.companySelectFormItem.label}
			name={"companyName"}
			{...props}
		>
			<AutoComplete
				listHeight={400}
				onSelect={onSelect}
				options={[
					...firmsMatched.map(({ name, ort, plz, strasseHausnummer, land }, index) => {
						return {
							value: name,
							label: (
								<CompanyListItem key={`suggestion-item-${index}`}>
									<ShopOutlined
										style={{
											fontSize: 25,
										}}
									/>
									<CompanyDetails>
										<Text>{name}</Text>
										<Text type="secondary">
											{strasseHausnummer}, {plz} {ort}, {land}
										</Text>
									</CompanyDetails>
								</CompanyListItem>
							),
						};
					}),
					{
						label: (
							<NotFound>
								<Text>
									{t.companySelectFormItem.errorCompanyNameNotFound}
									<Button
										type="link"
										style={{
											height: "auto",
											whiteSpace: "normal",
											padding: "4px",
											color: linkColour,
										}}
										onClick={resetCompanyData}
									>
										{t.companySelectFormItem.errorCompanyNameNotFoundLinkText}
									</Button>
								</Text>
							</NotFound>
						),
					},
				]}
			>
				<Input
					placeholder={t.companySelectFormItem.placeholder}
					suffix={
						<Spin
							style={{
								// inline-style instead of conditional rendering to prevent loosing input focus when loading state changes
								visibility: loading ? "visible" : "hidden",
							}}
						/>
					}
					onChange={getMatchedCompanies}
					{...inputProps}
				/>
			</AutoComplete>
		</Form.Item>
	);
};

export default CompanySelectFormItem;
