import { Form, Input, InputProps } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Rule } from "antd/lib/form";
import { useTranslation } from "react-i18next";

// check for special char and capital letter
const PASSWORD_REGEX = /(?=.*[^A-Za-z0-9])(?=.*[A-Z]).*(?=.*[0-9]).*/;

interface Props {
	name: string;
	label?: string;
	errorMessage?: string;
	rules?: Rule[];
	inputProps?: InputProps;
}

const InputPassword = ({ name, label, rules, inputProps }: Props) => {
	const { t } = useTranslation();

	return (
		<Form.Item
			rules={
				rules || [
					{
						type: "string",
						min: 8,
						required: true,
						message: t("error-messages.password-too-short"),
					},
					{
						pattern: PASSWORD_REGEX,
						message: t("error-messages.password-not-complex"),
					},
				]
			}
			label={label || t("form.password")}
			name={name}
		>
			<Input.Password
				maxLength={255}
				placeholder="•••••••••"
				iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
				{...inputProps}
			/>
		</Form.Item>
	);
};

export default InputPassword;
