import { Col, Form, Input, Row, Select, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import ApplicationSteps from "src/pages/application-form/components/ApplicationSteps";
import { ContentWrapper } from "src/components/layout/LayoutComponents";
import ApplicationFormCard from "src/pages/application-form/components/ApplicationFormCard";
import ApplicationFormButton from "src/pages/application-form/components/ApplicationFormButton";
import ApplicationFormBackButton from "src/pages/application-form/components/ApplicationFormBackButton";
import { Routes } from "src/Router";
import {
	CompanyDetails,
	CompanyTypes,
	useApplicationForm,
} from "src/providers/application-form-provider/useApplicationForm";
import { RootState } from "src/store/reducers/rootReducer";
import TinFormItem from "@ui/form/form-items/tin-form-item/TinFormItem";
import CountrySelectFormItem from "@ui/form/form-items/country-select-form-item/CountrySelectFormItem";
import { usePageTitle } from "src/hooks/usePageTitle";
import HouseNumberFormItem from "@ui/form/form-items/house-number-form-item/HouseNumberFormItem";
import HrNumberFormItem from "@ui/form/form-items/hr-number-form-item/HrNumberFormItem";
import StreetFormItem from "@ui/form/form-items/street-form-item/StreetFormItem";
import CityFormItem from "@ui/form/form-items/city-form-item/CityFormItem";
import PostcodeFormItem from "@ui/form/form-items/postcode-form-item/PostcodeFormItem";

const { Title, Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const companyTypes = Object.keys(CompanyTypes) as (keyof typeof CompanyTypes)[];

const ApplicationCompanyDetails = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { isAuthenticated } = useSelector((state: RootState) => state.userState);
	const { userApplicationTypes } = useSelector((state: RootState) => state.partnerState);
	const [form] = Form.useForm<CompanyDetails>();
	const { applicationForm, setApplicationForm, isSelectedCompany } = useApplicationForm();

	usePageTitle(t("pageTitle.new-application"));

	const requiredRule = {
		required: true,
		message: t("form.field-required"),
	};

	const onFinish = (companyDetails: CompanyDetails) => {
		setApplicationForm((prevState) => ({
			...prevState,
			...companyDetails,
			allBanksLoanPurpose: companyDetails.allBanksLoanPurpose || userApplicationTypes[0],
		}));
		navigate(isAuthenticated ? Routes.applicationResult : Routes.applicationAccountCreation);
	};

	return (
		<>
			<ApplicationSteps stepIndex={2} />
			<ContentWrapper>
				<ApplicationFormCard>
					<ApplicationFormBackButton path={Routes.applicationCompanyName} />
					<Space direction="vertical" size={24}>
						<Title level={3}>{t("form.company-details-title")}</Title>
						<Form form={form} layout="vertical" onFinish={onFinish} initialValues={applicationForm}>
							<Form.Item rules={[requiredRule]} label={t("form.company-type")} name="companyType">
								<Select placeholder={t("form.company-type-placeholder")}>
									{companyTypes.map((companyType) => (
										<Option key={companyType}>{t(`company-type.${companyType}`)}</Option>
									))}
								</Select>
							</Form.Item>
							{!isSelectedCompany && (
								<>
									<Row gutter={16}>
										<Col sm={12}>
											<StreetFormItem name="street" required />
										</Col>
										<Col sm={12}>
											<HouseNumberFormItem name="houseNumber" required />
										</Col>
									</Row>
									<Row gutter={16}>
										<Col sm={12}>
											<CityFormItem name="companyLocation" required />
										</Col>
										<Col sm={12}>
											<PostcodeFormItem name="companyPostCode" required />
										</Col>
									</Row>

									<CountrySelectFormItem
										name="companyCountry"
										label={t("form.country")}
										rules={[requiredRule]}
										countries={t("countries", { returnObjects: true })}
									/>
								</>
							)}
							<HrNumberFormItem name="hrNumber" required />
							{userApplicationTypes.length > 1 && (
								<Form.Item
									name="allBanksLoanPurpose"
									label={t("application.loanType")}
									rules={[requiredRule]}
								>
									<Select>
										{userApplicationTypes.map((type) => (
											<Select.Option key={type} value={type}>
												{t(`allBanksLoanPurposes.${type}`)}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							)}
							<Form.Item
								rules={[
									{
										min: 5,
										max: 200,
										message: t("form.field-invalid"),
									},
									requiredRule,
								]}
								label={t("form.business-purpose")}
								name="businessPurpose"
							>
								<Input maxLength={200} placeholder={t("form.business-purpose-placeholder")} />
							</Form.Item>
							<Form.Item
								rules={[
									{
										min: 30,
										max: 32000,
										message: t("form.field-invalid"),
									},
									requiredRule,
								]}
								label={
									<Space size={4}>
										<Text>{t("form.detailed-loan-purpose")}</Text>
										<Text type="secondary">{t("form.detailed-loan-purpose-limit")}</Text>
									</Space>
								}
								name="purposeDescription"
							>
								<TextArea
									rows={6}
									maxLength={32000}
									placeholder={t("form.detailed-loan-purpose-placeholder")}
								/>
							</Form.Item>
							<TinFormItem
								name={"taxIdNumber"}
								label={
									<Space size={4}>
										<Text>{t("form.tax-number")}</Text>
										<Text type="secondary" style={{ fontSize: 12 }}>
											{t("form.optional")}
										</Text>
									</Space>
								}
								inputProps={{ placeholder: t("form.tax-number-placeholder") }}
								errorMsg={t("form.field-invalid")}
							/>
							<Row>
								<Col
									span={24}
									style={{
										textAlign: "center",
									}}
								>
									<ApplicationFormButton type="primary" size="large" htmlType="submit">
										{t("next")}
									</ApplicationFormButton>
								</Col>
							</Row>
						</Form>
					</Space>
				</ApplicationFormCard>
			</ContentWrapper>
		</>
	);
};

export default ApplicationCompanyDetails;
