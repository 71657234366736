import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";

import styled from "styled-components";
import { Tag as AntdTag, Typography } from "antd";
import dayjs from "dayjs";

import ThemedButton from "src/components/themed-ui/ThemedButton";
import { ApplicationStatuses, UserApplication } from "@teylor-tools/Api";
import { Routes } from "src/Router";

import { getStatus, TAG_COLORS } from "../utils/status";
import { yearMonthDay } from "@teylor-tools/utils/dateFormats";
import { useFormatter } from "@teylor-tools/hooks/formatter";

const { Text } = Typography;

const ApplicationDetails = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const ApplicationDetailsTop = styled.div`
	display: flex;
	align-items: center;
`;

const AplicationDetailsBottom = styled.div`
	.ant-typography {
		color: rgba(0, 0, 0, 0.55);
	}
`;

const Tag = styled(AntdTag)`
	border-radius: 50px;
`;

const Amount = styled(Text)`
	font-size: 24px;
	font-weight: 600;
	margin-right: 16px;
`;

const Date = styled(Text)`
	font-weight: 600;
	margin-right: 16px;
`;

const ActionButton = ({ status, id }: { status: ApplicationStatuses; id: string }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const btnText =
		status === ApplicationStatuses.Open ? t("dashboard.complete-application") : t("dashboard.view");
	const btnType =
		status === ApplicationStatuses.Open || status === ApplicationStatuses.Signature
			? "primary"
			: "ghost";

	return (
		<ThemedButton
			type={btnType}
			onClick={() => navigate(generatePath(Routes.dashboardApplicationOverview, { id }))}
		>
			{btnText}
		</ThemedButton>
	);
};

const ApplicationListItem = ({ application }: { application: UserApplication }) => {
	const { t } = useTranslation();
	const { currency } = useFormatter(application.currency);

	const { applicationId, shortApplicationId, status, created, loanSize } = application;

	if (!applicationId || !shortApplicationId || !status || !created || !loanSize) {
		return null;
	}

	const applicationStatus = getStatus(status);

	return (
		<ApplicationDetails>
			<div>
				<ApplicationDetailsTop>
					<Amount>{currency(loanSize, { showFractions: false })}</Amount>
					<Tag color={TAG_COLORS[applicationStatus]}>
						{t(`application-status.${applicationStatus}`)}
					</Tag>
				</ApplicationDetailsTop>
				<AplicationDetailsBottom>
					<Date>{dayjs(created).utc().format(yearMonthDay)}</Date>{" "}
					<Text>ID: {shortApplicationId}</Text>
				</AplicationDetailsBottom>
			</div>
			<ActionButton status={status} id={applicationId} />
		</ApplicationDetails>
	);
};

export default ApplicationListItem;
