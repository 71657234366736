import { createStore, Store } from "redux";
import rootReducer from "./reducers/rootReducer";

let store: Store;
if (process.env.NODE_ENV !== "production") {
	// conditional imports are restricted at typscript configuration
	// eslint-disable-next-line
	const { composeWithDevToolsDevelopmentOnly } = require("@redux-devtools/extension");
	// eslint-disable-next-line
	store = createStore(rootReducer, composeWithDevToolsDevelopmentOnly());
} else {
	store = createStore(rootReducer);
}

export default store;
