import { Col, Form, Row, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import ApplicationSteps from "src/pages/application-form/components/ApplicationSteps";
import { ContentWrapper } from "src/components/layout/LayoutComponents";
import ApplicationFormCard from "src/pages/application-form/components/ApplicationFormCard";
import ApplicationFormButton from "src/pages/application-form/components/ApplicationFormButton";
import { Routes } from "src/Router";
import {
	LoanDetails,
	useApplicationForm,
} from "src/providers/application-form-provider/useApplicationForm";
import { usePageTitle } from "src/hooks/usePageTitle";
import LoanSlider from "src/pages/application-form/components/LoanSlider";
import { useSelector } from "react-redux";
import { RootState } from "src/store/reducers/rootReducer";
import { Entitlements } from "@teylor-tools/Api";

const { Title } = Typography;

const ApplicationLoanInfo = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [form] = Form.useForm();
	const { entitlements } = useSelector((state: RootState) => state.partnerState);

	usePageTitle(t("pageTitle.new-application"));

	const {
		applicationForm: { loanSize, loanDuration },
		setApplicationForm,
	} = useApplicationForm();

	const disableSliderInput = !entitlements.includes(Entitlements.CustomizedApplicationAmounts);

	const onFinish = ({ loanSize, loanDuration }: LoanDetails) => {
		setApplicationForm((prevState) => ({ ...prevState, loanSize, loanDuration }));
		navigate(Routes.applicationCompanyName);
	};

	return (
		<>
			<ApplicationSteps stepIndex={0} />
			<ContentWrapper>
				<ApplicationFormCard>
					<Space direction="vertical" size={24}>
						<Title level={3} data-cy="loan-information-title">
							{t("form.loan-info-title")}
						</Title>
						<Form form={form} layout="vertical" onFinish={onFinish}>
							<LoanSlider
								type="LoanAmount"
								initialValue={loanSize}
								name="loanSize"
								disableInput={disableSliderInput}
							/>
							<LoanSlider
								type="LoanDuration"
								initialValue={loanDuration}
								name="loanDuration"
								disableInput={disableSliderInput}
							/>
							<Row>
								<Col
									span={24}
									style={{
										textAlign: "center",
									}}
								>
									<ApplicationFormButton type="primary" size="large" htmlType="submit">
										{t("next")}
									</ApplicationFormButton>
								</Col>
							</Row>
						</Form>
					</Space>
				</ApplicationFormCard>
			</ContentWrapper>
		</>
	);
};

export default ApplicationLoanInfo;
