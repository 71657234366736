import { useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import styled from "styled-components";
import { Breadcrumb, message, Spin, Tabs as AntdTabs, Tooltip, Typography } from "antd";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import {
	Application,
	ApplicationStatuses,
	PendingActionType,
	SignatoriesResponse,
	SignatoryRequirements,
	SignatoryWithId,
	StatusPendingAction,
	UpdateApplicationStatusRequestBody,
	User,
	UserApplication,
} from "@teylor-tools/Api";

import ThemedButton from "src/components/themed-ui/ThemedButton";
import { ContentWrapper } from "src/components/layout/LayoutComponents";
import Tag from "src/components/Tag";
import { Routes } from "src/Router";
import { getStatus, Status, TAG_COLORS } from "./utils/status";
import ApplicationOverview from "./components/ApplicationOverview";
import Signatures from "./components/Signatures";
import { Axios, ErrorResponse } from "src/utils/Axios";
import CompanyRepresentativesList from "src/pages/dashboard/components/CompanyRepresentativesList";
import DocumentsUpload from "src/pages/dashboard/components/DocumentsUpload/DocumentsUpload";
import { lgScreen, maxWidth } from "src/breakpoints";
import Error from "src/components/Error";
import { RootState } from "src/store/reducers/rootReducer";
import { getProfile } from "src/utils/api/authentication";
import SubmitConfirmationModal from "src/pages/dashboard/components/SubmitConfirmationModal";
import { usePageTitle } from "src/hooks/usePageTitle";
import Alert from "src/components/Alert";

const { TabPane } = AntdTabs;
const { Text } = Typography;

const SpinWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-grow: 1;
`;

const PageWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
`;

const HeadingWrapper = styled.div`
	background: #ffffff;
	width: 100%;
	display: flex;
	justify-content: center;
	padding-bottom: 64px;
`;

const HeadingContent = styled.div`
	width: var(--teylor-content-width);
	padding: 16px 16px;

	@media (max-width: ${maxWidth(lgScreen)}) {
		max-width: var(--teylor-content-width);
	}
`;

const Heading = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const LoanBreadcrumbWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const LoanBreadcrumb = styled(Text)`
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 24px;

	span {
		font-weight: 400;
	}
`;

const Tabs = styled(AntdTabs)`
	.ant-tabs-nav {
		margin-bottom: 40px;

		&::before {
			border-bottom: none;
		}
	}

	.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
		color: var(--teylor-text-primary);
	}
`;

const Content = styled(ContentWrapper)`
	padding-top: 0;
	position: relative;
	top: -46px; // height of tab list to display it on white background
`;

const InReviewAlert = () => {
	const { t } = useTranslation();
	return (
		<Alert
			type="info"
			message={t("application.in-review-alert")}
			showIcon
			style={{ marginBottom: 24 }}
		/>
	);
};

export enum ApplicationTabs {
	"OVERVIEW" = "OVERVIEW",
	"REPRESENTATIVES" = "REPRESENTATIVES",
	"DOCUMENTS" = "DOCUMENTS",
	"SIGNATURES" = "SIGNATURES",
}
export enum PendingItem {
	EMAIL_VERIFICATION,
	BANK_INFORMATION,
	COMPANY_TIN,
	REPRESENTATIVES,
	DOCUMENTS,
	SIGNATURE,
}

interface SubmitBtnOptions {
	text: string;
	onSubmit: () => void;
}

const pendingActionToItemMap = new Map<PendingActionType, PendingItem>([
	[PendingActionType.BankNameIbanValid, PendingItem.BANK_INFORMATION],
	[PendingActionType.CompanyTinSpecified, PendingItem.COMPANY_TIN],
	[PendingActionType.SignatoriesSpecified, PendingItem.REPRESENTATIVES],
	[PendingActionType.DocumentAnnualStatementsUploaded, PendingItem.DOCUMENTS],
	[PendingActionType.DocumentBwaSusaUploaded, PendingItem.DOCUMENTS],
	[PendingActionType.DocumentCurrentDebtRegisterUploaded, PendingItem.DOCUMENTS],
	[PendingActionType.SignatoriesTaxidSpecified, PendingItem.SIGNATURE],
	[PendingActionType.SignatoriesIdentCompleted, PendingItem.SIGNATURE],
	[PendingActionType.SepaMandateAccepted, PendingItem.SIGNATURE],
	[PendingActionType.GuarantorContractUploaded, PendingItem.SIGNATURE],
]);

const shouldShowSignatureTab = (
	application: UserApplication,
	requirements: SignatoryRequirements
) => {
	const signatureStatusIndex = application?.status_flow.findIndex(
		(status) => status.status_name === ApplicationStatuses.Signature
	);
	const statusesToShowSignatureTab =
		application?.status_flow.slice(signatureStatusIndex).map((status) => status.status_name) || [];

	const isRequiredToSign =
		requirements.is_video_id_required ||
		requirements.is_loan_signature_required ||
		requirements.is_ubo_signature_required;

	return statusesToShowSignatureTab.includes(application.status) && isRequiredToSign;
};

const DashboardApplicationOverview = () => {
	const { t } = useTranslation();
	const [application, setApplication] = useState<UserApplication>();
	const [representatives, setRepresentatives] = useState<SignatoryWithId[]>([]);
	const [activeTab, setActiveTab] = useState<ApplicationTabs>(ApplicationTabs.OVERVIEW);
	const [loading, setLoading] = useState(true);
	const [loadingSubmit, setLoadingSubmit] = useState(false);
	const [error, setError] = useState<ErrorResponse>();
	const { id } = useParams() as { id: string };
	const { emailVerified, email } = useSelector((state: RootState) => state.userState);
	const [pendingItems, setPendingItems] = useState<StatusPendingAction[]>([]);
	const [pendingItemsToDisplay, setPendingItemsToDisplay] = useState<PendingItem[]>([]);
	const [showSubmitConfirmationModal, setShowSubmitConfirmationModal] = useState(false);
	const [nextStatus, setNextStatus] = useState<ApplicationStatuses>();
	const { currency } = useFormatter(application?.currency);

	usePageTitle(t("pageTitle.application-overview"));

	const status = application && getStatus(application.status);

	const signatory = representatives.find((representative) => representative.email === email);

	const showSignatureTab =
		application &&
		signatory?.requirements &&
		shouldShowSignatureTab(application, signatory.requirements);

	const isApplicationInReview =
		status && [Status.IN_REVIEW, Status.IN_REVIEW_AFTER_SIGNATURE].includes(status);

	const pendingItemExists = (actionType: PendingActionType): boolean =>
		pendingItems.some((action) => action.action === actionType);

	const submitApplication = () => {
		if (!nextStatus) {
			void message.error("error-messages:something-went-wrong");
			return;
		}

		setLoadingSubmit(true);
		Axios.patch<UpdateApplicationStatusRequestBody, Application>(
			`/user/applications/${id}/status`,
			{ status: nextStatus }
		)
			.then(
				({ data }) => setApplication(data.application),
				(err: ErrorResponse) => Axios.error(err)
			)
			.finally(() => setLoadingSubmit(false));
	};

	const getRepresentatives = () => {
		setLoading(true);
		Axios.get<SignatoriesResponse>(`/user/applications/${id}/signatories`)
			.then(
				({ data }) => setRepresentatives(data.signatories),
				(err: ErrorResponse) => setError(err)
			)
			.finally(() => setLoading(false));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	};

	const getApplication = () => {
		Axios.get<UserApplication>(`/user/applications/${id}`)
			.then(
				(response) => setApplication(response.data),
				(error: ErrorResponse) => setError(error)
			)
			.finally(() => {
				setLoading(false);
			});
	};

	const getPendingItems = () => {
		if (!status) return;

		if (!emailVerified) {
			void getProfile();
		}

		Axios.get<User.ApplicationsNextStatusValidateDetail.ResponseBody>(
			`/user/applications/${id}/next_status_validate`
		)
			.then(({ data }) => {
				setPendingItems(data.pending_actions);
				setNextStatus(data.next_status);

				const pendingItems = data.pending_actions.reduce<PendingItem[]>((acc, item) => {
					const pendingItem = pendingActionToItemMap.get(item.action);

					if (!pendingItem) return acc;

					if (pendingItem === PendingItem.SIGNATURE && !acc.includes(pendingItem)) {
						const signatoriesAssociatedWithError =
							item.errors &&
							item.errors.reduce<string[]>((acc, error) => {
								error.signatories?.forEach((signatoryId) => acc.push(signatoryId));
								return acc;
							}, []);

						if (
							signatory?.signatory_id &&
							signatoriesAssociatedWithError?.includes(signatory.signatory_id)
						) {
							acc.push(pendingItem);
						}

						return acc;
					}

					if (!acc.includes(pendingItem)) {
						acc.push(pendingItem);
					}

					return acc;
				}, []);

				setPendingItemsToDisplay([
					...(emailVerified ? [] : [PendingItem.EMAIL_VERIFICATION]),
					...pendingItems,
				]);
			})
			.catch((err: ErrorResponse) => setError(err));
	};

	const handleChangeTab = (tab: ApplicationTabs) => {
		setActiveTab(tab);
		if (tab === ApplicationTabs.OVERVIEW) getPendingItems();
	};

	const submitButton: SubmitBtnOptions | undefined = useMemo(() => {
		if (application && application.status === ApplicationStatuses.Open) {
			return {
				text: t("application.submit-application"),
				onSubmit: () => setShowSubmitConfirmationModal(true),
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [application?.status]);

	useEffect(() => {
		getRepresentatives();
		getApplication();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	useEffect(() => {
		getPendingItems();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, emailVerified, status, signatory]);

	if (loading)
		return (
			<SpinWrapper>
				<Spin />
			</SpinWrapper>
		);

	if (error) return <Error error={error} />;

	if (!application || !status) return null;

	return (
		<>
			<PageWrapper>
				<HeadingWrapper>
					<HeadingContent>
						<Heading>
							<LoanBreadcrumbWrapper>
								<Breadcrumb>
									<Breadcrumb.Item>
										<Link to={Routes.dashboardApplications}>{t("application.loans")}</Link>
									</Breadcrumb.Item>
									<Breadcrumb.Item>
										<LoanBreadcrumb>
											{currency(application.loanSize, { showFractions: false })}
											<span>({application.shortApplicationId})</span>
										</LoanBreadcrumb>
									</Breadcrumb.Item>
								</Breadcrumb>
								{application.status && (
									<Tag style={{ marginLeft: 8 }} color={TAG_COLORS[status]}>
										{t(`application-status.${status}`)}
									</Tag>
								)}
							</LoanBreadcrumbWrapper>
							{submitButton && (
								<Tooltip
									title={
										status === Status.NOT_COMPLETED
											? t("application.submit-application-tooltip")
											: ""
									}
								>
									<ThemedButton
										type="primary"
										disabled={!!pendingItemsToDisplay.length}
										loading={loadingSubmit}
										onClick={submitButton.onSubmit}
									>
										{submitButton.text}
									</ThemedButton>
								</Tooltip>
							)}
						</Heading>
					</HeadingContent>
				</HeadingWrapper>
				<Content>
					<Tabs
						activeKey={activeTab}
						onChange={(v) => handleChangeTab(v as ApplicationTabs)}
						destroyInactiveTabPane
					>
						<TabPane tab={t("application.overview")} key={ApplicationTabs.OVERVIEW}>
							{isApplicationInReview && <InReviewAlert />}
							<ApplicationOverview
								application={application}
								status={status}
								pendingItems={pendingItemsToDisplay}
								getPendingItems={getPendingItems}
								getApplication={getApplication}
								setActiveTab={setActiveTab}
							/>
						</TabPane>
						<TabPane
							tab={t("application.company-representatives")}
							key={ApplicationTabs.REPRESENTATIVES}
						>
							{isApplicationInReview && <InReviewAlert />}
							<CompanyRepresentativesList
								representatives={representatives}
								applicationId={id}
								applicationStatus={application.status}
								updateRepresentatives={getRepresentatives}
								getPendingItems={getPendingItems}
								ownershipPercentageExceeded={pendingItemExists(
									PendingActionType.SignatoriesSharesLe100
								)}
								noSoleSignatory={pendingItemExists(PendingActionType.SignatoriesSoleOrAtleast2)}
							/>
						</TabPane>
						<TabPane tab={t("application.documents")} key={ApplicationTabs.DOCUMENTS}>
							{isApplicationInReview && <InReviewAlert />}
							<DocumentsUpload
								applicationId={application.applicationId}
								applicationStatus={application.status}
								getPendingItems={getPendingItems}
							/>
						</TabPane>
						{showSignatureTab && (
							<TabPane tab={t("application.signatures")} key={ApplicationTabs.SIGNATURES}>
								<Signatures
									application={application}
									signatory={signatory}
									updateRepresentatives={getRepresentatives}
									pendingItems={pendingItems}
									getPendingItems={getPendingItems}
								/>
							</TabPane>
						)}
					</Tabs>
				</Content>
			</PageWrapper>
			<SubmitConfirmationModal
				representatives={representatives}
				modalProps={{
					visible: showSubmitConfirmationModal,
					onCancel: () => {
						setShowSubmitConfirmationModal(false);
					},
					onOk: () => {
						submitApplication();
						setShowSubmitConfirmationModal(false);
					},
				}}
			/>
		</>
	);
};

export default DashboardApplicationOverview;
