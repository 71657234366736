import { PublicFeature } from "@teylor-tools/Api";
import { ConfigState } from "src/store/reducers/configReducer";

export enum ConfigActions {
	SET_CONFIG = "SET_CONFIG",
}

export type Action = {
	type: ConfigActions.SET_CONFIG;
	currencies?: PublicFeature;
	urlList?: PublicFeature;
};

export const setConfig = ({ currencies, urlList }: ConfigState): Action => {
	return {
		type: ConfigActions.SET_CONFIG,
		currencies,
		urlList,
	};
};
