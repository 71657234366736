import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import styled from "styled-components";

import ThemedButton from "src/components/themed-ui/ThemedButton";

export const Button = styled(ThemedButton)`
	min-width: 185px;
	align-self: center;
	margin-top: 24px;
`;

export const CheckCircleIcon = styled(CheckCircleOutlined)`
	color: ${({ theme }) => theme.color_primary};
	font-size: 112px;
	margin-top: 8px;
	margin-bottom: 24px;
`;

export const CloseCircleIcon = styled(CloseCircleOutlined)`
	color: var(--teylor-light-gray);
	font-size: 112px;
	margin-top: 8px;
	margin-bottom: 24px;
`;
