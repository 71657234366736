import { Typography, Form, Space, Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import ApplicationSteps from "src/pages/application-form/components/ApplicationSteps";
import { ContentWrapper } from "src/components/layout/LayoutComponents";
import ApplicationFormCard from "src/pages/application-form/components/ApplicationFormCard";
import ApplicationFormButton from "src/pages/application-form/components/ApplicationFormButton";
import ApplicationFormBackButton from "src/pages/application-form/components/ApplicationFormBackButton";
import InputPassword from "src/components/form/InputPassword";
import ThemedLink from "src/components/themed-ui/ThemedLink";
import { Routes } from "src/Router";
import { Axios, ErrorResponse } from "src/utils/Axios";
import { loginUser } from "src/utils/api/authentication";
import EmailFormItem from "@ui/form/form-items/email-form-item/EmailFormItem";
import TermsAndConditionsCheckboxes from "src/components/form/TermsAndConditionsCheckboxes";
import { User } from "@teylor-tools/Api";
import { usePageTitle } from "src/hooks/usePageTitle";

const { Title, Text } = Typography;

interface RegistrationInput {
	email: string;
	password: string;
	terms: boolean;
	termsPartner: boolean;
}

const ApplicationAccountCreation = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [form] = Form.useForm();

	usePageTitle(t("pageTitle.new-application"));

	const onFinish = ({ email: emailAddress, password, terms, termsPartner }: RegistrationInput) => {
		const email = emailAddress.toLowerCase();
		Axios.post<User.UsersCreate.RequestBody, User.UsersCreate.ResponseBody>("/user/users", {
			email,
			password,
			accepted_terms: terms,
			accepted_partner_terms: termsPartner,
		}).then(
			() => void loginUser(email, password).then(() => navigate(Routes.applicationResult)),
			(error: ErrorResponse) => void Axios.error(error, t("error-messages.registration-error"))
		);
	};

	return (
		<>
			<ApplicationSteps stepIndex={3} />
			<ContentWrapper>
				<ApplicationFormCard>
					<ApplicationFormBackButton path={Routes.applicationCompanyDetails} />
					<Space direction="vertical" size={24}>
						<Title level={3}>{t("form.account-creation-title")}</Title>
						<Form form={form} layout="vertical" onFinish={onFinish}>
							<EmailFormItem />
							<InputPassword name="password" />
							<InputPassword
								name="passwordRepeat"
								label={t("form.password-repeat")}
								errorMessage={t("error-messages.password-repeat-invalid")}
								rules={[
									{
										required: true,
										validator: (_, passwordRepeat) =>
											passwordRepeat === form.getFieldValue("password")
												? Promise.resolve()
												: Promise.reject(t("error-messages.password-repeat-invalid")),
									},
								]}
							/>
							<TermsAndConditionsCheckboxes />
							<Row>
								<Col
									span={24}
									style={{
										textAlign: "center",
									}}
								>
									<ApplicationFormButton type="primary" size="large" htmlType="submit">
										{t("next")}
									</ApplicationFormButton>
								</Col>
							</Row>
						</Form>
					</Space>
					<Text style={{ textAlign: "center", marginTop: 48 }}>
						{t("form.login-link-1")}{" "}
						<ThemedLink
							to={{
								pathname: Routes.login,
								search: "application=true",
							}}
						>
							{t("form.login-link-2")}
						</ThemedLink>
					</Text>
				</ApplicationFormCard>
			</ContentWrapper>
		</>
	);
};

export default ApplicationAccountCreation;
