import styled from "styled-components";
import { CloseCircleOutlined, MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { Divider as AntdDivider, Typography, Space } from "antd";
import { useTranslation } from "react-i18next";

import ThemedExternalLink from "src/components/themed-ui/ThemedExternalLink";
import { ContentWrapperNarrow, ContentCard } from "src/components/layout/LayoutComponents";
import { ErrorResponse } from "src/utils/Axios";
import { useSelector } from "react-redux";
import { RootState } from "src/store/reducers/rootReducer";

export const CloseCircleIcon = styled(CloseCircleOutlined)`
	color: var(--teylor-light-gray);
	font-size: 112px;
	margin-top: 8px;
	margin-bottom: 24px;
`;

const Divider = styled(AntdDivider)`
	margin: 32px 0;
`;

const ContactItem = styled.div`
	display: grid;
	grid-template-columns: auto 1fr;
	gap: 8px;
	align-items: center;
`;

const { Title, Text } = Typography;

interface Props {
	error?: ErrorResponse | Error;
}

const Error = ({ error }: Props) => {
	const { t } = useTranslation();
	const partnerState = useSelector((state: RootState) => state.partnerState);

	return (
		<ContentWrapperNarrow>
			<ContentCard>
				<CloseCircleIcon />
				<Title level={3} style={{ textAlign: "center" }}>
					{t("error-component.title")}
				</Title>
				{error && "response" in error && (
					<Text strong type="secondary" style={{ textAlign: "center" }}>
						{t("error-component.error-code", {
							errorCode: error.response.status,
						})}
					</Text>
				)}
				<Divider />
				<Space direction="vertical">
					<Text type="secondary">{t("error-component.subtitle-1")}</Text>
					<Text type="secondary">{t("error-component.subtitle-2")}</Text>
					<Text type="secondary">
						<ContactItem>
							<PhoneOutlined />
							<Text type="secondary">{partnerState.customer_support_phone}</Text>
						</ContactItem>
						<ContactItem>
							<MailOutlined />
							<ThemedExternalLink href={`mailto:${partnerState.customer_support_email}`}>
								{partnerState.customer_support_email}
							</ThemedExternalLink>
						</ContactItem>
					</Text>
				</Space>
			</ContentCard>
		</ContentWrapperNarrow>
	);
};

export default Error;
