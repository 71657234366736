import styled from "styled-components";
import { Link } from "react-router-dom";

const ThemedLink = styled(Link)`
	color: ${(props) => props.theme.color_link} !important;

	&:hover {
		opacity: 0.8;
	}
`;

export default ThemedLink;
