import {
	Col,
	Form,
	FormItemProps,
	InputNumber,
	InputNumberProps,
	Row,
	Slider,
	Space,
	Typography,
} from "antd";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import { useState } from "react";
import CurrencyInput from "@ui/form/inputs/currency-input/CurrencyInput";
import ThemedButton from "src/components/themed-ui/ThemedButton";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "src/store/reducers/rootReducer";
import { SliderBaseProps } from "antd/es/slider";

const { Text, Link } = Typography;

type SliderProps = Required<Pick<SliderBaseProps, "min" | "max" | "step">>;

const AMOUNT_SLIDER_CONFIG: SliderProps = {
	min: 50000,
	max: 500000,
	step: 10000,
};

const DURATION_SLIDER_CONFIG: SliderProps = {
	min: 12,
	max: 60,
	step: 2,
};

const AMOUNT_INPUT_CONFIG: InputNumberProps = {
	min: AMOUNT_SLIDER_CONFIG.min,
	max: 100000000,
};

const DURATION_INPUT_CONFIG: InputNumberProps = {
	min: DURATION_SLIDER_CONFIG.min,
	max: 240,
};

interface Props extends Omit<FormItemProps, "initialValue"> {
	type: "LoanAmount" | "LoanDuration";
	initialValue: number;
	disableInput?: boolean;
}

const LoanSlider = ({ type, disableInput, ...props }: Props) => {
	const { t } = useTranslation();
	const [currentValue, setCurrentValue] = useState(props.initialValue);
	const { defaultCurrency } = useSelector((state: RootState) => state.configState);
	const formatCurrency = useFormatter(defaultCurrency).currency;
	const form = Form.useFormInstance();

	const isAmountSlider = type === "LoanAmount";
	const isDurationSlider = type === "LoanDuration";

	const sliderConfig = isAmountSlider ? AMOUNT_SLIDER_CONFIG : DURATION_SLIDER_CONFIG;
	const inputConfig = isAmountSlider ? AMOUNT_INPUT_CONFIG : DURATION_INPUT_CONFIG;

	const [showSlider, setShowSlider] = useState(currentValue <= sliderConfig.max);

	const labelTxt = t(isAmountSlider ? "form.loan-amount" : "form.duration");
	const needMoreTxt = t(isAmountSlider ? "form.need-more-amount" : "form.need-more-duration");

	const onCancelClick = () => {
		setShowSlider(true);
		setCurrentValue(sliderConfig.max);
		form.setFieldsValue({ [props.name as string]: sliderConfig.max });
	};

	if (showSlider) {
		return (
			<Form.Item
				{...props}
				label={
					<Space>
						<Text>
							{`${labelTxt}: `}
							{isAmountSlider
								? formatCurrency(currentValue, { showFractions: false })
								: currentValue}
							{isDurationSlider && ` ${t("form.months")}`}
						</Text>
						{!disableInput && currentValue === sliderConfig.max && (
							<Link onClick={() => setShowSlider(false)}>{needMoreTxt}</Link>
						)}
					</Space>
				}
			>
				<Slider
					{...sliderConfig}
					{...(isAmountSlider && {
						tipFormatter: (v) => v && formatCurrency(v, { showFractions: false }),
					})}
					onChange={(value) => setCurrentValue(value)}
				/>
			</Form.Item>
		);
	}

	return (
		<Row align="middle" style={{ marginBottom: 24 }}>
			<Col style={{ marginRight: 8 }}>{`${labelTxt}: `}</Col>
			<Col style={{ marginRight: 8 }}>
				<Form.Item {...props} style={{ margin: 0 }}>
					{isAmountSlider ? (
						<CurrencyInput {...inputConfig} style={{ width: "116px" }} />
					) : (
						<InputNumber {...inputConfig} style={{ width: "48px" }} controls={false} />
					)}
				</Form.Item>
			</Col>
			{isDurationSlider && <Col>{t("form.months")}</Col>}
			<ThemedButton type={"link"} style={{ marginLeft: 8, padding: 0 }} onClick={onCancelClick}>
				{t("cancel")}
			</ThemedButton>
		</Row>
	);
};

export default LoanSlider;
