import { createContext, useContext, ReactNode } from "react";
import { ObjectFinancingType, ObjectRentalState } from "@teylor-tools/Api";
import { Country, countriesEn } from "@teylor-tools/utils/countryList";

export interface Translations {
	add: string;
	fieldRequired: string;
	fieldInvalid: string;
	save: string;
	yes: string;
	no: string;

	city: string;
	cityPlaceholder: string;
	street: string;
	streetPlaceholder: string;
	country: string;
	countries: Country[];
	emailInputErrorMessage: string;
	emailInputLabel: string;
	emailInputPlaceholder: string;
	postcode: string;
	postcodePlaceholder: string;
	tinInvalid: string;
	tinPlaceholder: string;
	hrNumber: string;
	hrNumberPlaceholder: string;
	houseNumber: string;
	houseNumberPlaceholder: string;

	object: {
		object: string;
		manufacturer: string;
		type: string;
		yearBuilt: string;
		objectDescription: string;
		netPurchasePrice: string;
		downPayment: string;
		remainingValue: string;
		numberOfObjects: string;
		financingType: string;
		rentalState: string;
		isFixedInterest: string;
		companyName: string;
		street: string;
		houseNumber: string;
		city: string;
		postcode: string;
		country: string;
		errors: {
			required: string;
			fieldMinLength5: string;
			postcodeInvalid: string;
			between: string;
			and: string;
		};
		placeholders: {
			manufacturer: string;
			type: string;
			yearBuilt: string;
			objectDescription: string;
			objectCompanyName: string;
			street: string;
			houseNumber: string;
			city: string;
		};
		options: {
			financingTypes: {
				[key in ObjectFinancingType]: string;
			};
			rentalStates: {
				[key in ObjectRentalState]: string;
			};
			isFixedInterest: {
				yes: string;
				no: string;
			};
			countries: Country[];
		};
		sections: {
			retailer: string;
		};
	};

	representativeCard: {
		address: string;
		addTin: string;
		beneficialOwner: string;
		canSignAlone: string;
		dateOfBirth: string;
		email: string;
		legalSignatory: string;
		nationality: string;
		ownership: string;
		phone: string;
		tin: string;
		willingToSign: string;
		schufaScore: string;
		details: string;
		lastUpdated: string;
	};

	representativeModal: {
		addRepresentative: string;
		allFieldsRequired: string;
		beneficialOwner: string;
		canSignAlone: string;
		dateOfBirth: string;
		editRepresentative: string;
		firstName: string;
		houseNumber: string;
		lastName: string;
		legalSignatory: string;
		nationality: string;
		optional: string;
		percentageOwnership: string;
		phoneNumber: string;
		representativeNotContacted: string;
		street: string;
		taxNumber: string;
		willingToSign: string;
	};

	companySelectFormItem: {
		label: string;
		placeholder: string;
		errorCompanyNameInvalid: string;
		errorCompanyNameNotFound: string;
		errorCompanyNameNotFoundLinkText: string;
	};
}

export const fallbackTranslations: Translations = {
	add: "Add",
	fieldRequired: "Required field",
	fieldInvalid: "Field invalid",
	save: "Save",
	yes: "Yes",
	no: "No",

	city: "City",
	cityPlaceholder: "e.g. Berlin",
	street: "Street",
	streetPlaceholder: "e.g. Whateverstreet",
	country: "Country",
	countries: countriesEn,
	emailInputErrorMessage: "Please provide valid e-mail address.",
	emailInputLabel: "E-mail Address",
	emailInputPlaceholder: "E-mail Address",
	postcode: "Postcode",
	postcodePlaceholder: "Eg. 10315",
	tinInvalid: "Please provide valid tax number.",
	tinPlaceholder: "Example: 1234567890",
	hrNumber: "HR Number",
	hrNumberPlaceholder: "e.g. 12345",
	houseNumber: "House number",
	houseNumberPlaceholder: "e.g. 30a",

	object: {
		object: "Object",
		manufacturer: "Manufacturer",
		type: "Type",
		yearBuilt: "Year built",
		objectDescription: "Detailed Description",
		netPurchasePrice: "Net Purchase Price",
		downPayment: "Down Payment",
		remainingValue: "Remaining Value",
		numberOfObjects: "Number of Objects",
		financingType: "Financing Type",
		rentalState: "Will this machine be rented out?",
		isFixedInterest: "Should the interest  be a fixed rate?",
		companyName: "Company name",
		street: "Street",
		houseNumber: "House number",
		city: "City",
		postcode: "Post code",
		country: "Country",
		errors: {
			required: "Required",
			fieldMinLength5: "Minimum 5 characters",
			postcodeInvalid: "Please provide a valid postcode.",
			between: "Between",
			and: "and",
		},
		placeholders: {
			manufacturer: "Eg. Machine GmbH",
			type: "Eg. Machinery",
			yearBuilt: "Eg. 2020",
			objectDescription: "Describe the object being financed",
			objectCompanyName: "Eg. Metabo GmbH",
			street: "Eg. Example street",
			houseNumber: "Eg. 103",
			city: "Eg. Berlin",
		},
		options: {
			isFixedInterest: {
				yes: "Yes",
				no: "No",
			},
			financingTypes: {
				leasing: "Leasing",
				hire_purchase: "Hire-Purchase",
				financing: "Financing",
				sale_leaseback: "Sale & leaseback",
				credit_line: "Credit line",
				other: "Other",
			},
			rentalStates: {
				no: "No",
				internal: "Within company",
				b2c: "To the end customer",
			},
			countries: countriesEn,
		},
		sections: {
			retailer: "Retailer",
		},
	},

	representativeCard: {
		address: "Address",
		addTin: "Add TIN",
		beneficialOwner: "Beneficial owner",
		canSignAlone: "Can sign alone",
		dateOfBirth: "Date of birth",
		email: "Email",
		legalSignatory: "Legal signatory",
		nationality: "Nationality",
		ownership: "Ownership",
		phone: "Phone",
		tin: "TIN",
		willingToSign: "Willing to sign",
		schufaScore: "SCHUFA Score",
		details: "Details",
		lastUpdated: "Last updated",
	},

	representativeModal: {
		addRepresentative: "Add representative",
		allFieldsRequired: "All fields are required",
		beneficialOwner: "Beneficial owner",
		canSignAlone: "Can sign alone",
		dateOfBirth: "Date of birth",
		editRepresentative: "Edit representative",
		firstName: "First name",
		houseNumber: "House number",
		lastName: "Last name",
		legalSignatory: "Legal signatory",
		nationality: "Nationality",
		optional: "(can be added later)",
		percentageOwnership: "Percentage Ownership",
		phoneNumber: "Phone",
		representativeNotContacted:
			"The representative will not be contacted before the offer is accepted.",
		street: "Street",
		taxNumber: "TIN (Tax ID number or Tax Number)",
		willingToSign: "Willing to sign",
	},

	companySelectFormItem: {
		label: "Company name",
		placeholder: "Example GmbH",
		errorCompanyNameInvalid: "Please review your company name and try again.",
		errorCompanyNameNotFound: "Not found? No Problem!",
		errorCompanyNameNotFoundLinkText: "Click here to enter the company details manually.",
	},
};

const TranslationsContext = createContext<Translations>(fallbackTranslations);

export const useTranslations = () => {
	return useContext(TranslationsContext);
};

interface TranslationsProviderProps {
	translations: Partial<Translations>;
	children: ReactNode;
}

export const TranslationsProvider = ({ translations, children }: TranslationsProviderProps) => {
	const t = useTranslations();

	return (
		<TranslationsContext.Provider value={{ ...t, ...translations }}>
			{children}
		</TranslationsContext.Provider>
	);
};
