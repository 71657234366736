import { Typography, Form, Space, Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import ApplicationSteps from "src/pages/application-form/components/ApplicationSteps";
import { ContentWrapper } from "src/components/layout/LayoutComponents";
import ApplicationFormCard from "src/pages/application-form/components/ApplicationFormCard";
import ApplicationFormButton from "src/pages/application-form/components/ApplicationFormButton";
import ApplicationFormBackButton from "src/pages/application-form/components/ApplicationFormBackButton";
import { Routes } from "src/Router";
import { useApplicationForm } from "src/providers/application-form-provider/useApplicationForm";
import { usePageTitle } from "src/hooks/usePageTitle";
import CompanySelectFormItem, {
	SelectedCompany,
} from "@ui/form/form-items/company-select-form-item/CompanySelectFormItem";
import { Axios } from "src/utils/Axios";
import { useTheme } from "styled-components";

const { Title } = Typography;

const ApplicationCompanyName = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const theme = useTheme();

	const {
		applicationForm: { companyName },
		setApplicationForm,
		setIsSelectedCompany,
	} = useApplicationForm();

	usePageTitle(t("pageTitle.new-application"));

	const [form] = Form.useForm<{ companyName: string }>();

	const onFinish = ({ companyName }: { companyName: string }) => {
		setApplicationForm((prevState) => ({ ...prevState, companyName }));
		navigate(Routes.applicationCompanyDetails);
	};

	const setCompanyData = (company: SelectedCompany, isSelected: boolean) => {
		const { street, houseNumber, companyPostCode, companyLocation, companyCountry } = company;

		setApplicationForm((prevState) => ({
			...prevState,
			companyType: undefined,
			street,
			houseNumber,
			companyPostCode,
			companyLocation,
			companyCountry,
		}));

		setIsSelectedCompany(isSelected);
	};

	return (
		<>
			<ApplicationSteps stepIndex={1} />
			<ContentWrapper>
				<ApplicationFormCard>
					<ApplicationFormBackButton path={Routes.applicationLoanInfo} />
					<Space direction="vertical" size={24}>
						<Title level={3}>{t("form.company-name-title")}</Title>
						<Form form={form} layout="vertical" onFinish={onFinish}>
							<CompanySelectFormItem
								initialValue={companyName}
								setCompanyData={setCompanyData}
								axios={Axios.axios}
								linkColour={theme.color_link}
							/>
							<Row>
								<Col
									span={24}
									style={{
										textAlign: "center",
									}}
								>
									<ApplicationFormButton type="primary" size="large" htmlType="submit">
										{t("next")}
									</ApplicationFormButton>
								</Col>
							</Row>
						</Form>
					</Space>
				</ApplicationFormCard>
			</ContentWrapper>
		</>
	);
};

export default ApplicationCompanyName;
