import { ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
	TranslationsProvider as TranslationsProviderUI,
	Translations,
} from "@ui/translations/translations";

export const TranslationsProvider = ({ children }: { children: ReactNode }) => {
	const { t } = useTranslation();
	const translationsUI: Partial<Translations> = useMemo(
		() => ({
			add: t("add"),
			city: t("dashboard.city"),
			cityPlaceholder: t("form.city-placeholder"),
			street: t("form.street"),
			streetPlaceholder: t("form.street-placeholder"),
			country: t("form.country"),
			countries: t("countries", { returnObjects: true }),
			emailInputErrorMessage: t("error-messages.email-invalid"),
			emailInputLabel: t("form.email"),
			emailInputPlaceholder: t("form.email-placeholder"),
			yes: t("yes"),
			no: t("no"),
			fieldRequired: t("form.field-required"),
			fieldInvalid: t("form.field-invalid"),
			postcode: t("form.post-code"),
			postcodePlaceholder: t("form.post-code-placeholder"),
			tinInvalid: t("error-messages.tax-number-invalid"),
			tinPlaceholder: t("form.tax-number-placeholder"),
			save: t("save"),
			hrNumber: t("form.hr-number"),
			hrNumberPlaceholder: t("form.hr-number-placeholder"),
			houseNumber: t("form.house-number"),
			houseNumberPlaceholder: t("form.house-number-placeholder"),
			representativeCard: {
				address: t("dashboard.address"),
				addTin: t("dashboard.add-tin"),
				beneficialOwner: t("dashboard.beneficial-owner"),
				canSignAlone: t("dashboard.can-sign-alone"),
				dateOfBirth: t("dashboard.dob"),
				email: t("dashboard.email"),
				legalSignatory: t("dashboard.legal-signatory"),
				nationality: t("dashboard.nationality"),
				ownership: t("dashboard.ownership"),
				phone: t("dashboard.phone"),
				tin: t("dashboard.tin"),
				willingToSign: t("dashboard.willing-to-sign"),
				schufaScore: t("dashboard.schufa-score"),
				details: t("dashboard.details"),
				lastUpdated: t("dashboard.last-updated"),
			},
			representativeModal: {
				addRepresentative: t("dashboard.add-representative"),
				allFieldsRequired: t("form.all-fields-required"),
				beneficialOwner: t("dashboard.beneficial-owner"),
				canSignAlone: t("dashboard.can-sign-alone"),
				dateOfBirth: t("dashboard.dob"),
				editRepresentative: t("dashboard.edit-representative"),
				firstName: t("dashboard.first-name"),
				houseNumber: t("dashboard.house-number"),
				lastName: t("dashboard.last-name"),
				legalSignatory: t("dashboard.legal-signatory"),
				nationality: t("dashboard.nationality"),
				optional: t("form.optional"),
				percentageOwnership: t("dashboard.percentage-ownership"),
				phoneNumber: t("dashboard.phone-number"),
				representativeNotContacted: t("dashboard.representative-not-contacted"),
				street: t("dashboard.street"),
				taxNumber: t("form.tax-number"),
				willingToSign: t("dashboard.willing-to-sign"),
			},
			companySelectFormItem: {
				label: t("form.company-name"),
				placeholder: t("form.company-name-placeholder"),
				errorCompanyNameInvalid: t("error-messages.company-name-invalid"),
				errorCompanyNameNotFound: t("form.company-name-not-found-text"),
				errorCompanyNameNotFoundLinkText: t("form.company-name-not-found-link"),
			},
		}),
		[t]
	);

	return <TranslationsProviderUI translations={translationsUI}>{children}</TranslationsProviderUI>;
};
