import styled from "styled-components";
import { CheckOutlined as AntdCheckOutlined } from "@ant-design/icons/lib/icons";
import { default as AntdTitle } from "antd/es/typography/Title";
import { useTranslation } from "react-i18next";

const CheckOutlined = styled(AntdCheckOutlined)`
	color: var(--ant-primary-color);
	margin-right: 8px;
`;

const List = styled.ul`
	color: var(--teylor-text-secondary);
	padding-left: 50px;
`;

const Title = styled(AntdTitle)`
	margin: 10px 0;
`;

const CompanyRepresentativeInfo = () => {
	const { t } = useTranslation();

	return (
		<>
			<Title level={5}>{t("dashboard.empty-title")}</Title>

			<Title level={5}>
				<CheckOutlined />
				{t("dashboard.empty-section-1-title")}
			</Title>
			<List>
				<li>{t("dashboard.empty-section-1-point-1")}</li>
				<li>{t("dashboard.empty-section-1-point-2")}</li>
			</List>

			<Title level={5}>
				<CheckOutlined />
				{t("dashboard.empty-section-2-title")}
			</Title>
			<List>
				<li>{t("dashboard.empty-section-2-point-1")}</li>
			</List>

			<Title level={5}>
				<CheckOutlined />
				{t("dashboard.empty-section-3-title")}
			</Title>
			<List>
				<li>{t("dashboard.empty-section-3-point-1")}</li>
				<li>{t("dashboard.empty-section-3-point-2")}</li>
			</List>
		</>
	);
};

export default CompanyRepresentativeInfo;
