import styled from "styled-components";
import ThemedButton from "src/components/themed-ui/ThemedButton";

const ApplicationFormButton = styled(ThemedButton)`
	min-width: 185px;
	align-self: center;
	margin-top: 24px;
`;

export default ApplicationFormButton;
