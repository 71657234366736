import styled from "styled-components";

const ThemedExternalLink = styled.a`
	color: ${(props) => props.theme.color_link} !important;

	&:hover {
		opacity: 0.8;
	}
`;

export default ThemedExternalLink;
