import { Checkbox, Form } from "antd";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Typography } from "antd";
import styled from "styled-components";

import { RootState } from "src/store/reducers/rootReducer";

const { Link: AntdLink } = Typography;

const Link = styled(AntdLink)`
	color: ${(props) => props.theme.color_link} !important;
`;

const TermsAndConditionsCheckboxes = () => {
	const { t } = useTranslation();
	const { display_name, terms_url, data_protection_url } = useSelector(
		(state: RootState) => state.partnerState
	);
	const { terms, privacy_policy } = useSelector(
		(state: RootState) => state.configState.urlList?.value
	) || { terms: undefined, privacy_policy: undefined, imprint: undefined };

	return (
		<>
			<Form.Item
				name="terms"
				valuePropName="checked"
				rules={[
					{
						validator: (_, value) =>
							value
								? Promise.resolve()
								: Promise.reject(t("error-messages.terms-and-conditions-invalid")),
					},
				]}
			>
				<Checkbox>
					<Trans i18nKey={"form.terms-and-conditions"}>
						{`I accept the `}
						<Link href={terms} target="_blank" rel="noopener noreferrer">
							general terms and conditions
						</Link>
						{` and `}
						<Link href={privacy_policy} target="_blank" rel="noopener noreferrer">
							data protection conditions
						</Link>
					</Trans>
				</Checkbox>
			</Form.Item>

			{data_protection_url && terms_url && (
				<Form.Item
					name="termsPartner"
					valuePropName="checked"
					rules={[
						{
							validator: (_, value) =>
								value
									? Promise.resolve()
									: Promise.reject(t("error-messages.terms-and-conditions-invalid")),
						},
					]}
				>
					<Checkbox>
						<Trans i18nKey={"form.terms-and-conditions-partner"}>
							{`I accept the `}
							<Link href={terms_url} target="_blank" rel="noopener noreferrer">
								terms and conditions
							</Link>
							{` and `}
							<Link href={data_protection_url} target="_blank" rel="noopener noreferrer">
								data protection policy
							</Link>
							{` of `}
							{{ displayName: display_name }}
						</Trans>
					</Checkbox>
				</Form.Item>
			)}
		</>
	);
};

export default TermsAndConditionsCheckboxes;
