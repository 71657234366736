const locale = window.navigator.language;

export enum CurrencyDisplay {
	SYMBOL = "symbol",
	CODE = "code",
}

interface CurrencyConfig {
	showFractions?: boolean;
	currencyDisplay?: CurrencyDisplay;
}

export const useFormatter = (
	currency = "EUR"
): {
	decimal: (number: number | string) => string;
	currency: (number: number | string, config?: CurrencyConfig) => string;
	date: (date: string, options?: Intl.DateTimeFormatOptions) => string;
} => {
	return {
		decimal: (number: number | string) =>
			new Intl.NumberFormat(locale, { style: "decimal" }).format(Number(number)),
		currency: (
			number: number | string,
			{ showFractions, currencyDisplay } = {
				showFractions: true,
				currencyDisplay: CurrencyDisplay.SYMBOL,
			}
		) =>
			new Intl.NumberFormat(locale, {
				style: "currency",
				currency,
				currencyDisplay,
				minimumFractionDigits: showFractions ? 2 : 0,
				maximumFractionDigits: showFractions ? 2 : 0,
			}).format(Number(number)),
		date: (date: string, options: Intl.DateTimeFormatOptions = {}) => {
			return date ? new Intl.DateTimeFormat(locale, options).format(new Date(date)) : "-";
		},
	};
};
