import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Form, Space, Row, Col } from "antd";
import { useTranslation } from "react-i18next";

import EmailFormItem from "@ui/form/form-items/email-form-item/EmailFormItem";

import { Routes } from "src/Router";
import { Axios, ErrorResponse } from "src/utils/Axios";
import { ContentCard } from "src/components/layout/LayoutComponents";

import { Button, CheckCircleIcon } from "./style/AuthenticationComponents";
import ThemedLink from "src/components/themed-ui/ThemedLink";
import { usePageTitle } from "src/hooks/usePageTitle";

const { Title, Text } = Typography;

const ForgotPassword = () => {
	const { t } = useTranslation();
	const [resetSuccess, setResetSuccess] = useState(false);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const [form] = Form.useForm();

	usePageTitle(t("pageTitle.forgotPassword"));

	const onFinish = ({ email }: { email: string }) => {
		setLoading(true);
		Axios.post("/user/account/reset", {
			email,
		})
			.then(
				() => {
					setResetSuccess(true);
				},
				(error: ErrorResponse) => {
					void Axios.error(error, t("error-messages.forgot-password-error"));
				}
			)
			.finally(() => setLoading(false));
	};

	if (resetSuccess) {
		return (
			<ContentCard>
				<CheckCircleIcon />
				<Title level={3} style={{ textAlign: "center" }}>
					{t("authentication.password-reset-email-sent-title")}
				</Title>
				<Text type="secondary" style={{ textAlign: "center" }}>
					{t("authentication.password-reset-email-sent-subtitle")}
				</Text>
				<Button
					type="primary"
					size="large"
					style={{ marginTop: 48 }}
					onClick={() => navigate(Routes.login)}
				>
					{t("authentication.go-to-login")}
				</Button>
			</ContentCard>
		);
	}

	return (
		<ContentCard>
			<Space direction="vertical" size={24}>
				<div>
					<Title level={3}>{t("authentication.forgot-password-title")}</Title>
					<Text type="secondary">{t("authentication.forgot-password-subtitle")}</Text>
				</div>
				<Form form={form} layout="vertical" onFinish={onFinish}>
					<EmailFormItem />
					<Row>
						<Col span={24} style={{ textAlign: "center" }}>
							<Button type="primary" size="large" htmlType="submit" loading={loading}>
								{t("authentication.reset-password")}
							</Button>
						</Col>
					</Row>
				</Form>
			</Space>
			<Text style={{ textAlign: "center", marginTop: 16 }}>
				{t("authentication.go-to-login-link-1")}{" "}
				<ThemedLink to={Routes.login}>{t("authentication.go-to-login-link-2")}</ThemedLink>
			</Text>
		</ContentCard>
	);
};

export default ForgotPassword;
