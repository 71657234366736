import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { Typography, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Routes } from "src/Router";
import { Axios, ErrorResponse } from "src/utils/Axios";
import { RootState } from "src/store/reducers/rootReducer";
import { ContentCard } from "src/components/layout/LayoutComponents";

import { Button, CheckCircleIcon, CloseCircleIcon } from "./style/AuthenticationComponents";
import { usePageTitle } from "src/hooks/usePageTitle";

const { Title, Text } = Typography;

const SpinWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

const ConfirmEmail = () => {
	const { t } = useTranslation();
	const { confirmationCode } = useParams() as { confirmationCode: string };
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState<ErrorResponse>();
	const { isAuthenticated } = useSelector((state: RootState) => state.userState);
	const navigate = useNavigate();

	usePageTitle(t("pageTitle.emailConfirmation"));

	useEffect(() => {
		Axios.post(`/user/account/confirm_email/${confirmationCode}`)
			.catch((error: ErrorResponse) => {
				setError(error);
				void Axios.error(error, t("error-messages.something-went-wrong"));
			})
			.finally(() => setLoading(false));
	}, [t, confirmationCode]);

	if (loading)
		return (
			<SpinWrapper>
				<Spin />
			</SpinWrapper>
		);

	if (error)
		return (
			<ContentCard>
				<CloseCircleIcon />
				<Title level={3} style={{ textAlign: "center" }}>
					{t("authentication.confirm-email-error-title")}
				</Title>
				<Text type="secondary" style={{ textAlign: "center" }}>
					{t("authentication.confirm-email-error-subtitle")}
				</Text>
				<Button
					type="primary"
					size="large"
					style={{ marginTop: 48 }}
					onClick={() => navigate(isAuthenticated ? Routes.dashboardApplications : Routes.login)}
				>
					{isAuthenticated ? t("authentication.view-my-loans") : t("authentication.go-to-login")}
				</Button>
			</ContentCard>
		);

	return (
		<ContentCard>
			<CheckCircleIcon />
			<Title level={3} style={{ textAlign: "center" }}>
				{t("authentication.confirm-email-success-title")}
			</Title>
			<Text type="secondary" style={{ textAlign: "center" }}>
				{t("authentication.confirm-email-success-subtitle")}
			</Text>
			<Button
				type="primary"
				size="large"
				style={{ marginTop: 48 }}
				onClick={() => navigate(isAuthenticated ? Routes.dashboardApplications : Routes.login)}
			>
				{isAuthenticated ? t("authentication.view-my-loans") : t("authentication.go-to-login")}
			</Button>
		</ContentCard>
	);
};

export default ConfirmEmail;
