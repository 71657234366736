import { Form, FormItemProps, Input, InputProps } from "antd";
import { RuleObject } from "rc-field-form/lib/interface";

interface Props extends FormItemProps {
	inputProps?: InputProps;
	errorMsg: string;
}

const MIN_LENGTH = 10;
const MAX_LENGTH = 11;

export const parseTin = (value?: string) => value?.replaceAll("/", "") || "";

const TinFormItem = ({ inputProps, errorMsg, ...props }: Props) => {
	const validateTin = (_: RuleObject, value: string): Promise<void | string> => {
		if (!value) {
			return Promise.resolve();
		}

		const tinOnlyNumbers = parseTin(value);

		if (tinOnlyNumbers.length < MIN_LENGTH || tinOnlyNumbers.length > MAX_LENGTH) {
			return Promise.reject(errorMsg);
		}

		return Promise.resolve();
	};

	return (
		<Form.Item
			{...props}
			rules={[
				{
					type: "string",
					pattern: /^[\d\/]+$/,
					message: errorMsg,
				},
				{
					validator: validateTin,
				},
				...(props.rules || []),
			]}
			validateFirst
		>
			<Input {...inputProps} />
		</Form.Item>
	);
};

export default TinFormItem;
