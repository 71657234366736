import { FC, KeyboardEvent } from "react";
import { InputNumber, InputNumberProps } from "antd";

const CurrencyInput: FC<InputNumberProps> = (props: InputNumberProps) => {
	const format = (value: string | number | undefined): string => {
		// This regexp removes everything except digits and adds a space between each 3 digits
		// example 7000000 would be transformed to 7 000 000
		return `€ ${value || "0"}`.replace(/,/, ".").replace(/\B(?=(\d{3})+(?!\d))/g, " ");
	};

	const onKeyPress = (evt: KeyboardEvent<HTMLInputElement>): void => {
		// don't allow to press key other than the following kit
		const allowedKeys = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "-", ".", ","];
		if (!allowedKeys.includes(evt.nativeEvent.key)) {
			evt.preventDefault();
			evt.stopPropagation();
		}
	};

	return (
		<InputNumber
			formatter={format}
			parser={(value) => (value ? parseFloat(value.replace(/,/, ".").replace(/[^0-9.-]/g, "")) : 0)}
			precision={2}
			onKeyPress={onKeyPress}
			controls={false}
			{...props}
		/>
	);
};

export default CurrencyInput;
