import { Form, Select } from "antd";
import { Rule } from "antd/lib/form";
import { NamePath } from "rc-field-form/es/interface";
import { Country } from "@teylor-tools/utils/countryList";

interface Props {
	label: string;
	countries: Country[];
	name?: NamePath;
	disabled?: boolean;
	rules: Rule[];
}

const CountrySelectFormItem = ({ label, countries, name, disabled, rules }: Props) => {
	return (
		<Form.Item label={label} name={name || "country"} rules={rules}>
			<Select disabled={disabled}>
				{countries.map((country, index) => (
					<Select.Option key={index} value={country.code}>
						{country.name}
					</Select.Option>
				))}
			</Select>
		</Form.Item>
	);
};

export default CountrySelectFormItem;
