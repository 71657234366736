import styled from "styled-components";
import { Layout as AntdLayout } from "antd";

import { lgScreen, mdScreen, maxWidth } from "src/breakpoints";

export const Layout = styled(AntdLayout)`
	min-height: 100vh;
	height: 100%;
	background-color: #fbfbfb;
`;

export const Content = styled(Layout.Content)`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
`;

export const ContentWrapper = styled.div`
	padding: 40px 16px;
	width: var(--teylor-content-width);

	@media (max-width: ${maxWidth(lgScreen)}) {
		padding: 24px 16px;
		max-width: var(--teylor-content-width);
		width: 100%;
	}
`;

export const ContentWrapperNarrow = styled.div`
	box-sizing: border-box;
	width: var(--teylor-content-narrow-width);

	@media (max-width: ${maxWidth(mdScreen)}) {
		max-width: var(--teylor-content-narrow-width);
		width: auto;
	}
`;

export const ContentCard = styled.div`
	background-color: #ffffff;
	padding: 40px 64px 80px;
	margin-top: 64px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;

	@media (max-width: 992px) {
		padding: 32px 48px;
		margin-top: 32px;
	}

	@media (max-width: 576px) {
		padding: 16px 24px;
		margin-top: 24px;
	}
`;
