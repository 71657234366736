import "core-js"; // ES6 polyfills
import { useEffect } from "react";

import "antd/dist/antd.variable.min.css";
import "./variables.css";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import Routes from "./Router";
import AppConfig from "./AppConfig";

// utils
import store from "./store/Store";
import i18nInitialize from "./i18n/i18n";

const App = () => {
	i18nInitialize();

	useEffect(() => {
		dayjs.extend(utc);
		dayjs.extend(timezone);
		dayjs.extend(customParseFormat);
	}, []);

	return (
		<Provider store={store}>
			<BrowserRouter>
				<AppConfig>
					<Routes />
				</AppConfig>
			</BrowserRouter>
		</Provider>
	);
};

render(<App />, document.getElementById("root"));
