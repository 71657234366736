import { ReactElement, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { ConfigProvider } from "antd";
import styled, { ThemeProvider } from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import {
	PartnerConfigTheme,
	Partner,
	Config,
	Entitlements,
	PublicFeatureName,
} from "@teylor-tools/Api";

import FullPageSpinner from "./components/FullPageSpinner";
import { Routes } from "./Router";
import { Axios } from "src/utils/Axios";
import { setPartner } from "./store/actions/partnerActions";
import { setConfig } from "./store/actions/configActions";
import { RootState } from "src/store/reducers/rootReducer";
import { getProfile } from "src/utils/api/authentication";
import { TranslationsProvider } from "src/providers/translations-provider/translations";

const AppGlobalStyle = styled.div`
	font-family: "Asap", sans-serif;
`;

const AppConfig = ({ children }: { children: ReactElement }) => {
	const [themeLoaded, setThemeLoaded] = useState<boolean>(false);
	const [configLoaded, setConfigLoaded] = useState(false);
	const [theme, setTheme] = useState<PartnerConfigTheme>({} as PartnerConfigTheme);
	const dispatch = useDispatch();
	const { isAuthenticated } = useSelector((state: RootState) => state.userState);

	const navigate = useNavigate();

	useEffect(() => {
		if (isAuthenticated) {
			void getProfile();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (themeLoaded) {
			return;
		}

		const subdomain = window.location.host.split(".")[0];

		const loadTheme = (theme: PartnerConfigTheme) => {
			setTheme(theme);

			ConfigProvider.config({
				theme: { primaryColor: theme.color_primary },
			});
		};

		Axios.get<Partner.KeyConfigDetail.ResponseBody>(`/partner/key/${subdomain}/config`)
			.then(({ data }) => {
				if (!data.entitlements?.includes(Entitlements.WhitelabeledUserBranding)) {
					return Promise.reject("No entitlements");
				}

				if (!data.fe_theme) {
					return Promise.reject("No theme provided");
				}

				loadTheme(data.fe_theme);
				dispatch(
					setPartner({
						configLoaded: true,
						customer_support_email: data.parameters.customer_support_email,
						customer_support_phone: data.parameters.customer_support_phone,
						display_name: data.parameters.display_name,
						terms_url: data.terms_url,
						data_protection_url: data.data_protection_url,
						userApplicationTypes: data.user_application_types || [],
						entitlements: data.entitlements || [],
					})
				);
			})
			.catch((err) => {
				console.error("Config error: ", err);
				navigate(Routes.notFound);
			})
			.finally(() => setThemeLoaded(true));

		if (configLoaded) return;

		Axios.get<Config.ConfigList.ResponseBody>(`/config`)
			.then(({ data }) => {
				const currencies = data.public_features.find(
					(publicFeature) => publicFeature.name === PublicFeatureName.Currencies
				);
				const urlList = data.public_features.find(
					(publicFeature) => publicFeature.name === PublicFeatureName.UrlList
				);
				dispatch(
					setConfig({
						currencies,
						urlList,
					})
				);
			})
			.catch((err) => {
				console.error("Error fetching config", err);
				navigate(Routes.notFound);
			})
			.finally(() => setConfigLoaded(true));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [themeLoaded, configLoaded]);

	return (
		<AppGlobalStyle>
			{themeLoaded ? (
				<TranslationsProvider>
					<ThemeProvider theme={theme}>{{ ...children }}</ThemeProvider>
				</TranslationsProvider>
			) : (
				<FullPageSpinner />
			)}
		</AppGlobalStyle>
	);
};
export default AppConfig;
