import { FC } from "react";
import { InputNumber, InputNumberProps } from "antd";

const allowedKeys = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "-", ".", ","];

const format = (value: string) => {
	return `% ${value || "0"}`;
};

const PercentInput: FC<InputNumberProps> = (props: InputNumberProps) => {
	const onKeyPress = (evt: React.KeyboardEvent<HTMLInputElement>) => {
		// don't allow to press key other than allowedKeys
		if (!allowedKeys.includes(evt.nativeEvent.key)) {
			evt.preventDefault();
			evt.stopPropagation();
		}
	};

	return (
		<InputNumber
			min={0}
			max={100}
			step={0.01}
			formatter={(value) => format(String(value))}
			parser={(value?: string) => {
				if (!value) return 0;

				const parsedValue = parseFloat(
					String(value)
						.replace(/,/, ".")
						.replace(/[^0-9.]/g, "")
				);

				return Math.trunc(parsedValue * 100) / 100;
			}}
			onKeyPress={onKeyPress}
			controls={false}
			{...props}
		/>
	);
};

export default PercentInput;
