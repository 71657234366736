import i18next from "i18next";

import store from "src/store/Store";
import { Axios, ErrorResponse } from "src/utils/Axios";
import {
	login,
	logout,
	resetProfile,
	setProfile,
	setProfileLoading,
} from "src/store/actions/userActions";
import { UserLogin, UserProfile } from "@teylor-tools/Api";
import { AxiosResponse } from "axios";

export const loginUser = (
	email: string,
	password: string
): Promise<void | AxiosResponse<never, never>> => {
	return Axios.post<UserLogin, never>("/user/account/login", {
		email,
		password,
	})
		.then(() =>
			getProfile().then(() => {
				store.dispatch(login());
			})
		)
		.catch(() => Promise.reject());
};

export const logoutUser = (): Promise<void | AxiosResponse<never, never>> => {
	return Axios.post<never, never>("/user/account/logout").then(
		() => {
			store.dispatch(logout());
			store.dispatch(resetProfile());
		},
		(error: ErrorResponse) => Axios.error(error, i18next.t("error-messages.logout-error"))
	);
};

export const getProfile = (): Promise<void | AxiosResponse<never, never>> => {
	store.dispatch(setProfileLoading(true));

	return Axios.get<UserProfile>("/user/profile")
		.then(
			({ data }) => {
				store.dispatch(
					setProfile({
						userId: data.user_id,
						email: data.email,
						emailVerified: data.emailVerified,
					})
				);
			},
			() => {
				store.dispatch(logout());
			}
		)
		.finally(() => store.dispatch(setProfileLoading(false)));
};
