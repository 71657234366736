import { FC } from "react";
import { Input, InputProps, Form } from "antd";
import { FormItemProps } from "antd/lib/form/FormItem";

interface TelephoneFormInputProps extends FormItemProps {
	inputProps?: InputProps;
}

const TelephoneFormItem: FC<TelephoneFormInputProps> = ({
	inputProps,
	...props
}: TelephoneFormInputProps) => {
	return (
		<Form.Item {...props}>
			<Input type="tel" maxLength={15} {...inputProps} />
		</Form.Item>
	);
};

export default TelephoneFormItem;
