import { FC } from "react";
import { useSelector } from "react-redux";
import { Routes as RouterRoutes, Route, Navigate, Outlet, Path } from "react-router-dom";

import NotFoundPage from "./pages/NotFoundPage";
import ApplicationLoanInfo from "./pages/application-form/ApplicationLoanInfo";
import ApplicationCompanyName from "./pages/application-form/ApplicationCompanyName";
import ApplicationCompanyDetails from "./pages/application-form/ApplicationCompanyDetails";
import ApplicationAccountCreation from "./pages/application-form/ApplicationAccountCreation";
import ApplicationResult from "./pages/application-form/ApplicationResult";
import ApplicationPageLayout from "./pages/application-form/components/ApplicationPageLayout";
import AuthenticationPageLayout from "./pages/authentication/components/AuthenticationPageLayout";
import Login from "./pages/authentication/Login";
import ForgotPassword from "./pages/authentication/ForgotPassword";
import ResetPassword from "./pages/authentication/ResetPassword";
import Register from "./pages/authentication/Register";
import RegisterInvitation from "./pages/authentication/RegisterInvitation";
import ConfirmEmail from "./pages/authentication/ConfirmEmail";
import DashboardApplicationsList from "./pages/dashboard/DashboardApplicationsList";
import DashboardApplicationOverview from "./pages/dashboard/DashboardApplicationOverview";
import DashboardSettings from "./pages/dashboard/DashboardSettings";
import DashboardPageLayout from "./pages/dashboard/components/DashboardPageLayout";
import { RootState } from "./store/reducers/rootReducer";

import { ApplicationFormProvider } from "./providers/application-form-provider/useApplicationForm";

export enum Routes {
	index = "/",

	applicationLoanInfo = "/application/loan-info",
	applicationCompanyName = "/application/company-name",
	applicationCompanyDetails = "/application/company-details",
	applicationAccountCreation = "/application/account-creation",
	applicationResult = "/application/application-result",

	login = "/login",
	forgotPassword = "/forgot-password",
	resetPassword = "/user/account/reset/:token",
	register = "/register",
	registerInvitation = "/user/account/invite/:token",
	confirmEmail = "/user/account/confirm_email/:confirmationCode",

	dashboard = "/dashboard",
	dashboardApplications = "/dashboard/applications",
	dashboardApplicationOverview = "/dashboard/applications/:id",
	dashboardSettings = "/dashboard/settings",

	notFound = "/not-found",
}

type RouteType = typeof Routes[keyof typeof Routes];

interface RouteFullPath extends Omit<Path, "pathname"> {
	pathname: RouteType;
}

// This type is made to match our routes in useNavigate hook
export type RoutePath = RouteType | Partial<RouteFullPath>;

const ProtectedRoute: FC = () => {
	const { isAuthenticated } = useSelector((state: RootState) => state.userState);

	if (!isAuthenticated) {
		return <Navigate to={Routes.login} replace />;
	}

	return <Outlet />;
};

const AuthRoutes: FC = () => {
	const { isAuthenticated } = useSelector((state: RootState) => state.userState);
	if (isAuthenticated) {
		return <Navigate to={Routes.index} replace />;
	}

	return <Outlet />;
};

const Router = () => {
	const { isAuthenticated } = useSelector((state: RootState) => state.userState);
	return (
		<RouterRoutes>
			<Route element={<ApplicationFormProvider />}>
				<Route element={<ApplicationPageLayout />}>
					<Route
						path={Routes.index}
						element={<Navigate to={Routes.applicationLoanInfo} replace />}
					/>
					<Route path={Routes.applicationLoanInfo} element={<ApplicationLoanInfo />} />
					<Route path={Routes.applicationCompanyName} element={<ApplicationCompanyName />} />
					<Route path={Routes.applicationCompanyDetails} element={<ApplicationCompanyDetails />} />
				</Route>

				<Route element={<ProtectedRoute />}>
					<Route element={<DashboardPageLayout />}>
						<Route
							path={Routes.dashboard}
							element={<Navigate to={Routes.dashboardApplications} replace />}
						/>
						<Route path={Routes.dashboardApplications} element={<DashboardApplicationsList />} />
						<Route
							path={Routes.dashboardApplicationOverview}
							element={<DashboardApplicationOverview />}
						/>
						<Route path={Routes.dashboardSettings} element={<DashboardSettings />} />
					</Route>
					<Route element={<ApplicationPageLayout />}>
						<Route path={Routes.applicationResult} element={<ApplicationResult />} />
					</Route>
				</Route>

				<Route element={<AuthRoutes />}>
					<Route element={<ApplicationPageLayout />}>
						<Route
							path={Routes.applicationAccountCreation}
							element={<ApplicationAccountCreation />}
						/>
					</Route>
					<Route element={<AuthenticationPageLayout />}>
						<Route path={Routes.login} element={<Login />} />
						<Route path={Routes.forgotPassword} element={<ForgotPassword />} />
						<Route path={Routes.resetPassword} element={<ResetPassword />} />
						<Route path={Routes.register} element={<Register />} />
						<Route path={Routes.registerInvitation} element={<RegisterInvitation />} />
					</Route>
				</Route>

				<Route element={isAuthenticated ? <DashboardPageLayout /> : <AuthenticationPageLayout />}>
					<Route path={Routes.confirmEmail} element={<ConfirmEmail />} />
				</Route>
			</Route>

			<Route path="*" element={<NotFoundPage />} />
		</RouterRoutes>
	);
};

export default Router;
