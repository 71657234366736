import { FC } from "react";
import { Input, InputProps, Form } from "antd";
import { FormItemProps } from "antd/lib/form/FormItem";
import { useTranslations } from "../../../translations/translations";

interface Props extends FormItemProps {
	inputProps?: InputProps;
}

const PostcodeFormItem: FC<Props> = ({ inputProps, ...props }: Props) => {
	const t = useTranslations();

	return (
		<Form.Item
			label={t.postcode}
			{...props}
			rules={[
				{
					type: "string",
					max: 5,
					pattern: /^\d*$/i,
					message: t.fieldInvalid,
				},
				...(props.required
					? [
							{
								required: true,
								message: t.fieldRequired,
							},
					  ]
					: []),
				...(props.rules || []),
			]}
			validateFirst
		>
			<Input
				// tel type will show numeric keyboard on mobile
				// and allow to use max length
				type="tel"
				maxLength={5}
				placeholder={t.postcodePlaceholder}
				{...inputProps}
			/>
		</Form.Item>
	);
};

export default PostcodeFormItem;
