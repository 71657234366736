import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button, Dropdown, Menu } from "antd";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { LogoutOutlined, SettingOutlined, UserOutlined, EuroOutlined } from "@ant-design/icons";

import { Routes } from "src/Router";
import { MenuItem } from "./UserActionsComponents";
import { RootState } from "src/store/reducers/rootReducer";
import { logoutUser } from "src/utils/api/authentication";

const UserIcon = styled(UserOutlined)`
	display: flex !important;
	height: 26px;
	width: 26px;
	background-color: var(--teylor-grey-border);
	border-radius: 50%;
	color: var(--teylor-text-secondary);
	justify-content: center;
	align-items: center;
	font-size: 11px;
`;

const UserButton = styled(Button)`
	display: flex;
	align-items: center;
	font-size: 12px;
	color: var(--teylor-text-secondary);
	padding: 0;

	&:hover,
	&:active,
	&:focus {
		background: none;
		color: var(--teylor-text-secondary);
	}
`;

const DropdownMenu = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const logOut = () => void logoutUser().then(() => navigate(Routes.index));

	return (
		<Menu>
			<MenuItem key="my-loans">
				<Link to={Routes.dashboardApplications}>
					<EuroOutlined /> {t("dashboard.my-loans")}
				</Link>
			</MenuItem>
			<MenuItem key="settings">
				<Link to={Routes.dashboardSettings}>
					<SettingOutlined /> {t("dashboard.settings")}
				</Link>
			</MenuItem>
			<MenuItem key="logout" onClick={logOut}>
				<LogoutOutlined /> {t("dashboard.log-out")}
			</MenuItem>
		</Menu>
	);
};

const UserActions = () => {
	const { email } = useSelector((state: RootState) => state.userState);

	return (
		<Dropdown overlay={<DropdownMenu />} placement="bottomRight" arrow>
			<UserButton type="text">
				{email} <UserIcon />
			</UserButton>
		</Dropdown>
	);
};

export default UserActions;
