import { message } from "antd";
import {
	AxiosError,
	AxiosInstance,
	AxiosRequestConfig,
	AxiosResponse,
	AxiosResponseHeaders,
	default as axiosModule,
} from "axios";
import i18n from "i18next";

import store from "../store/Store";
import config from "../config";
import { logout } from "src/store/actions/userActions";
import { RootState } from "src/store/reducers/rootReducer";

export interface ErrorResponse extends Omit<AxiosError, "response"> {
	response: {
		data: {
			errors?: {
				code: string;
				message: string;
			}[];
		};
		status: number;
		statusText: string;
		headers: AxiosResponseHeaders;
		config: AxiosRequestConfig<unknown>;
		request?: unknown;
	};
}

const endpoints = {
	config: "/config",
	partnerKey: "/partner/key/",
	companySearch: "/company_search",
	users: "/users",
	profile: "/profile",
	accountLogin: "/account/login",
	accountLogout: "/account/logout",
	accountReset: "/account/reset",
	accountRegister: "/account/register",
	accountRecover: "/account/recover",
	accountInviteAccept: "/account/invite_accept",
};

class ApiWrapperAxiosSingleton {
	private static _instance: ApiWrapperAxiosSingleton;
	readonly axios!: AxiosInstance;
	readonly apiPath: string = config.api.LENDING_SERVICE_URL;

	private constructor() {
		axiosModule.defaults.withCredentials = true;

		this.axios = axiosModule.create({
			baseURL: this.apiPath,
			timeout: 30000,
		});

		this.axios.interceptors.request.use((config) => {
			// If a user is not authenticated do not call backend to avoid multiple 401 errors
			const state: RootState = store.getState();
			const isAuthenticated = state.userState.isAuthenticated;

			const endpointsWithoutAuth = [
				endpoints.config,
				endpoints.partnerKey,
				endpoints.companySearch,
				endpoints.users,
				endpoints.profile,
				endpoints.accountLogin,
				endpoints.accountLogout,
				endpoints.accountReset,
				endpoints.accountRegister,
				endpoints.accountRecover,
				endpoints.accountInviteAccept,
			];

			const isEndpointWithoutAuth = endpointsWithoutAuth.some((path) =>
				(config.url as string).includes(path)
			);

			return isAuthenticated || isEndpointWithoutAuth ? config : Promise.reject();
		});

		this.axios.interceptors.response.use(
			function (response) {
				return response;
			},
			function (error: AxiosError) {
				if (!error) {
					return Promise.reject();
				}

				if (error.response?.status !== 401) {
					return Promise.reject(error);
				}

				const responseURL = (error["request"] as { responseURL: string }).responseURL;

				if (
					!responseURL ||
					[
						endpoints.accountLogin,
						endpoints.accountRegister,
						endpoints.accountRecover,
						endpoints.accountInviteAccept,
					].every((path) => !responseURL.includes(path))
				) {
					store.dispatch(logout());
					void message.error(String(i18n.t("error-messages.logged-out")));
					return Promise.reject(error);
				}

				if (responseURL.includes(endpoints.accountInviteAccept)) {
					void message.error(String(i18n.t("error-messages.invite-token-invalid")));
					return Promise.reject(error);
				}

				if (responseURL.includes(endpoints.accountLogin)) {
					void message.error(String(i18n.t("error-messages.login-error")));
					return Promise.reject(error);
				}

				void message.error(String(i18n.t("error-messages.something-went-wrong")));
				return Promise.reject(error);
			}
		);
	}

	public error(error: ErrorResponse, errorMessage = "Internal Server Error"): Promise<never> {
		if (error.response?.status === 401) {
			return Promise.reject();
		}

		if (error.response?.data?.errors) {
			error.response.data.errors.forEach((error) => {
				if (error.message) {
					void message.error(error.message);
				} else if (i18n.exists(`error-code.${error.code}`)) {
					void message.error(String(i18n.t(`error-code.${error.code}`)));
				} else {
					void message.error(String(i18n.t("error-messages.something-went-wrong")));
				}
			});
			return Promise.reject();
		}

		void message.error(errorMessage);
		return Promise.reject();
	}

	public static get Instance(): ApiWrapperAxiosSingleton {
		return this._instance || (this._instance = new this());
	}

	public async get<Response>(
		path: string,
		config?: AxiosRequestConfig
	): Promise<AxiosResponse<Response>> {
		return this.axios.get(path, config);
	}

	public async post<Request, Response>(
		path: string,
		body?: Request | never,
		config?: AxiosRequestConfig
	): Promise<AxiosResponse<Response>> {
		return this.axios.post(path, body || {}, config || {});
	}

	public async put<Request, Response>(
		path: string,
		body?: Request | never,
		config?: AxiosRequestConfig
	): Promise<AxiosResponse<Response>> {
		return this.axios.put(path, body || {}, config || {});
	}

	public async patch<Request, Response>(
		path: string,
		body?: Request | never,
		config?: AxiosRequestConfig
	): Promise<AxiosResponse<Response>> {
		return this.axios.patch(path, body || {}, config || {});
	}

	public async delete<Response>(
		path: string,
		config?: AxiosRequestConfig
	): Promise<AxiosResponse<Response>> {
		return this.axios.delete(path, config || {});
	}
}

export const Axios = ApiWrapperAxiosSingleton.Instance;
