import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";
import { Form, FormProps, message, Modal, Space } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import RepresentativeCard from "@ui/representative-card/RepresentativeCard";
import RepresentativeModal from "@ui/representative-modal/RepresentativeModal";

import { ApplicationStatuses, SignatoryWithId } from "@teylor-tools/Api";
import { Axios, ErrorResponse } from "src/utils/Axios";
import TinFormItem, { parseTin } from "@ui/form/form-items/tin-form-item/TinFormItem";

const ExclamationCircleOutlinedIcon = styled(ExclamationCircleOutlined)`
	color: var(--ant-error-color) !important;
`;

interface Props {
	representative: SignatoryWithId;
	onDeleteOrUpdate: () => void;
	onUpdateTin: () => void;
	applicationStatus: ApplicationStatuses;
}

interface TinFormProps extends FormProps {
	taxid: string;
}

const TIN_FORM = "tin-form";

const tinNotEditableStatuses = [
	ApplicationStatuses.Open,
	ApplicationStatuses.FinalReview,
	ApplicationStatuses.AwaitingPayout,
	ApplicationStatuses.PaidOut,
	ApplicationStatuses.OfferRejected,
	ApplicationStatuses.Rejected,
];

const CompanyRepresentative = ({
	representative,
	onDeleteOrUpdate,
	onUpdateTin,
	applicationStatus,
}: Props) => {
	const { t } = useTranslation();
	const theme = useTheme();
	const { confirm } = Modal;
	const [tinModalVisibility, setTinModalVisibility] = useState(false);
	const [isRepresentativeModalOpen, setIsRepresentativeModalOpen] = useState(false);

	const [form] = Form.useForm<TinFormProps>();

	const representativeName = `${representative.first_name} ${representative.last_name}`;

	const showEditTinButton = !tinNotEditableStatuses.includes(applicationStatus);

	const representativeNationality =
		t("countries", { returnObjects: true }).find(
			(country) => country.code === representative.nationality
		)?.name || "";

	const deleteRepresentative = () => {
		confirm({
			title: t("dashboard.are-you-sure"),
			icon: <ExclamationCircleOutlinedIcon />,
			content: (
				<Trans i18nKey="dashboard.delete-representative-info">{{ name: representativeName }}</Trans>
			),
			okText: t("delete"),
			okButtonProps: { danger: true },
			cancelText: t("cancel"),
			onOk() {
				Axios.delete(`/user/signatories/${representative.signatory_id || ""}`).then(
					() => onDeleteOrUpdate(),
					(err: ErrorResponse) =>
						void Axios.error(err, t("error-messages.dashboard-delete-representative-error"))
				);
			},
		});
	};

	const handleUpdateTin = ({ taxid }: TinFormProps) => {
		Axios.patch<SignatoryWithId, never>(`/user/signatories/${representative.signatory_id}`, {
			...representative,
			taxid: parseTin(taxid),
		}).then(
			() => {
				closeTinModal();
				void message.success(t("dashboard.tin-added-successfully"));
				onUpdateTin();
			},
			(err: ErrorResponse) => void Axios.error(err, t("error-messages.error-updating-tin"))
		);
	};

	const editRepresentative = (signatory: SignatoryWithId) => {
		Axios.patch(`/user/signatories/${representative.signatory_id}`, {
			...signatory,
		}).then(
			() => {
				void message.success("Changes saved successfully");
				onDeleteOrUpdate();
			},
			(err: ErrorResponse) => void Axios.error(err, t("error-messages.error-try-again"))
		);
	};

	const closeTinModal = () => {
		setTinModalVisibility(false);
		form.resetFields();
	};

	return (
		<>
			<RepresentativeCard
				representative={representative}
				representativeNationality={representativeNationality}
				showEditTinButton={showEditTinButton}
				themedLinkColor={theme.color_link}
				onEditTin={() => setTinModalVisibility(true)}
				onEditRepresentative={() => setIsRepresentativeModalOpen(true)}
				onDeleteRepresentative={deleteRepresentative}
				showExtra={applicationStatus === ApplicationStatuses.Open}
			/>

			<Modal
				title={representative.taxid ? t("dashboard.edit-tin") : t("dashboard.add-tin")}
				visible={tinModalVisibility}
				onCancel={closeTinModal}
				cancelText={t("cancel")}
				okText={t("save")}
				okButtonProps={{ htmlType: "submit", form: TIN_FORM }}
			>
				<Form id={TIN_FORM} form={form} layout="vertical" onFinish={handleUpdateTin}>
					<TinFormItem
						name="taxid"
						initialValue={representative.taxid}
						rules={[{ required: true, message: t("form.field-required") }]}
						label={
							<Space size={4}>
								<Trans i18nKey="dashboard.tin-for-person">
									{{
										name: representativeName,
									}}
								</Trans>
							</Space>
						}
						inputProps={{ placeholder: t("form.tax-number-placeholder") }}
						errorMsg={t("error-messages.tax-number-invalid")}
					/>
				</Form>
			</Modal>
			{isRepresentativeModalOpen && (
				<RepresentativeModal
					save={editRepresentative}
					isVisible={isRepresentativeModalOpen}
					close={() => setIsRepresentativeModalOpen(false)}
					representative={representative}
				/>
			)}
		</>
	);
};

export default CompanyRepresentative;
