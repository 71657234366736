import styled from "styled-components";
import { Menu } from "antd";

export const MenuItem = styled(Menu.Item)`
	margin: 0 !important;

	&:not(:hover).ant-menu-item-selected {
		background-color: #fff !important;

		* {
			color: var(--teylor-text-primary);
		}
	}

	&:hover {
		background-color: var(--ant-primary-1);
	}

	a:hover {
		color: var(--teylor-text-primary);
	}

	.anticon {
		margin-right: 5px;
	}
`;
