import { combineReducers } from "redux";

import userReducer, { UserState } from "src/store/reducers/userReducer";
import partnerReducer, { PartnerState } from "./partnerReducer";
import configReducer, { ConfigState } from "./configReducer";

export interface RootState {
	userState: UserState;
	partnerState: PartnerState;
	configState: ConfigState;
}

const rootReducer = combineReducers<RootState>({
	userState: userReducer,
	partnerState: partnerReducer,
	configState: configReducer,
});

export default rootReducer;
