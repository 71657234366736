import { Link, useNavigate } from "react-router-dom";
import {
	Button as AntdButton,
	Dropdown,
	Layout as AntdLayout,
	Menu,
	Popover,
	Space,
	Typography,
} from "antd";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { RootState } from "src/store/reducers/rootReducer";
import { Routes } from "src/Router";
import ThemedButton from "src/components/themed-ui/ThemedButton";
import UserActions from "./UserActions";
import { DownOutlined, MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { languages } from "src/i18n/i18n";
import { MenuItem } from "src/components/layout/page-header/UserActionsComponents";

const { Header: BaseHeader } = AntdLayout;
const { Text } = Typography;

const HeaderWrapper = styled.div<{ transparentBg: boolean }>`
	background-color: ${({ transparentBg }) => (transparentBg ? "transparent" : "#fff")};
`;

const Header = styled(BaseHeader)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	max-width: var(--teylor-header-width);
	margin: 0 auto;
	background-color: transparent;
`;

const Logo = styled.img.attrs((props) => ({
	src: props.theme.logo_web_path,
	alt: "Logo",
	height: 40,
}))``;

const HeaderItemsWrapper = styled.div`
	display: flex;
	align-items: center;
	column-gap: 24px;
`;

const HeaderItemTitle = styled(AntdButton)`
	padding: 0;

	&:hover,
	&:active,
	&:focus {
		background: none;
	}
`;

const Divider = styled.span`
	display: inline-block;
	width: 1px;
	height: 32px;
	background-color: #f0f0f0;
`;

interface Props {
	showUserArea?: boolean;
	transparentBg?: boolean;
}

const PageHeader = ({ showUserArea = true, transparentBg = false }: Props) => {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();
	const { customer_support_phone, customer_support_email } = useSelector(
		(rootState: RootState) => rootState.partnerState
	);
	const isLoggedIn = useSelector((state: RootState) => state.userState.isAuthenticated);

	const changeLanguage = (langCode: string) => {
		void i18n.changeLanguage(langCode);
	};

	return (
		<HeaderWrapper transparentBg={transparentBg}>
			<Header>
				<Link to={Routes.index}>
					<Logo />
				</Link>

				<HeaderItemsWrapper>
					<Popover
						content={
							<Space direction={"vertical"} style={{ minWidth: 200 }}>
								<Text type="secondary">{t("headerNeedHelp")}</Text>
								{customer_support_phone && (
									<Space>
										<PhoneOutlined />
										<Text>{customer_support_phone}</Text>
									</Space>
								)}
								{customer_support_email && (
									<Space>
										<MailOutlined />
										<Text>{customer_support_email}</Text>
									</Space>
								)}
							</Space>
						}
					>
						<HeaderItemTitle type="text">Contact</HeaderItemTitle>
					</Popover>

					<Dropdown
						overlay={
							<Menu>
								{languages.map((lang) => (
									<MenuItem
										key={lang}
										onClick={() => changeLanguage(lang)}
										style={{ textAlign: "center", minWidth: 50 }}
									>
										{lang.toUpperCase()}
									</MenuItem>
								))}
							</Menu>
						}
						arrow={true}
					>
						<HeaderItemTitle type="text">
							{i18n.language.toUpperCase()} <DownOutlined />
						</HeaderItemTitle>
					</Dropdown>

					{showUserArea && (
						<>
							<Divider />

							{isLoggedIn ? (
								<UserActions />
							) : (
								<ThemedButton type="primary" onClick={() => navigate(Routes.login)}>
									{t("login")}
								</ThemedButton>
							)}
						</>
					)}
				</HeaderItemsWrapper>
			</Header>
		</HeaderWrapper>
	);
};

export default PageHeader;
