/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface PaymentProviderInfo {
	provider_client_id?: string;
	provider_client_alias?: string;
	provider_user_id?: string;
	provider_wallet_id?: string;
	provider_bank_account_id?: string;
	provider_mandate_id?: string;
	provider_mandate_reference?: string;
}

export interface Partner {
	name: string;
	short_id?: string;
	tenant_id?: string;
	partner_key?: string;
	status?: string;
	created_at?: string;
}

export interface PartnerResponse {
	partner_id?: string;
	name: string;
	short_id: string;
	tenant_id: string;
	partner_key: string;
	status: string;
	created_at: string;
}

export type PartnerResponseAdmin = PartnerResponse & { config: PartnerConfig };

export interface PartnerConfigResponse {
	id: string;
	partner_id: string;
	fe_theme: PartnerConfigTheme;
	parameters: PartnerConfigParameters;
	terms_url: string;
	is_partner_terms_required: boolean;
	data_protection_url: string;
	entitlements?: Entitlements[];
	application_types: AllBanksLoanPurposes[];
	user_application_types: AllBanksLoanPurposes[];
}

export interface PublicFeatureValues {
	supported: string[];
	default: string;
	terms?: string;
	privacy_policy?: string;
	imprint?: string;
}

export interface PublicFeature {
	feature_id: number;
	name: PublicFeatureName;
	description?: string;
	value: PublicFeatureValues;
	is_active: boolean;
	is_deleted: boolean;
	is_public: boolean;
}

export interface ConfigResponse {
	partner_config?: PartnerConfigResponse;
	public_features: PublicFeature[];
}

export interface PartnerConfig {
	fe_theme: PartnerConfigTheme;
	terms_url?: string;
	is_partner_terms_required: boolean;
	data_protection_url?: string;
	application_types?: AllBanksLoanPurposes[];
	user_application_types?: AllBanksLoanPurposes[];
}

export type PartnerConfigWithSelectors = PartnerConfig & {
	id: string;
	partner_id: string;
	parameters: PartnerConfigParameters;
	default_product_selectors: PartnerDefaultProductSelector[];
};

export interface PartnerConfigTheme {
	color_link?: string;
	color_primary: string;
	logo_web_path: string;
	color_cta_fill?: string;
	color_cta_text: string;
}

export interface PartnerConfigParameters {
	customer_support_email: string;
	customer_support_phone: string;
	display_name: string;
}

export interface PartnerDefaultProductSelector {
	product_default_selection_id: string;
	product_id: string;
	partner_id: string;
	condition: string;
	weight: number;
}

export interface PartnerDefaultProductSelectorRequest {
	product_id: string;
	condition: string;
	weight: number;
}

export interface DebtNote {
	debt_note_id: string;
	name: string;
}

export interface Application {
	application: UserApplication;
}

export interface ApplicationParametersResponse {
	application_id: string;
	loan_amount: string;
	loan_duration: number;
	representative_monthly_rate?: string;
}

export interface UserApplication {
	applicationId: string;
	allFilesUploaded?: boolean;
	allDocsSubmitted: boolean;
	bankName?: string;
	businessPurpose?: string;
	companyCountry: string;
	companyId: string;
	companyLocation: string;
	companyFoundationDate?: string;
	companyName: string;
	companyPostCode: string;
	companyType: CompanyTypes;

	/** @example 2021-04-07T09:43:37.765Z */
	created?: string;
	houseNumber?: string;
	hrNumber: string;
	iban?: string;

	/** duration in months */
	loanDuration: number;

	/** @example EUR */
	currency?: string;
	loanPurpose?: string;
	loanPurposeNumberOfMachines?: number;
	loanPurposePriceOfMachines?: number;

	/** @example 200000.00 */
	loanSize: string;
	pricing: ApplicationPricing;
	purposeDescription?: string;
	rate: string;
	representative_monthly_rate?: string;
	monthlyRate: string;
	sepaMandatAccepted?: boolean;
	shortApplicationId: string;
	status: ApplicationStatuses;
	street?: string;
	taxIdNumber: string;
	language?: string;
	possible_states: ApplicationStatuses[];
	status_flow: StatusFlowItem[];
	finance_objects?: FinanceObjectGetResponse[];
	country?: string;
}

export interface UserApplicationsResponse {
	pagination?: LegacyPagination;
	applications?: UserApplication[];
}

export interface IdentificationStatus {
	application_id?: string;
	signatory_id?: string;
	is_complete?: boolean;
	ident?: string;
	company_name?: string;
	signatory_name?: string;
	provider_status?:
		| "SUCCESS"
		| "SUCCESS_DATA_CHANGED"
		| "FRAUD_SUSPICION_CONFIRMED"
		| "IDENT_EXPIRED"
		| "ABORTED"
		| "REVIEW_PENDING"
		| "FRAUD_SUSPICION_PENDING";

	/** @format datetime */
	created_at?: string;
}

export interface SignatoryIdentStatus {
	transaction_id?: string;
	signatory_id?: string;
	ident_type?: "ident esign";
	provider_transaction_id?: string;
	provider_status?: "SUCCESS SUCCESS_DATA_CHANGED FRAUD_SUSPICION_CONFIRMED ABORTED";

	/** @format datetime */
	provider_status_updated_at?: string;

	/** @format datetime */
	created_at?: string;
}

export interface OcrFileCreate {
	document_id: string;
	document_type: "annual_statement de_bwa_susa";
	period_type: "interim full_year";
	year?: number;
	month?: number;
}

export interface OcrFileUpdateInfo {
	period_type?: "interim full_year";
	year?: number;
	month?: number;
}

export interface OcrFileResponse {
	document_id?: string;
	document_type?: "annual_statement de_bwa_susa";
	period_type?: "interim full_year";
	year?: number;
	month?: number;
	status?: "request_failed" | "upload_pending" | "scanning" | "completed" | "error";
}

export enum LoanProviders {
	ING = "ING",
	SocieteGenerale = "Societe_Generale",
	SolarisBank = "Solaris_Bank",
	KFW = "KFW",
	EMPTY = "EMPTY",
	TEYLOR = "TEYLOR",
}

export enum ApplicationStatuses {
	PreCheck = "pre_check",
	OfferRejected = "Offer_Rejected",
	Open = "Open",
	Signature = "Signature",
	InternalReview = "Internal_Review",
	InternalPricing = "internal_pricing",
	ExternalPricing = "external_pricing",
	FinalOffer = "Final_Offer",
	Reopened = "Reopened",
	Pricing = "Pricing",
	Submitted = "Submitted",
	AwaitingPayout = "Awaiting_Payout",
	FinalReview = "Final_Review",
	Approved = "Approved",
	Rejected = "Rejected",
	PaidOut = "Paid_Out",
	BankOpen = "Bank_Open",
	BankSubmitted = "bank_submitted",
	OfferAvailable = "offer_available",
	OfferAccepted = "offer_accepted",
}

export enum PublicFeatureName {
	CommunicationLanguages = "communication_languages",
	UserLocaleRegion = "user_locale_region",
	Currencies = "currencies",
	UrlList = "url_list",
}

export interface StatusFlowItem {
	status_name: ApplicationStatuses;
	is_current_status: boolean;
}

export interface LegacyPagination {
	currentPage?: string;
	itemsPerPage?: string;
	numberOfRows?: string;
}

export interface Pagination {
	total_rows: number;
	current_page: number;
	items_per_page: number;
}

export interface ScoringFeedback {
	/** min length 200 characters */
	scoring_feedback: string;
	flags_reviewed: boolean;
}

export enum ChecklistType {
	Application = "application",
	FourEyeCheck = "four_eye_check",
}

export enum LoanStatus {
	Open = "open",
	Delay = "delay",
	Default = "default",
	Repaid = "repaid",
}

export interface ChecklistTemplateResponse {
	id?: number;
	version?: number;
	type?: ChecklistType;
	checklist_template?: ChecklistTemplate;
}

export interface ApplicationChecklistResponse {
	application_checklist_id: number;
	checklist_id: number;
	application_id: string;
	checklist_data: ChecklistTemplate;
}

/**
 * map where key is id of the item and value is selected state or text
 */
export type ChecklistUpdateMap = Record<string, any>;

export interface ChecklistTemplate {
	checklist_meta: { version: string; funding_source: string };
	groups: { id: string; category: string; description: string; items: ChecklistItemTemplate[] }[];
}

export interface ChecklistItemTemplate {
	id: string;
	name: string;
	item_type: "text" | "select" | "checkbox";
	text: string;
	description: string;
	options: {
		id: string;
		name: string;
		description: string;
		selected: boolean;
		type: "black" | "grey" | "white";
		requirements?: { min_length?: number };
	}[];
}

export interface IncomingApplicationPricing {
	interest_rate?: number;
	transaction_fee?: number;
	external_interest_rate?: number;
}

export type ApplicationPricing = IncomingApplicationPricing & {
	external_interest_rate?: number;
	external_pricing_allowed?: boolean;
	final_interest_rate?: number;
	offer_representative_monthly_rate?: number;
	offer_rate_high?: number;
	offer_rate_low?: number;
	offer_is_range?: boolean;
	monthly_payment?: number;
	is_final_pricing?: boolean;
};

export interface SignatoryRequirementsUserRequest {
	signatory_id?: string;
	application_id?: string;
	sepa_mandate_accepted?: boolean;
	legal_signature_accepted?: boolean;
}

export interface SignatoryRequirementsAdminRequest {
	signatory_id: string;
	application_id: string;
	is_loan_signature_required: boolean;
	is_ubo_signature_required: boolean;
	is_video_id_required: boolean;
	guarantor_contract_url: string;
	guarantor_contract_filename: string;
	digital_signature_url: string;
}

export type SignatoryRequirements = (SignatoryRequirementsAdminRequest &
	SignatoryRequirementsUserRequest &
	SignatureStatusResponseForBOUsers &
	SignatureStatusResponseForUsers) & {
	is_signature_complete?: boolean;
	signature_completed_at?: string;
	signature_results_url?: string;
	sepa_mandate_accepted_at?: string;
	legal_signature_accepted_at?: string;
};

export interface SignatureStatusResponseForBOUsers {
	signature_internal_status?: SignatureInternalStatus;
	signature_user_status?: SignatureUserFacingStatus;
	signature_provider_status?: string;
	signature_provider?: SignatureProvider;
}

export interface SignatureStatusResponseForUsers {
	signature_status?: SignatureUserFacingStatus;
}

export enum SignatureInternalStatus {
	NotStarted = "not_started",
	InProgress = "in_progress",
	Completed = "completed",
	UnderReview = "under_review",
	Canceled = "canceled",
	Failed = "failed",
	Expired = "expired",
}

export enum SignatureUserFacingStatus {
	Completed = "completed",
	NotCompleted = "not_completed",
	Pending = "pending",
}

export enum SignatureProvider {
	Idnow = "idnow",
	SwissSign = "swiss_sign",
}

export interface Signatory {
	/** @example Jonh */
	first_name: string;

	/** @example Snow */
	last_name: string;

	/** @example DE */
	country: string;

	/** @example Berlin */
	location: string;
	street: string;
	street_number: string;
	postcode: string;
	telephone: string;
	email?: string;

	/** @example F */
	gender?: string;
	birthdate: string;

	/**
	 * 2 characters country code (GB, DE, US, ....)
	 * @example US
	 */
	nationality?: string;
	taxid: string;
	percentage_owned: string;
	application_id: string;
	is_beneficial_owner: boolean;
	is_legal_signatory: boolean;
	is_sole_signatory: boolean;
	is_contract_signatory: boolean;
	invite_status?: string;

	/**
	 * @format datetime
	 * @example "2022-08-24T09:57:18.000Z"
	 */
	invite_status_updated_at?: string;
	requirements?: SignatoryRequirements;
}

export type SignatoryResponse = SignatoryWithId & {
	signatory_id: string;
	credit_score?: number;
	credit_score_info_text?: string;
	credit_score_provider?: string;
	credit_score_updated_at?: string;
	requirements?: SignatoryRequirements;
};

export type SignatoryWithId = Signatory & { signatory_id: string };

export type SignatoriesRequest = Signatory[];

export interface SignatoriesResponse {
	signatories: SignatoryResponse[];
	warnings: { warning_id: string; text_en: string; resource_id: string }[];
}

export interface Loan {
	/** @example ln_abcdef */
	loan_id: string;

	/** @example app_134def */
	application_id: string;
	loan_status: LoanStatus;

	/** @example 12345abcdf */
	loan_id_short: string;

	/**
	 * @format currency
	 * @example 1234.56
	 */
	amount: string;

	/** @example EUR */
	currency?: string;

	/** @example de */
	language?: string;

	/** @example DE */
	country?: string;

	/** @example 48 */
	duration_months: number;

	/**
	 * @format float
	 * @example 5.52
	 */
	interest_rate: string;

	/** @format date */
	payout_date: string;

	/** @format date */
	due_date: string;

	/** @format date-time */
	created_at: string;

	/**
	 * @format currency
	 * @example 1234.56
	 */
	begin_balance_total: string;

	/**
	 * @format currency
	 * @example 1234.56
	 */
	begin_balance_principal: string;

	/**
	 * @format currency
	 * @example 1234.56
	 */
	begin_balance_interest: string;

	/**
	 * @format currency
	 * @example 1234.56
	 */
	begin_balance_fees: string;

	/**
	 * @format currency
	 * @example 1234.56
	 */
	outstanding_balance_total: string;

	/**
	 * @format currency
	 * @example 1234.56
	 */
	outstanding_balance_principal: string;

	/**
	 * @format currency
	 * @example 1234.56
	 */
	outstanding_balance_interest: string;

	/**
	 * @format currency
	 * @example 1234.56
	 */
	outstanding_balance_fees: string;
	is_monitored: boolean;
	monitor_details: string;
	is_review_required: boolean;
	company: Company;
	payment_plan: PaymentPlan;
	debt_note_id?: string;
	debt_note_name?: string;
	external_reference: string;
	review_details: string;
	fronting_bank_iban?: string;
	product?: ProductResponse;
}

export interface PaymentPlan {
	amount_total: string;
	payment_plan_id: string;
	application_id: string;
	loan_id: string;
	interest_total: string;
	payment: string;
	installments: PaymentPlanInstallment[];
}

export interface Company {
	companyId: string;
	company_id_short: string;
	companyName: string;
	companyType: CompanyTypes;
	email: string;
	street: string;
	houseNumber?: string;
	companyLocation: string;
	companyPostCode: string;
	companyCountry: string;
	numberOfEmployees?: number;
	creditScore?: number;
	brancheCode?: string;
	hrNumber?: string;
	companyFoundationDate?: string;
	taxIdNumber?: string;
	businessPurpose?: string;
	creditreformShareCapital?: number;
	creditreformNumberOfShareholders?: number;
	shareHolderOneSharesValue?: number;
	shareHolderOneSharesPercentage?: number;
	shareHolderTwoSharesValue?: number;
	shareHolderTwoSharesPercentage?: number;
	shareHolderThreeSharesValue?: number;
	shareHolderThreeSharesPercentage?: number;
}

export interface PaymentPlanInstallment {
	installment_id: string;
	payment_plan_id: string;
	installment_index: number;
	is_paid: boolean;

	/** @format date */
	due_date: string;

	/**
	 * @format currency
	 * @example 1234.56
	 */
	amount: string;

	/** @example scheduled */
	status: InstallmentStatuses;

	/** @example EUR */
	currency: string;
	payment_reference: string;

	/**
	 * @format currency
	 * @example 1234.56
	 */
	repaid_total: string;

	/**
	 * @format currency
	 * @example 1234.56
	 */
	amount_principal: string;

	/**
	 * @format currency
	 * @example 1234.56
	 */
	amount_interest: string;

	/**
	 * @format currency
	 * @example 1234.56
	 */
	outstanding_total: string;

	/**
	 * @format currency
	 * @example 1234.56
	 */
	outstanding_principal: string;

	/**
	 * @format currency
	 * @example 1234.56
	 */
	outstanding_interest: string;

	/** @example ["request_payment"] */
	actions: InstallmentActions;
}

export enum InstallmentStatuses {
	Scheduled = "scheduled",
	PaymentPending = "payment_pending",
	Paid = "paid",
	Canceled = "canceled",
	Error = "error",
}

export enum InstallmentActions {
	RequestPayment = "request_payment",
	ReconcilePayment = "reconcile_payment",
	ViewPayment = "view_payment",
}

export interface LoanTransactionsResponse {
	transactions?: LoanTransaction[];
}

export interface LoanTransaction {
	transaction_id: string;
	loan_id: string;
	transaction_type: string;

	/**
	 * debit or credit
	 * @example credit
	 */
	deb_cr: string;

	/** @format date */
	booking_date: string;

	/**
	 * @format currency
	 * @example 1234.56
	 */
	amount_total: string;

	/**
	 * @format currency
	 * @example 1234.56
	 */
	amount_principal: string;

	/**
	 * @format currency
	 * @example 1234.56
	 */
	amount_interest: string;

	/**
	 * @format currency
	 * @example 1234.56
	 */
	amount_fees: string;

	/** @example EUR */
	currency: string;

	/**
	 * @format currency
	 * @example 1234.56
	 */
	beg_bal_total: string;

	/**
	 * @format currency
	 * @example 1234.56
	 */
	beg_bal_principal: string;

	/**
	 * @format currency
	 * @example 1234.56
	 */
	beg_bal_interest: string;

	/**
	 * @format currency
	 * @example 1234.56
	 */
	beg_bal_fees: string;

	/**
	 * @format currency
	 * @example 1234.56
	 */
	end_bal_total: string;

	/**
	 * @format currency
	 * @example 1234.56
	 */
	end_bal_principal: string;

	/**
	 * @format currency
	 * @example 1234.56
	 */
	end_bal_interest: string;

	/**
	 * @format currency
	 * @example 1234.56
	 */
	end_bal_fees: string;
	description: string;
	bank_transaction_id?: string;

	/** @format date-time */
	created_at: string;
}

export interface UserProfile {
	/** @example usr_1rnby4knzmkTO56VsWyfeP6bsBk */
	user_id: string;

	/** @example a1b2c3d4ef */
	user_id_short?: string;

	/** @example user@teylor.com */
	email: string;
	name: string;
	is_enabled: boolean;

	/** @format datetime */
	created?: string;
	role: string;
	financialAdvisor: boolean;
	hasAcceptedTermsAndConditions: boolean;
	accepted_partner_terms?: boolean;
	mainContactNumber: string;
	emailVerified: boolean;
	guest: boolean;
	testUser: boolean;
	solarisBankOnly: boolean;
	language: string;
	region: string;
}

export interface UserProfileUpdate {
	language?: string;
	region?: string;
}

export interface PartnerUser {
	entity_id?: string;
	partner_id?: string;
	status?: string;
	email?: string;
	name?: string;
	mobile_number?: string;
	role_id?: string;

	/** @format datetime */
	created?: string;
}

export interface BOUserProfile {
	/** @example bou_1rnby4knzmkTO56VsWyfeP6bsBk */
	id: string;

	/** @example admin@teylor.com */
	email: string;

	/** @example ["application","bouser","loan","checklist"] */
	features: (
		| "application"
		| "payment"
		| "lead"
		| "user"
		| "loan"
		| "checklist"
		| "system_settings"
	)[];
}

export interface BOUserRole {
	/** @example role_system_admin */
	id?: string;

	/** @example system-admin */
	name?: string;

	/** @example can do anything they want */
	description?: string;
}

export interface DocumentTemplate {
	document_template_id?: string;
	version?: number;
	document_type?: "loan_contract guarantor_contract payment_confirmation";
	product_id?: string;

	/** @format datetime */
	created?: string;
	aws_id?: string;
	language?: string;
	country?: string;
}

export enum ErrorCode {
	EntityNotFound = "entity_not_found",
	NoSignatoryRequirements = "no_signatory_requirements",
	ErrApplicationPayoutChecklistNotDone = "err_application_payout_checklist_not_done",
	ErrApplicationFourEyesChecklistNotDone = "err_application_four_eyes_checklist_not_done",
	ErrApplicationNoBankName = "err_application_no_bank_name",
	ErrApplicationNoIban = "err_application_no_iban",
	ErrApplicationNoSignatories = "err_application_no_signatories",
	ErrApplicationNoSignatoriesSignLoanContract = "err_application_no_signatories_sign_loan_contract",
	ErrApplicationNoTaxidForSignatory = "err_application_no_taxid_for_signatory",
	ErrApplicationNoLoanContract = "err_application_no_loan_contract",
	ErrApplicationInternalError = "err_application_internal_error",
	ErrApplicationNoSignatoryContract = "err_application_no_signatory_contract",
	ErrApplicationSignatoriesSoleOrAtleast2 = "err_application_signatories_sole_or_atleast_2",
	ErrApplicationNoSignatoryIdentUrl = "err_application_no_signatory_ident_url",
	ErrApplicationNoPricing = "err_application_no_pricing",
	ErrSharePercentageGreaterThen100 = "err_share_percentage_greater_then_100",
	ErrApplicationSignatoryIdentNotDone = "err_application_signatory_ident_not_done",
	ErrApplicationMissingCompany = "err_application_missing_company",
	ErrApplicationCompanyTaxidNotSpecified = "err_application_company_taxid_not_specified",
	ErrApplicationUserIsTeylorEmployee = "err_application_user_is_teylor_employee",
	ErrMissingScoringData = "err_missing_scoring_data",
	ErrMissingScoringConfig = "err_missing_scoring_config",
	ErrLowScoringMark = "err_low_scoring_mark",
	ErrTooManyRedFlags = "err_too_many_red_flags",
	ErrTooManyWarnings = "err_too_many_warnings",
	ErrCrefoCompanySearchFailed = "err_crefo_company_search_failed",
	ErrChecklistBadScore = "err_checklist_bad_score",
	ErrChecklistNotExist = "err_checklist_not_exist",
	ErrChecklistNotDone = "err_checklist_not_done",
	ErrFinancialsNotExist = "err_financials_not_exist",
	ErrCompanyCountryNotAllowed = "err_company_country_not_allowed",
	ErrCompanyTypeNotAllowed = "err_company_type_not_allowed",
	ErrCompanyCreditScoreNotAllowed = "err_company_credit_score_not_allowed",
	ErrCompanyCreditScoreTooHigh = "err_company_credit_score_too_high",
	ErrCompanyCreditScoreTooLow = "err_company_credit_score_too_low",
	ErrApplicationRoleInvalid = "err_application_role_invalid",
	ErrStatusChangeNotAllowed = "err_status_change_not_allowed",
	ErrScoringFeedbackNotDone = "err_scoring_feedback_not_done",
	ErrCurrencyNotAllowed = "err_currency_not_allowed",
	ErrApplicationNoExternalPricing = "err_application_no_external_pricing",
	ErrDocumentAnnualStatmentMissing = "err_document_annual_statment_missing",
	ErrDocumentBwaSusaMissing = "err_document_bwa_susa_missing",
	ErrDocumentDebtRegistryMissing = "err_document_debt_registry_missing",
	ErrDocumentGuarantorConractMissing = "err_document_guarantor_conract_missing",
	ErrSepaMandateNotAccepted = "err_sepa_mandate_not_accepted",
	ErrRepaymentPlanNotGenerated = "err_repayment_plan_not_generated",
	ErrLoanStatusNotPending = "err_loan_status_not_pending",
	ErrLoanCannotChangeLoanStatus = "err_loan_cannot_change_loan_status",
	ErrLoanCannotChangePayoutDate = "err_loan_cannot_change_payout_date",
	ErrLoanDebtNoteAssignedToOneOrMoreLoans = "err_loan_debt_note_assigned_to_one_or_more_loans",
	ErrLoanDebtNoteNameIsEmpty = "err_loan_debt_note_name_is_empty",
	ErrLoanEntityNotFound = "err_loan_entity_not_found",
	PaymentCurrencyDiffersFromInstallmentCurrency = "payment_currency_differs_from_installment_currency",
	PaymentInstallmentIDDiffersFromInstallmentID = "payment_installment_ID_differs_from_installment_ID",
	PaymentAmountDiffersFromInstallmentAmount = "payment_amount_differs_from_installment_amount",
	PaymentStatusIsNotCorrectForReconciling = "payment_status_is_not_correct_for_reconciling",
	InstallmentAlreadyHasPaymentTransactions = "installment_already_has_payment_transactions",
	InstallmentHasWrongStatusForApplyingAPayment = "installment_has_wrong_status_for_applying_a_payment",
	InstallmentHasWrongStatusForRequestingAPayment = "installment_has_wrong_status_for_requesting_a_payment",
	ErrApiDeprectated = "err_api_deprectated",
	ErrLoanMustBePositive = "err_loan_must_be_positive",
	ErrUploadForbiddenForPendingLoan = "err_upload_forbidden_for_pending_loan",
	ErrInvalidProviderType = "err_invalid_provider_type",
	ErrProviderNotConnected = "err_provider_not_connected",
	ErrIbanOrBicNotFound = "err_iban_or_bic_not_found",
	ErrSignatoriesNotSignLoanContract = "err_signatories_not_sign_loan_contract",
	ErrProviderMangopayFailed = "err_provider_mangopay_failed",
	ErrApplicationStatusOwnerDuplicate = "err_application_status_owner_duplicate",
	ErrPercentageOwnedValueInvalid = "err_percentage_owned_value_invalid",
	ErrPricingInterestRateMustBeSet = "err_pricing_interest_rate_must_be_set",
	ErrPricingInterestRateMustBePositive = "err_pricing_interest_rate_must_be_positive",
	ErrPricingInterestRateIsHigherThanAllowed = "err_pricing_interest_rate_is_higher_than_allowed",
	ErrPricingInterestRateIsLowerThanAllowed = "err_pricing_interest_rate_is_lower_than_allowed",
	ErrPricingChangeNotAllowed = "err_pricing_change_not_allowed",
	ErrPricingNoScoringDataAvailable = "err_pricing_no_scoring_data_available",
	ErrPricingTransactionFeeMustBePositive = "err_pricing_transaction_fee_must_be_positive",
	ErrAuthEntityNotFound = "err_auth_entity_not_found",
	ErrAuthEntityIsDisabled = "err_auth_entity_is_disabled",
	ErrAuthEntityIsDeleted = "err_auth_entity_is_deleted",
	ErrAuthInvalidCredentials = "err_auth_invalid_credentials",
	ErrAuthSessionNotFound = "err_auth_session_not_found",
	ErrAuthSessionExpired = "err_auth_session_expired",
	ErrAuthTokenUsed = "err_auth_token_used",
	ErrAuthTokenExpired = "err_auth_token_expired",
	ErrAuthRoleNotFound = "err_auth_role_not_found",
	ErrAuthEmailNotYetConfirmed = "err_auth_email_not_yet_confirmed",
	ErrAuthEmailConflict = "err_auth_email_conflict",
	ErrAuthPasswordTooShort = "err_auth_password_too_short",
	ErrAuthPasswordTooLong = "err_auth_password_too_long",
	ErrAuthPasswordTooSimple = "err_auth_password_too_simple",
	ErrBankTxStatusNotCorrectForReconciling = "err_bank_tx_status_not_correct_for_reconciling",
	ErrPaymentProviderMangopayConfirmationFailed = "err_payment_provider_mangopay_confirmation_failed",
	ErrPaymentProviderUnknown = "err_payment_provider_unknown",
	ErrBouserEmailAlreadyExists = "err_bouser_email_already_exists",
	ErrBouserEmailProvider = "err_bouser_email_provider",
	ErrIdnowInvalidIdentType = "err_idnow_invalid_ident_type",
	ErrPaymentPlanNotExist = "err_payment_plan_not_exist",
	ErrPaymentPlanLoanStatusMustBeOpenOrPending = "err_payment_plan_loan_status_must_be_open_or_pending",
	ErrPaymentPlanApplicationStatusMustBeAwaitingPayout = "err_payment_plan_application_status_must_be_awaiting_payout",
	ErrLocaleNotFound = "err_locale_not_found",
}

export interface InstallmentPaymentsResponse {
	bank_transactions?: BankTransaction[];
}

export interface BankTransaction {
	/** @example btx_23N4e53YUPMMrtdYBRJRWAqTHHW */
	transaction_id?: string;

	/** @example payment_request */
	transaction_type?: BankTransactionType;

	/**
	 * debit or credit
	 * @example credit
	 */
	deb_cr?: string;

	/**
	 * @format date
	 * @example 2022-05-31
	 */
	booking_date?: string;

	/**
	 * @format currency
	 * @example 1234.56
	 */
	amount?: string;

	/** @example EUR */
	currency?: string;

	/** @example posted */
	status?: BankTransactionStatus;

	/** @example SUCCEEDED */
	provider_status?: string;

	/** @example ln_20B1yXb5jj9N0CffCc8xy6lov4A */
	loan_id?: string;

	/** @example ppi_20B7R1ORT7Zv5x4STcT7pjMGsjy */
	installment_id?: string;

	/** @example 12345678 */
	provider_transaction_id?: string;

	/** @example teylor kredit */
	customer_reference?: string;
	provider_reference?: string;
	provider_id?: string;

	/** @format date-time */
	created_at?: string;
	details?: object;
}

export interface BankTransactionCreateData {
	transaction_type?: string;

	/**
	 * debit or credit
	 * @example credit
	 */
	deb_cr?: string;

	/**
	 * @format date
	 * @example 2022-05-31
	 */
	booking_date?: string;

	/**
	 * @format currency
	 * @example 1234.56
	 */
	amount?: string;

	/** @example EUR */
	currency?: string;

	/** @example posted */
	status?: BankTransactionStatus;

	/** @example SUCCEEDED */
	provider_status?: string;

	/** @example 12345678 */
	provider_transaction_id?: string;

	/** @example teylor kredit */
	customer_reference?: string;
	provider_reference?: string;
	provider_id?: string;
	details?: object;
}

export enum BankTransactionStatus {
	Pending = "pending",
	Posted = "posted",
	Reconciled = "reconciled",
	Canceled = "canceled",
	Rejected = "rejected",
	Error = "error",
}

export enum BankTransactionType {
	PaymentRequest = "payment_request",
}

export interface StatusOwnerSettingsItem {
	application_status?: ApplicationStatuses;
	assignee_ids?: string[];
}

export interface TaskStatus {
	task_status_id?: string;
	name?: string;
	index?: number;
	is_visible?: boolean;

	/** @format datetime */
	created_at?: string;
}

export interface TaskTag {
	task_tag_id?: string;
	name?: string;
	is_visible?: boolean;

	/** @format datetime */
	created_at?: string;
}

export enum TaskSubjectKind {
	Application = "application",
	Loan = "loan",
}

export enum TaskAssigneeKind {
	BoUser = "bo_user",
}

export interface TaskResponse {
	task_id?: string;
	subject_id?: string;
	subject_kind?: TaskSubjectKind;
	created_by?: string;
	assignee_id?: string;
	assignee_kind?: TaskAssigneeKind;
	title?: string;
	details?: object;
	due_date?: string | null;

	/** @format datetime */
	created_at?: string;
	tag_names?: string[];
	status?: TaskStatus;
}

export interface CreateTaskRequest {
	subject_id?: string;
	subject_kind?: TaskSubjectKind;
	assignee_id?: string;
	assignee_kind?: TaskAssigneeKind;
	title?: string;
	details?: object;
	due_date?: string;
	status_id?: string;
	tag_ids?: string[];
}

export interface UpdateTaskRequest {
	assignee_id?: string;
	assignee_kind?: TaskAssigneeKind;
	title?: string;
	details?: object;
	due_date?: string;
	tag_ids?: string[];
}

export interface UpdateTaskStatusSettingRequest {
	name?: string;
	index?: number;
	is_visible?: boolean;
}

export interface CreateTaskStatusSettingRequest {
	name?: string;
	index?: number;
	is_visible?: boolean;
}

export interface UpdateTaskTagSettingRequest {
	is_visible?: boolean;
}

export interface CreateTaskTagSettingRequest {
	name?: string;
	is_visible?: boolean;
}

export interface UserResponse {
	user_id: string;
	user_id_short?: string | null;
	email?: string;
	name?: string;

	/** @format datetime */
	created?: string | null;
	role?: string;
	financialAdvisor?: boolean;
	financialAdvisorAddress?: string | null;
	confirmationCode?: string | null;
	hasAcceptedTermsAndConditions?: boolean;
	userIdentityId?: string;
	campaign?: string | null;
	campaignArriveTime?: string | null;
	campaignSId?: string | null;
	mainContactNumber?: string;
	emailVerified?: boolean;
	guest?: boolean;
	testUser?: boolean;
	solarisBankOnly?: boolean;
	is_deleted?: boolean;
	has_credentials?: boolean;
	is_enabled?: boolean;
	is_email_confirmed?: boolean;
}

export interface CompanyUserResponse {
	campaign: string | null;
	campaignArriveTime: string | null;
	campaignSId: string | null;
	confirmationCode: string | null;

	/** @format datetime */
	created: string | null;
	email: string;
	emailVerified: boolean;
	financialAdvisor: boolean;
	financialAdvisorAddress: string | null;
	guest: boolean;
	hasAcceptedTermsAndConditions: boolean;
	is_deleted: boolean;
	mainContactNumber: string;
	name: string;
	role: string;
	solarisBankOnly: boolean;
	testUser: boolean;
	userIdentityId: string;
	user_id: string;
	user_id_short: string | null;
}

export enum NoteSubjectKind {
	Application = "application",
	Lead = "lead",
}

export interface NoteMessage {
	blocks?: NoteMessageBlock[];
}

export interface NoteMessageBlock {
	text?: string;
}

export interface NoteResponse {
	note_id: string;
	subject_kind: string;
	subject_id: string;
	created_by: string;
	message?: NoteMessage;

	/** @format datetime */
	created_at: string;
}

export interface CreateNoteRequest {
	subject_kind: NoteSubjectKind;
	subject_id: string;
	message?: NoteMessage;
}

export interface UpdateNoteRequest {
	message?: object;
}

export interface ProductResponse {
	product_family_id: string;
	product_family_name: string;
	family_description?: string;
	product_id: string;
	product_name: string;
	product_description?: string;
}

export interface AvailableProductFamily {
	family_id?: string;
	family_name?: string;
	family_description?: string;
	products?: ProductResponse[];
}

export interface Product {
	product_id?: string;
	name?: string;
	description?: string;
	product_family_id?: string;
	family_name?: string;
	family_description?: string;
	is_enabled?: boolean;
}

export interface ProductRequest {
	name: string;
	description: string;
	product_family_id: string;
	is_enabled?: boolean;
}

export interface ProductFamily {
	product_family_id?: string;
	name?: string;
	description?: string;
}

export interface ProductFamilyRequest {
	name: string;
	description?: string;
}

export interface ProductConfigRequest {
	category: string;
	type: string;
	purposes: string[];
	company_types: string[];
	company_locations: string[];
	min_credit_score: number;
	max_credit_score: number;
	min_company_age: number;
	min_amount: number;
	max_amount: number;
	min_length: number;
	max_length: number;
	currencies: string[];
	legacy_provider?: string;
	is_enabled: boolean;
	config_version: number;
}

export interface ProductConfigResponse {
	product_config_id: string;
	product_id: string;
	config_version: number;
	legacy_provider?: string;
	category: string;
	type: string;
	purposes: string[];
	company_types: string[];
	company_locations: string[];
	min_credit_score?: number;
	max_credit_score?: number;
	min_company_age?: number;
	requirements?: object;
	min_amount?: number;
	max_amount?: number;
	min_length?: number;
	max_length?: number;
	currencies: string[];
	allow_external_pricing: boolean;
	owner_nationality: string[];
	signatory_nationality: string[];
	is_enabled: boolean;
}

export interface ApplicationCreate {
	company_name?: string;
	companyId?: string;
	numberOfEmployees?: number;
	taxIdNumber?: string;
	businessPurpose?: string;
	loanPurpose?: string;
	purposeDescription?: string;
	loanPurposeNumberOfMachines?: number;
	allBanksLoanPurpose?: string;
	loanPurposePriceOfMachines?: number;
	street?: string;
	houseNumber?: string;
	loanSize?: number;
	loanDuration?: number;

	/** @example EUR */
	currency?: string;
	companyType?: CompanyTypes;
	isRevenueMoreThan50k?: boolean;
	companyPostCode?: string;
	companyLocation?: string;
	companyCountry?: string;
	hrNumber?: string;
}

export interface UserCreate {
	email: string;
	password: string;
	accepted_terms: boolean;
	accepted_partner_terms?: boolean;
	language?: string;
	region?: string;
}

export interface UserRegister {
	password: string;
	token: string;
}

export type InvitedUserRegister = UserRegister & {
	accepted_terms: boolean;
	accepted_partner_terms?: boolean;
	language?: string;
	region?: string;
};

export interface UserLogin {
	email: string;
	password: string;
}

export interface ApplicationCreator {
	creator: { onBehalfUserId?: string };
}

export type ApplicationAndUserCreate = ApplicationCreate & UserCreate;

export type ApplicationForUserCreate = ApplicationCreate & ApplicationCreator;

export interface AdminApplicationListItem {
	application_id: string;
	short_id: string;
	company_id: string;
	company_name: string;

	/** @format email */
	email: string;
	status: ApplicationStatuses;
	loan_provider: LoanProviders;
	amount?: number;
	duration: number;
	status_owner_id?: string;
	status_owner_email?: string;
	status_owner_name?: string;
	account_manager_id?: string;
	account_manager_name?: string;
	account_manager_email?: string;
	product: ProductResponse;

	/** @format date-time */
	created_at?: string;
	finance_objects?: FinanceObjectGetResponse[];
	partner_name?: string;
}

export type AdminApplication = UserApplication & {
	allBanksLoanPurpose: AllBanksLoanPurposes;
	allDocsMatch?: boolean;
	applicationId: string;
	creditScore?: string;
	status: ApplicationStatuses;
	pdfContract?: string;
	isCompanyOlderThanTwoYears: boolean;
	isRevenueMoreThan50k: boolean;
	confirmAllSignatoriesAdded: boolean;
	submittedAtTimestamp?: number;
	yearOfFinancialFigures?: string;
	annualResult?: number;
	interestSpending?: number;
	writeOffs?: number;
	extraordinaryIncome?: number;
	plannedLoanSpending?: number;
	availableCapital?: number;
	predictedCashFlow?: number;
	yearlyLoanCost?: number;
	capitalCoverageRate?: number;
	companyBranche?: string;
	fatca: boolean;
	repaymentPlanId?: string;
	solarisLoanId?: string;
	identificationId?: string;
	identificationStatus?: string;
	numberOfEmployees?: number;
	bilanzSumme?: number;
	taxIdentificationId?: string;
	rejectedReason?: string;
	creditSummaries?: string;
	probabilityofdefault?: string;
	equity?: number;
	timestampSepaMandatAccepted?: number;
	brancheCode?: string;
	register?: string;
	ebita?: number;
	rejectionReason?: string;
	broker?: string;
	creditreformShareCapital?: number;
	creditreformNumberOfShareholders?: number;
	referrer?: string;
	crmDealId?: string;
	creditType?: string;
	loanProvider?: LoanProviders;
	termsAndConditionsAcceptedTimestamp?: number;
	houseBankName?: string;
	bankSpecificInfo?: string;
	loan_contract_url?: string;
	loan_contract_filename?: string;
	user_id?: string;
	user_email?: string;
	status_owner_id?: string;
	status_owner_email?: string;
	status_owner_name?: string;
	status_owner_assigned_at?: string;
	account_manager_id?: string;
	account_manager_name?: string;
	account_manager_email?: string;
	is_baas: boolean;
	possible_providers: LoanProviders[];
	product: ProductResponse;
	partner_id?: string;
	partner_name?: string;
};

export interface AdminCompany {
	companyName: string;
	companyPostCode: string;

	/** @example ["GMBH","AG"] */
	companyType: CompanyTypes[];
	street: string[];
	houseNumber: string[];
	hrNumber: string[];

	/** @example 1101081600000 */
	companyFoundationDate: string[];

	/** @example 2 */
	numberOfApplications: number;

	/** @format date-time */
	created?: string;
}

export interface TenantCreateRequest {
	key: string;
	full_name: string;
	display_name: string;
	is_enabled: boolean;
}

export interface Tenant {
	id?: string;
	key?: string;
	full_name?: string;
	display_name?: string;
	is_enabled?: boolean;
}

export interface Document {
	document_id?: string;
	document_name?: string;
	document_category?: DocumentCategory;
	uploader_entity_id?: string;
	uploader_entity_kind?: string;
	related_entity_id?: string;
	related_entity_kind?: RelatedEntityKind;
	s3_key?: string;
	is_client_visible?: boolean;
	created_at?: string;
}

export enum DocumentCategory {
	CreditReportCreditreform = "credit_report_creditreform",
	CreditReportSchufa = "credit_report_schufa",
	CompanyDocYearEndStmt = "company_doc_year_end_stmt",
	CompanyDocBwa = "company_doc_bwa",
	CompanyDocSusa = "company_doc_susa",
	CompanyDocBwaandsusa = "company_doc_bwaandsusa",
	CompanyDocBankStatement = "company_doc_bank_statement",
	CompanyDocDebtLedger = "company_doc_debt_ledger",
	GuarantorContract = "guarantor_contract",
	GuarantorContractSigned = "guarantor_contract_signed",
	LoanOffer = "loan_offer",
	LoanObjects = "loan_objects",
	LoanSupportDocument = "loan_support_document",
	LoanKycDocument = "loan_kyc_document",
	ObjectOrderForm = "object_order_form",
	Other = "other",
	None = "none",
}

export interface DocumentsResponse {
	pagination: Pagination;
	result: Document[];
}

export interface UploadDocumentPayload {
	file_name: string;
	document_category?: DocumentCategory;
	related_entity_kind?: RelatedEntityKind;

	/** @example sg_123 */
	related_entity_id?: string;
}

export interface UploadDocumentResponse {
	document_id: string;
	upload_url: string;
}

export interface DocumentUrlResponse {
	download_url: string;
}

export enum RelatedEntityKind {
	Signatory = "signatory",
}

export interface UpdateApplicationStatusRequestBody {
	status: ApplicationStatuses;

	/** reason for rejecting the application */
	rejectedReason?: string;

	/** additional data when changing to Awaiting_Payout status */
	status_awaiting_payout?: { fronting_bank_iban?: string };
}

export interface LoanContract {
	application_id: string;
	download_url: string;
	filename: string;
}

export interface StatusPendingActions {
	current_status: ApplicationStatuses;
	next_status: ApplicationStatuses;
	pending_actions: StatusPendingAction[];
}

export interface StatusPendingAction {
	action: PendingActionType;
	errors?: PendingActionDetails[];
}

export enum PendingActionType {
	StatusChangeNotAllowed = "status_change_not_allowed",
	ChecklistPayoutDone = "checklist_payout_done",
	ChecklistFourEyesDone = "checklist_four_eyes_done",
	BankNameIbanValid = "bank_name_iban_valid",
	SignatoriesSpecified = "signatories_specified",
	SignatoriesSharesLe100 = "signatories_shares_le_100",
	SignatoriesTaxidSpecified = "signatories_taxid_specified",
	ScoreMarkGeThreshold = "score_mark_ge_threshold",
	ScoreExists = "score_exists",
	ScoreWarningsCountLow = "score_warnings_count_low",
	ScoreRedFlagsCountLow = "score_red_flags_count_low",
	LoanContractExist = "loan_contract_exist",
	SignatoriesRequirementsSpecified = "signatories_requirements_specified",
	SignatoriesSignLoanContract = "signatories_sign_loan_contract",
	SignatoriesIdentUrlSpecified = "signatories_ident_url_specified",
	SignatoriesUboContractUrlSpecified = "signatories_ubo_contract_url_specified",
	SignatoriesSoleOrAtleast2 = "signatories_sole_or_atleast_2",
	ApplicationPricingSpecified = "application_pricing_specified",
	CompanyTinSpecified = "company_tin_specified",
	SignatoriesIdentCompleted = "signatories_ident_completed",
	ProdUserNotTeylorEmployee = "prod_user_not_teylor_employee",
	ChecklistApplicationPositive = "checklist_application_positive",
	FinancialsComplete = "financials_complete",
	AllowedCompanyType = "allowed_company_type",
	AllowedCreditScore = "allowed_credit_score",
	AllowedCompanyCountry = "allowed_company_country",
	ScoringFeedbackDone = "scoring_feedback_done",
	ApplicationExternalPricingSpecified = "application_external_pricing_specified",
	DocumentBwaSusaUploaded = "document_bwa_susa_uploaded",
	DocumentAnnualStatementsUploaded = "document_annual_statements_uploaded",
	DocumentCurrentDebtRegisterUploaded = "document_current_debt_register_uploaded",
	GuarantorContractUploaded = "guarantor_contract_uploaded",
	SepaMandateAccepted = "sepa_mandate_accepted",
	LegalSignatureAccepted = "legal_signature_accepted",
	RepaymentPlanGenerated = "repayment_plan_generated",
	FinanceObjectsAdded = "finance_objects_added",
}

export interface PendingActionDetails {
	error: PendingActionError;
	signatories?: string[];
}

export enum PendingActionError {
	ErrApplicationPayoutChecklistNotDone = "err_application_payout_checklist_not_done",
	ErrApplicationFourEyesChecklistNotDone = "err_application_four_eyes_checklist_not_done",
	ErrApplicationNoBankName = "err_application_no_bank_name",
	ErrApplicationNoIban = "err_application_no_iban",
	ErrApplicationNoSignatories = "err_application_no_signatories",
	ErrApplicationNoSignatoriesSignLoanContract = "err_application_no_signatories_sign_loan_contract",
	ErrApplicationNoTaxidForSignatory = "err_application_no_taxid_for_signatory",
	ErrApplicationNoLoanContract = "err_application_no_loan_contract",
	ErrApplicationInternalError = "err_application_internal_error",
	ErrApplicationNoSignatoryContract = "err_application_no_signatory_contract",
	ErrApplicationNoSignatoryIdentUrl = "err_application_no_signatory_ident_url",
	ErrApplicationNoPricing = "err_application_no_pricing",
	ErrSharePercentageGreaterThen100 = "err_share_percentage_greater_then_100",
	ErrApplicationSignatoryIdentNotDone = "err_application_signatory_ident_not_done",
	ErrApplicationMissingCompany = "err_application_missing_company",
	ErrApplicationCompanyTaxidNotSpecified = "err_application_company_taxid_not_specified",
	ErrApplicationUserIsTeylorEmployee = "err_application_user_is_teylor_employee",
	ErrMissingScoringData = "err_missing_scoring_data",
	ErrMissingScoringConfig = "err_missing_scoring_config",
	ErrLowScoringMark = "err_low_scoring_mark",
	ErrTooManyRedFlags = "err_too_many_red_flags",
	ErrTooManyWarnings = "err_too_many_warnings",
	ErrCrefoCompanySearchFailed = "err_crefo_company_search_failed",
	ErrChecklistBadScore = "err_checklist_bad_score",
	ErrChecklistNotExist = "err_checklist_not_exist",
	ErrChecklistNotDone = "err_checklist_not_done",
	ErrFinancialsNotExist = "err_financials_not_exist",
	ErrCompanyCountryNotAllowed = "err_company_country_not_allowed",
	ErrCompanyTypeNotAllowed = "err_company_type_not_allowed",
	ErrCompanyCreditScoreNotAllowed = "err_company_credit_score_not_allowed",
	ErrCompanyCreditScoreTooHigh = "err_company_credit_score_too_high",
	ErrCompanyCreditScoreTooLow = "err_company_credit_score_too_low",
	ErrApplicationRoleInvalid = "err_application_role_invalid",
	ErrStatusChangeNotAllowed = "err_status_change_not_allowed",
	ErrScoringFeedbackNotDone = "err_scoring_feedback_not_done",
	ErrApplicationNoExternalPricing = "err_application_no_external_pricing",
	ErrDocumentAnnualStatmentMissing = "err_document_annual_statment_missing",
	ErrDocumentBwaSusaMissing = "err_document_bwa_susa_missing",
	ErrDocumentDebtRegistryMissing = "err_document_debt_registry_missing",
	ErrDocumentGuarantorConractMissing = "err_document_guarantor_conract_missing",
	ErrSepaMandateNotAccepted = "err_sepa_mandate_not_accepted",
	ErrRepaymentPlanNotGenerated = "err_repayment_plan_not_generated",
}

export interface ProductScordingConfigRequest {
	benchmarks: object;
	score_card: object;
	default_interest_rate_for_calculations: number;
	revenue_thresholds: object;
	years_needed_for_scoring: object;
	max_red_flags_count?: number;
	max_warnings_count?: number;
	version: object;
}

export type ProductScoringConfigResponse = ProductScordingConfigRequest & {
	scoring_config_id: string;
	is_deleted: boolean;
	created_at: string;
};

export enum CompanyTypes {
	GMBH = "GMBH",
	GMBH_CO_KG = "GMBH_CO_KG",
	FREIE_BERUFE = "FREIE_BERUFE",
	SELF_EMPLOYED = "SELF_EMPLOYED",
	KGAA = "KGAA",
	AG = "AG",
	GBR = "GBR",
	OHG = "OHG",
	PARTG = "PARTG",
	KG = "KG",
	UG = "UG",
	SOLE_PROPRIETORSHIP = "SOLE_PROPRIETORSHIP",
	EG = "EG",
	EK = "EK",
}

export interface ProductInterestRateConfigRequest {
	purpose: "scoring" | "representative";
	interest_table: object;
}

export type ProductInterestRateConfigResponse = ProductInterestRateConfigRequest & {
	interest_rate_id: string;
	product_id: string;
	is_deleted: boolean;
	created_at: string;
};

export interface CompanySearchResponse {
	companyNameSuggestions?: CompanyNameSuggestion[];
}

export interface CompanyNameSuggestion {
	crefonummer?: string;
	identnummer?: string;
	name: string;
	handelsName?: string;
	land: string;
	bundesland?: string;
	plz: string;
	ort: string;
	strasseHausnummer: string;
}

export enum AllBanksLoanPurposes {
	GeneralLiquidity = "general_liquidity",
	ObjectFinancing = "object_financing",
	RealEstate = "real_estate",
	Factoring = "factoring",
	Other = "other",
}

export interface Entitlement {
	entitlement_id: number;
	entitlement: Entitlements;
}

export interface EntitlementRequest {
	entitlement: string;
}

export interface PartnerEntitlement {
	partner_entitlement_id: number;
	partner_id: string;
	entitlement_id: number;
	entitlement: Entitlements;
}

export interface PartnerEntitlementRequest {
	entitlement_id: number;
}

export enum Entitlements {
	CustomizedPricing = "customized_pricing",
	WhitelabeledUserBranding = "whitelabeled_user_branding",
	CustomizedApplicationAmounts = "customized_application_amounts",
}

export interface FinanceObjectRequest {
	/** @example John Deere */
	manufacturer: string;

	/** @example Tractor */
	type: string;

	/** @example 2022-08-26T18:36:30.250Z */
	built_at?: string;

	/** @example John Deere specialty tractors come with all the features, power and performance you want in a utility tractor in a size that’s just right for the specialty crops you grow. */
	detail_desc?: string;

	/** @example 90000 */
	net_purchase_price?: number;

	/** @example 9000 */
	down_payment?: number;

	/** @example 81000 */
	remaining_value?: number;

	/** @example 2 */
	object_count: number;

	/** @example true */
	is_fixed_interest: boolean;
	financing_type: ObjectFinancingType;
	rental_state: ObjectRentalState;

	/** @example Michael Gaar Landtechnik */
	merchant_name: string;

	/** @example Friedrich-Bergius-Str. 2 */
	merchant_street: string;

	/** @example 2 */
	merchant_house_num: string;

	/** @example Höhenkirchen-Siegb. */
	merchant_city: string;

	/** @example 85635 */
	merchant_postcode: string;

	/** @example DE */
	merchant_country: string;

	/** @example 0.025 */
	commission?: number;
}

export interface FinanceObjectCreateResponse {
	/** @example fo_2DsqxJDdHbYelXMIZv720VGZwB2 */
	finance_object_id: string;
}

export type FinanceObjectGetResponse = { finance_object_id?: string } & FinanceObjectRequest & {
		created_at?: string;
		updated_at?: string;
	};

export enum ObjectFinancingType {
	Leasing = "leasing",
	HirePurchase = "hire_purchase",
	Financing = "financing",
	SaleLeaseback = "sale_leaseback",
	CreditLine = "credit_line",
	Other = "other",
}

export enum ObjectRentalState {
	No = "no",
	Internal = "internal",
	B2C = "b2c",
}

export interface OrderFormRequest {
	requested_offer_details?: string;
}

export interface SignatoryRequirementStatus {
	is_completed?: boolean;
	is_required?: boolean;
}

export interface SignatoryKycStatus {
	sepa_mandate_accepted?: SignatoryRequirementStatus;
	legal_signature_accepted?: SignatoryRequirementStatus;
	guarantor_contract_exit?: SignatoryRequirementStatus;
	guarantor_contract_uploaded?: SignatoryRequirementStatus;
	ident_url_specified?: SignatoryRequirementStatus;
	ident_completed?: SignatoryRequirementStatus;
	tax_id_specified?: SignatoryRequirementStatus;
}

export enum FinancialStatementType {
	AnnualStatement = "annual_statement",
	InterimFinancials = "interim_financials",
}

export interface FinancialDataRequest {
	year?: number;
	month?: number;
	statement_type: FinancialStatementType;
	input_data: FinancialInputData;
}

export interface FinancialData {
	financial_data_id: number;
	application_id?: string;
	year?: number;
	month?: number;
	statement_type: FinancialStatementType;
	input_data: FinancialInputData;
	summary_data?: FinancialSummaryData;
	interim?: boolean;
}

export interface FinancialInputData {
	revenue?: string;
	inventory_change?: string;
	activated_costs?: string;
	other_income?: string;
	extraordinary_income?: string;
	extraordinary_expense?: string;
	costs_of_materials?: string;
	external_services?: string;
	personnel_costs?: string;
	depreciations?: string;
	other_costs?: string;
	interest_income?: string;
	interest_expense?: string;
	other_finance_income?: string;
	other_finance_costs?: string;
	taxes?: string;
	other_taxes?: string;
	long_term_intangible_assets?: string;
	self_made_intangible_assets?: string;
	long_term_properties?: string;
	long_term_securities?: string;
	investments_in_related_companies?: string;
	goodwill?: string;
	inventories?: string;
	accounts_receivable?: string;
	other_current_assets?: string;
	loan_to_related_companies?: string;
	loan_to_shareholders?: string;
	cash?: string;
	accruals_aktiva?: string;
	deferred_tax_assets?: string;
	losses_not_covered_by_equity?: string;
	subscribed_capital?: string;
	capital_reserve?: string;
	retained_income?: string;
	profit_carried_forward?: string;
	annual_profit?: string;
	other_equity_position?: string;
	accrued_liabilities_short_term?: string;
	accrued_liabilities_long_term?: string;
	bonds?: string;
	bank_debts_short_term?: string;
	bank_debts_long_term?: string;
	deposits_received?: string;
	accounts_payable?: string;
	loan_from_related_companies?: string;
	other_liabilities_short_term?: string;
	other_liabilities_long_term?: string;
	loan_from_shareholders?: string;
	accruals_passiva?: string;
	deferred_tax_liabilities?: string;
	total_assets?: string;
	other_debts_short_term?: string;
	other_debts_long_term?: string;
	total_debt_service?: string;
}

export interface FinancialSummaryData {
	revenue: string;
	activated_costs: string;
	inventory_increase: string;
	other_income_regular: string;
	cogs: string;
	other_cor: string;
	cost_of_revenue: string;
	gross_profit: string;
	sga: string;
	depreciations: string;
	other_opex_costs: string;
	opex: string;
	operating_profit: string;
	ebitda: string;
	other_income_net: string;
	other_finance_income: string;
	other_finance_costs: string;
	interest_income: string;
	interest_expense: string;
	ebt: string;
	taxes_paid: string;
	nopat: string;
	tax_rate: string;
	net_income: string;
	fixed_costs: string;
	cash: string;
	short_term_investments: string;
	accounts_receivable: string;
	inventories: string;
	inventory_net: string;
	prepaid_expenses: string;
	other_current_assets: string;
	loan_to_shareholders: string;
	loan_to_related_companies: string;
	property_equipment_net: string;
	intangible_assets_net: string;
	self_made_intangible: string;
	goodwill: string;
	long_term_investments: string;
	investments_in_related_companies: string;
	other_non_current_assets: string;
	deferred_tax_assets: string;
	current_assets_published: string;
	non_current_assets_published: string;
	total_assets_published: string;
	equity_published: string;
	current_assets: string;
	non_current_assets: string;
	total_assets: string;
	current_portion_of_long_debt: string;
	accounts_payable: string;
	accrued_expenses: string;
	income_taxes_payable: string;
	other_current_liabilities: string;
	current_liabilities_published: string;
	current_liabilities: string;
	long_term_debt: string;
	underfunded_retirement_plans: string;
	deferred_tax_liabilities: string;
	other_long_term_liabilities: string;
	non_current_liabilities_published: string;
	total_liabilities_published: string;
	loan_from_shareholders: string;
	non_current_liabilities: string;
	total_liabilities: string;
	equity: string;
	preferred_stock: string;
	shares_issued: string;
	paid_in_capital: string;
	retained_earnings: string;
	accumulated_other_comprehensive_income: string;
	price_per_share: string;
	total_debt: string;
	net_debt: string;
	capital_employed: string;
	total_invested_capital: string;
	net_fixed_assets: string;
	working_capital: string;
	net_working_capital: string;
	risk_free_rate_of_return: string;
	market_rate_of_return: string;
	classification_by_revenue:
		| "mega_cap"
		| "large_cap"
		| "medium_cap"
		| "small_cap"
		| "mini_cap"
		| "micro_cap"
		| "extra_micro_cap";
	beta: string;
	balance_sheet_sum_check: string;
	debt_service: string;
	company_age: number;
	financial_result: string;
}

export interface KPIs {
	kpis_id: string;
	application_id: string;
	category:
		| "risk_standard"
		| "risk_standard_multi"
		| "future_performance"
		| "cash_flow"
		| "additional_loan"
		| "repayment_information";
	start_year: number;
	end_year: number;
	kpis:
		| StandardRiskKPIs
		| StandardRiskMultiKPIs
		| FuturePerformanceKPIs
		| CashFlowKPIs
		| AdditionalLoanKPIs
		| RepaymentInformationKPIs;
}

export interface StandardRiskKPIs {
	margin_kpis: MarginKPIs;
	profitability_kpis: ProfitabilityKPIs;
	liquidity_kpis: LiquidityKPIs;
	solvency_kpis: SolvencyKPIs;
}

export interface MarginKPIs {
	activated_costs_margin: string;
	cost_of_revenue_margin: string;
	gross_profit_margin: string;
	opex_margin: string;
	depreciations_margin: string;
	operating_profit_margin: string;
	ebitda_margin: string;
	ebt_margin: string;
	nopat_margin: string;
	other_income_net_margin: string;
	interest_expense_margin: string;
	taxes_margin: string;
	net_income_margin: string;
	fixed_costs_margin: string;
	current_assets_margin: string;
	cash_margin: string;
	short_term_investments_margin: string;
	accounts_receivable_margin: string;
	inventory_net_margin: string;
	prepaid_expenses_margin: string;
	other_current_assets_margin: string;
	loan_to_shareholders_margin: string;
	loan_to_related_companies_margin: string;
	property_equipment_net_margin: string;
	intangible_assets_net_margin: string;
	self_made_intangible_margin: string;
	goodwill_margin: string;
	long_term_investments_margin: string;
	investments_in_related_companies_margin: string;
	other_non_current_assets_margin: string;
	deferred_tax_assets_margin: string;
	non_current_assets_margin: string;
	current_liabilities_margin: string;
	current_portion_of_long_debt_margin: string;
	accounts_payable_margin: string;
	accrued_expenses_margin: string;
	income_taxes_payable_margin: string;
	other_current_liabilities_margin: string;
	long_term_debt_margin: string;
	underfunded_retirement_plans_margin: string;
	deferred_tax_liabilities_margin: string;
	other_long_term_liabilities_margin: string;
	loan_from_shareholders_margin: string;
	non_current_liabilities_margin: string;
	total_liabilities_margin: string;
	equity_margin: string;
	preferred_stock_margin: string;
	shares_issued_margin: string;
	paid_in_capital_margin: string;
	retained_earnings_margin: string;
	accumulated_other_comprehensive_income_margin: string;
	total_debt_margin: string;
	net_debt_margin: string;
	capital_employed_margin: string;
	total_invested_capital_margin: string;
	net_fixed_assets_margin: string;
	working_capital_margin: string;
	net_working_capital_margin: string;
	sga_margin: string;
	other_opex_costs_margin: string;
	other_income_regular_margin: string;
}

export interface ProfitabilityKPIs {
	return_on_invested_capital: string;
	return_on_equity: string;
	return_on_assets: string;
	return_on_sales: string;
	return_on_capital: string;
	return_on_capital_employed: string;
	cost_of_equity: string;
	cost_of_debt_and_preferred_stock: string;
	after_tax_cost_of_debt: string;
	weighted_average_cost_of_capital: string;
	value_spread: string;
}

export interface LiquidityKPIs {
	current_ratio: string;
	working_capital_to_revenue: string;
	quick_ratio: string;
	cash_ratio: string;
	days_sales_outstanding: string;
	days_payables_outstanding: string;
	days_inventory_outstanding: string;
	cash_conversion_cycle: string;
}

export interface SolvencyKPIs {
	equity_ratio: string;
	debt_to_equity_ratio: string;
	debt_to_assets_ratio: string;
	debt_to_capital_ratio: string;
	asset_coverage_ratio: string;
	solvency_ratio: string;
	retention_ratio: string;
	group_financing_to_total_assets: string;
}

export interface StandardRiskMultiKPIs {
	growth_kpis: GrowthKPIs;
	efficiency_kpis: EfficiencyKPIs;
	leverage_kpis: LeverageKPIs;
}

export interface GrowthKPIs {
	revenue_growth: string;
	gross_profit_growth: string;
	ebitda_growth: string;
	operating_profit_growth: string;
	opex_growth: string;
	interest_expense_growth: string;
	net_income_growth: string;
	current_assets_growth: string;
	cash_growth: string;
	short_term_investments_growth: string;
	accounts_receivable_growth: string;
	inventories_growth: string;
	prepaid_expenses_growth: string;
	other_current_assets_growth: string;
	loan_to_shareholders_growth: string;
	loan_to_related_companies_growth: string;
	property_equipment_net_growth: string;
	intangible_assets_net_growth: string;
	self_made_intangible_growth: string;
	goodwill_growth: string;
	long_term_investments_growth: string;
	investments_in_related_companies_growth: string;
	other_non_current_assets_growth: string;
	deferred_tax_assets_growth: string;
	non_current_assets_growth: string;
	total_assets_growth: string;
	current_liabilities_growth: string;
	current_portion_of_long_debt_growth: string;
	accounts_payable_growth: string;
	accrued_expenses_growth: string;
	income_taxes_payable_growth: string;
	other_current_liabilities_growth: string;
	long_term_debt_growth: string;
	underfunded_retirement_plans_growth: string;
	deferred_tax_liabilities_growth: string;
	other_long_term_liabilities_growth: string;
	loan_from_shl_growth: string;
	non_current_liabilities_growth: string;
	total_liabilities_growth: string;
	equity_growth: string;
	total_debt_growth: string;
	net_debt_growth: string;
	capital_employed_growth: string;
	total_invested_capital_growth: string;
	net_fixed_assets_growth: string;
	working_capital_growth: string;
	net_working_capital_growth: string;
}

export interface EfficiencyKPIs {
	asset_turnover_ratio: string;
	asset_turnover: string;
	fixed_asset_turnover_ratio: string;
	inventory_turnover_ratio: string;
	accounts_receivable_turnover_ratio: string;
	accounts_payable_turnover_ratio: string;
	days_working_capital: string;
	return_on_operating_assets: string;
	working_capital_growth_to_revenue_growth: string;
	opex_growth_ratio: string;
	cash_conversion_ratio: string;
}

export interface LeverageKPIs {
	debt_to_ebitda_ratio: string;
	liabilities_to_ebitda_ratio: string;
	debt_growth_to_ebitda_growth: string;
	leverage_index: string;
	times_interest_earned: string;
	interest_coverage_ratio: string;
	debt_service_coverage_ratio: string;
	net_debt_service_coverage_ratio: string;
	relative_debt: string;
	fixed_charges_before_taxes: string;
	fixed_charge_coverage_ratio: string;
	operating_leverage: string;
	net_debt_to_ebitda_ratio: string;
	free_cash_flow_post_finance_to_net_debt: string;
	interest_coverage_ratio_adjusted: string;
	free_cash_flow_to_net_debt: string;
}

export interface CashFlowKPIs {
	cfo: string;
	capex: string;
	free_cash_flow: string;
	free_cash_flow_post_finance: string;
	earnings_quality: string;
	operating_cash_flow_ratio: string;
}

export interface FuturePerformanceKPIs {
	roe: string;
	equity_start: string;
	equity_end: string;
	retained_earnings: string;
	ebitda: string;
	ebitda_growth: string;
	revenue: string;
	revenue_growth: string;
	debt_service_coverage_ratio: string;
	total_debt: string;
	net_debt_issued: string;
}

export interface RepaymentInformationKPIs {
	annuity: string;
	total_interest_paid: string;
	total_interest_paid_after_12_months: string;
	total_repayment_after_12_months: string;
	repayment_plan: RepaymentPlanForMonth[];
}

export interface RepaymentPlanForMonth {
	interest_paid: string;
	principal_paid: string;
	starting_balance: string;
	ending_balance: string;
	total_interest_paid: string;
}

export interface AdditionalLoanKPIs {
	return_on_invested_capital: string;
	net_income: string;
	return_on_equity: string;
	total_assets: string;
	total_debt: string;
	total_liabilities: string;
	return_on_assets: string;
	interest_expense: string;
	cost_of_debt_and_preferred_stock: string;
	after_tax_cost_of_debt: string;
	weighted_average_cost_of_capital: string;
	value_spread: string;
	current_liabilities: string;
	working_capital: string;
	working_capital_growth: string;
	working_capital_growth_to_revenue_growth: string;
	current_ratio: string;
	quick_ratio: string;
	equity_ratio: string;
	debt_to_equity_ratio: string;
	solvency_ratio: string;
	debt_to_ebitda_ratio: string;
	liabilities_to_ebitda_ratio: string;
	total_debt_growth: string;
	debt_growth_to_ebitda_growth: string;
	leverage_index: string;
	times_interest_earned: string;
	interest_coverage_ratio: string;
	debt_service: string;
	debt_service_coverage_ratio: string;
	net_debt_service_coverage_ratio: string;
	relative_debt: string;
	fixed_charges_before_taxes: string;
	fixed_charge_coverage_ratio: string;
	requested_loan_amount_to_total_assets: string;
	requested_loan_amount_to_revenue: string;
	net_debt_growth: string;
}

export interface ScoringResult {
	scoring_id: string;
	application_id: string;
	product_id?: string;
	scoring_config_id?: string;
	interest_rate_id?: number;
	benchmark_result: ScoringResultForPeriod[];
	final_score: number;
	final_mark: string;
	minimum_interest_rate?: string;
	is_rejected: boolean;
	warnings: Warning[];
	pd_score: number;
	is_deleted?: boolean;

	/** @format datetime */
	created_at?: string;
}

export interface ScoringResultForPeriod {
	period_start: number;
	period_end: number;
	benchmarking_scores: any;
	total_score: number;
	mark: string;
	warnings: Warning[];
}

export interface Warning {
	type: "red_flag" | "warning";
	message: string;
	code: string;
}

export interface BenchmarkingScores {
	solvency: Score;
	profitability: Score;
	liquidity: Score;
	leverage: Score;
	future_performance?: Score;
	efficiency: Score;
	additional_loan: Score;
}

export interface Score {
	value: number;
	mark: string;
}

export namespace Admin {
	/**
	 * No description
	 * @tags account
	 * @name AccountLoginCreate
	 * @summary Log in as an admin
	 * @request POST:/admin/account/login
	 * @response `200` `BOUserProfile` successful login
	 */
	export namespace AccountLoginCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = { email: string; password: string };
		export type RequestHeaders = {};
		export type ResponseBody = BOUserProfile;
	}
	/**
	 * No description
	 * @tags account
	 * @name ProfileList
	 * @summary Get the admin's profile
	 * @request GET:/admin/profile
	 * @response `200` `BOUserProfile` successful login
	 */
	export namespace ProfileList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = BOUserProfile;
	}
	/**
	 * No description
	 * @tags document_template
	 * @name DocumentTemplatesCreate
	 * @request POST:/admin/document_templates
	 * @response `200` `void` ok
	 */
	export namespace DocumentTemplatesCreate {
		export type RequestParams = {};
		export type RequestQuery = {
			type: "loan_contract payment_confirmation guarantor_contract";
			product_id: string;
			language: string;
			country: string;
		};
		export type RequestBody = File;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags document_template
	 * @name DocumentTemplatesLastList
	 * @request GET:/admin/document_templates/last
	 * @response `200` `DocumentTemplate` document info
	 */
	export namespace DocumentTemplatesLastList {
		export type RequestParams = {};
		export type RequestQuery = {
			type: "loan_contract payment_confirmation guarantor_contract";
			product_id: string;
			language: string;
			country: string;
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = DocumentTemplate;
	}
	/**
	 * No description
	 * @tags document_template
	 * @name DocumentTemplatesDetail
	 * @request GET:/admin/document_templates/{document_template_id}
	 * @response `200` `DocumentTemplate` document info
	 */
	export namespace DocumentTemplatesDetail {
		export type RequestParams = { documentTemplateId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = DocumentTemplate;
	}
	/**
	 * No description
	 * @tags document_template
	 * @name DocumentTemplatesDelete
	 * @request DELETE:/admin/document_templates/{document_template_id}
	 * @response `200` `void` document info
	 */
	export namespace DocumentTemplatesDelete {
		export type RequestParams = { documentTemplateId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags document_template
	 * @name DocumentTemplatesBodyDetail
	 * @request GET:/admin/document_templates/{document_template_id}/body
	 * @response `200` `File` document info
	 */
	export namespace DocumentTemplatesBodyDetail {
		export type RequestParams = { documentTemplateId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = File;
	}
	/**
	 * No description
	 * @tags document_template
	 * @name DocumentTemplatesLastBodyList
	 * @request GET:/admin/document_templates/last/body
	 * @response `200` `File` document info
	 */
	export namespace DocumentTemplatesLastBodyList {
		export type RequestParams = {};
		export type RequestQuery = {
			type: "loan_contract payment_confirmation guarantor_contract";
			product_id: string;
			language: string;
			country: string;
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = File;
	}
	/**
	 * @description start ocr file scan
	 * @tags ocr
	 * @name StartOcrFileScan
	 * @request POST:/admin/ocr
	 * @response `200` `OcrFileResponse` created ocr file object
	 */
	export namespace StartOcrFileScan {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = OcrFileCreate;
		export type RequestHeaders = {};
		export type ResponseBody = OcrFileResponse;
	}
	/**
	 * No description
	 * @tags ocr
	 * @name UpdateOcrFileInfo
	 * @summary update ocr file info
	 * @request PATCH:/admin/ocr/{document_id}
	 * @response `200` `OcrFileResponse` created ocr file object
	 */
	export namespace UpdateOcrFileInfo {
		export type RequestParams = { documentId: string };
		export type RequestQuery = {};
		export type RequestBody = OcrFileUpdateInfo;
		export type RequestHeaders = {};
		export type ResponseBody = OcrFileResponse;
	}
	/**
	 * No description
	 * @tags ocr
	 * @name GetOcrFileInfo
	 * @summary get ocr file info
	 * @request GET:/admin/ocr/{document_id}
	 * @response `200` `OcrFileResponse` created ocr file object
	 */
	export namespace GetOcrFileInfo {
		export type RequestParams = { documentId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = OcrFileResponse;
	}
	/**
	 * No description
	 * @tags application
	 * @name ApplicationsList
	 * @summary List applications from admin panel
	 * @request GET:/admin/applications/
	 * @response `200` `{ applications?: (AdminApplicationListItem)[], pagination?: LegacyPagination }` application
	 */
	export namespace ApplicationsList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = {
			applications?: AdminApplicationListItem[];
			pagination?: LegacyPagination;
		};
	}
	/**
	 * No description
	 * @tags application
	 * @name ApplicationsCreate
	 * @summary create new application on behalf of user
	 * @request POST:/admin/applications/
	 * @response `200` `Application` application
	 */
	export namespace ApplicationsCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = ApplicationForUserCreate;
		export type RequestHeaders = {};
		export type ResponseBody = Application;
	}
	/**
	 * No description
	 * @tags files
	 * @name ApplicationsList2
	 * @summary Get all applications list
	 * @request GET:/admin/applications
	 * @originalName applicationsList
	 * @duplicate
	 * @response `200` `{ applications: ({ amount: number, currency?: string, application_id: string, company_id: string, company_name: string, created_at: string, duration: number, email: string, loan_provider: LoanProviders, short_id: string, status: ApplicationStatuses, status_owner_id?: string, status_owner_email?: string, status_owner_name?: string, account_manager_id?: string, account_manager_name?: string, account_manager_email?: string, product: ProductResponse })[], pagination?: LegacyPagination }` Applications list
	 */
	export namespace ApplicationsList2 {
		export type RequestParams = {};
		export type RequestQuery = {
			text?: string;
			status?: string;
			provider?: string;
			allBanksLoanPurpose?: string;
			status_owner_id?: string;
			account_manager_id?: string;
			page?: number;
			page_size?: number;
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = {
			applications: {
				amount: number;
				currency?: string;
				application_id: string;
				company_id: string;
				company_name: string;
				created_at: string;
				duration: number;
				email: string;
				loan_provider: LoanProviders;
				short_id: string;
				status: ApplicationStatuses;
				status_owner_id?: string;
				status_owner_email?: string;
				status_owner_name?: string;
				account_manager_id?: string;
				account_manager_name?: string;
				account_manager_email?: string;
				product: ProductResponse;
			}[];
			pagination?: LegacyPagination;
		};
	}
	/**
	 * No description
	 * @tags application
	 * @name ApplicationsDetail
	 * @summary Get application item from admin panel
	 * @request GET:/admin/applications/{application_id}
	 * @response `200` `AdminApplication` application
	 */
	export namespace ApplicationsDetail {
		export type RequestParams = { applicationId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = AdminApplication;
	}
	/**
	 * No description
	 * @tags application
	 * @name ApplicationsPartialUpdate
	 * @summary update application
	 * @request PATCH:/admin/applications/{application_id}
	 * @response `200` `Application` updated application
	 */
	export namespace ApplicationsPartialUpdate {
		export type RequestParams = { applicationId: string };
		export type RequestQuery = {};
		export type RequestBody = { language?: string; purposeDescription?: string };
		export type RequestHeaders = {};
		export type ResponseBody = Application;
	}
	/**
	 * No description
	 * @tags application
	 * @name ApplicationsParametersPartialUpdate
	 * @summary update applications parameters
	 * @request PATCH:/admin/applications/{application_id}/parameters
	 * @response `200` `ApplicationParametersResponse` updated application
	 */
	export namespace ApplicationsParametersPartialUpdate {
		export type RequestParams = { applicationId: string };
		export type RequestQuery = {};
		export type RequestBody = { loan_amount?: number; loan_duration?: number };
		export type RequestHeaders = {};
		export type ResponseBody = ApplicationParametersResponse;
	}
	/**
	 * No description
	 * @tags finance object
	 * @name ApplicationsFinanceObjectsCreate
	 * @summary Create finance object
	 * @request POST:/admin/applications/{application_id}/finance_objects
	 * @response `201` `FinanceObjectCreateResponse` created finance object
	 */
	export namespace ApplicationsFinanceObjectsCreate {
		export type RequestParams = { applicationId: string };
		export type RequestQuery = {};
		export type RequestBody = FinanceObjectRequest;
		export type RequestHeaders = {};
		export type ResponseBody = FinanceObjectCreateResponse;
	}
	/**
	 * No description
	 * @tags finance object
	 * @name ApplicationsFinanceObjectsDetail
	 * @summary Get application finance objects
	 * @request GET:/admin/applications/{application_id}/finance_objects
	 * @response `200` `(FinanceObjectGetResponse)[]` list of finance objects
	 */
	export namespace ApplicationsFinanceObjectsDetail {
		export type RequestParams = { applicationId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = FinanceObjectGetResponse[];
	}
	/**
	 * No description
	 * @tags finance object
	 * @name ApplicationsFinanceObjectsUpdate
	 * @summary Update finance object
	 * @request PUT:/admin/applications/{application_id}/finance_objects/{finance_object_id}
	 * @response `200` `void` updated finance object
	 */
	export namespace ApplicationsFinanceObjectsUpdate {
		export type RequestParams = { applicationId: string; financeObjectId: string };
		export type RequestQuery = {};
		export type RequestBody = FinanceObjectRequest;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags finance object
	 * @name ApplicationsFinanceObjectsDelete
	 * @summary Delete finance object
	 * @request DELETE:/admin/applications/{application_id}/finance_objects/{finance_object_id}
	 * @response `200` `void` deleted finance object
	 */
	export namespace ApplicationsFinanceObjectsDelete {
		export type RequestParams = { applicationId: string; financeObjectId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags finance object
	 * @name ApplicationsFinanceObjectsGenerateOrderFormCreate
	 * @summary Generate finance object order form
	 * @request POST:/admin/applications/{application_id}/finance_objects/{finance_object_id}/generate_order_form
	 * @response `200` `{ url: string }` generated finance object order form
	 */
	export namespace ApplicationsFinanceObjectsGenerateOrderFormCreate {
		export type RequestParams = { applicationId: string; financeObjectId: string };
		export type RequestQuery = {};
		export type RequestBody = OrderFormRequest;
		export type RequestHeaders = {};
		export type ResponseBody = { url: string };
	}
	/**
	 * No description
	 * @tags product
	 * @name ProductsCreate
	 * @summary create product
	 * @request POST:/admin/products
	 * @response `200` `Product` created product
	 */
	export namespace ProductsCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = ProductRequest;
		export type RequestHeaders = {};
		export type ResponseBody = Product;
	}
	/**
	 * No description
	 * @tags product
	 * @name ProductsDetail
	 * @summary get all product related rules
	 * @request GET:/admin/products/{product_id}
	 * @response `200` `object` OK
	 */
	export namespace ProductsDetail {
		export type RequestParams = { productId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = object;
	}
	/**
	 * No description
	 * @tags product
	 * @name ProductsPartialUpdate
	 * @summary update product
	 * @request PATCH:/admin/products/{product_id}
	 * @response `200` `Product` updated product
	 */
	export namespace ProductsPartialUpdate {
		export type RequestParams = { productId: string };
		export type RequestQuery = {};
		export type RequestBody = ProductRequest;
		export type RequestHeaders = {};
		export type ResponseBody = Product;
	}
	/**
	 * No description
	 * @tags product
	 * @name ProductsConfigCreate
	 * @summary create product config
	 * @request POST:/admin/products/{product_id}/config
	 * @response `200` `ProductConfigResponse` created product config
	 */
	export namespace ProductsConfigCreate {
		export type RequestParams = { productId: string };
		export type RequestQuery = {};
		export type RequestBody = ProductConfigRequest;
		export type RequestHeaders = {};
		export type ResponseBody = ProductConfigResponse;
	}
	/**
	 * No description
	 * @tags product
	 * @name ProductsConfigPartialUpdate
	 * @summary update product config
	 * @request PATCH:/admin/products/{product_id}/config/{config_id}
	 * @response `200` `ProductConfigResponse` updated product config
	 */
	export namespace ProductsConfigPartialUpdate {
		export type RequestParams = { productId: string; configId: string };
		export type RequestQuery = {};
		export type RequestBody = ProductConfigRequest;
		export type RequestHeaders = {};
		export type ResponseBody = ProductConfigResponse;
	}
	/**
	 * No description
	 * @tags product
	 * @name ProductsScoringConfigCreate
	 * @summary create product scoring config, replace previous scoring config if existed
	 * @request POST:/admin/products/{product_id}/scoring_config
	 * @response `200` `ProductScoringConfigResponse` created product scoring config
	 */
	export namespace ProductsScoringConfigCreate {
		export type RequestParams = { productId: string };
		export type RequestQuery = {};
		export type RequestBody = ProductScordingConfigRequest;
		export type RequestHeaders = {};
		export type ResponseBody = ProductScoringConfigResponse;
	}
	/**
	 * No description
	 * @tags product
	 * @name ProductsScoringConfigPartialUpdate
	 * @summary update product scoring config
	 * @request PATCH:/admin/products/{product_id}/scoring_config
	 * @response `200` `ProductScoringConfigResponse` created product scoring config
	 */
	export namespace ProductsScoringConfigPartialUpdate {
		export type RequestParams = { productId: string };
		export type RequestQuery = {};
		export type RequestBody = ProductScordingConfigRequest;
		export type RequestHeaders = {};
		export type ResponseBody = ProductScoringConfigResponse;
	}
	/**
	 * No description
	 * @tags product
	 * @name ProductsInterestRateConfigCreate
	 * @summary create product interest rate config, replace previous interest rate config if existed
	 * @request POST:/admin/products/{product_id}/interest_rate_config
	 * @response `200` `ProductInterestRateConfigResponse` created product scoring config
	 */
	export namespace ProductsInterestRateConfigCreate {
		export type RequestParams = { productId: string };
		export type RequestQuery = {};
		export type RequestBody = ProductInterestRateConfigRequest;
		export type RequestHeaders = {};
		export type ResponseBody = ProductInterestRateConfigResponse;
	}
	/**
	 * No description
	 * @tags product
	 * @name ProductsInterestRateConfigPartialUpdate
	 * @summary update product interest rate config
	 * @request PATCH:/admin/products/{product_id}/interest_rate_config
	 * @response `200` `ProductInterestRateConfigResponse` update product interest rate config
	 */
	export namespace ProductsInterestRateConfigPartialUpdate {
		export type RequestParams = { productId: string };
		export type RequestQuery = {};
		export type RequestBody = ProductInterestRateConfigRequest;
		export type RequestHeaders = {};
		export type ResponseBody = ProductInterestRateConfigResponse;
	}
	/**
	 * No description
	 * @tags product_families
	 * @name ProductFamiliesList
	 * @summary List product families
	 * @request GET:/admin/product_families
	 * @response `200` `(ProductFamily)[]` product families
	 */
	export namespace ProductFamiliesList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = ProductFamily[];
	}
	/**
	 * No description
	 * @tags product_families
	 * @name ProductFamiliesCreate
	 * @summary create product family
	 * @request POST:/admin/product_families
	 * @response `200` `ProductFamily` created product family
	 */
	export namespace ProductFamiliesCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = ProductFamilyRequest;
		export type RequestHeaders = {};
		export type ResponseBody = ProductFamily;
	}
	/**
	 * No description
	 * @tags product_families
	 * @name ProductFamiliesDetail
	 * @summary get given product family
	 * @request GET:/admin/product_families/{product_family_id}
	 * @response `200` `ProductFamily` OK
	 */
	export namespace ProductFamiliesDetail {
		export type RequestParams = { productFamilyId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = ProductFamily;
	}
	/**
	 * No description
	 * @tags product_families
	 * @name ProductFamiliesPartialUpdate
	 * @summary update product family
	 * @request PATCH:/admin/product_families/{product_family_id}
	 * @response `200` `ProductFamily` updated product family
	 */
	export namespace ProductFamiliesPartialUpdate {
		export type RequestParams = { productFamilyId: string };
		export type RequestQuery = {};
		export type RequestBody = { name?: string; description?: string };
		export type RequestHeaders = {};
		export type ResponseBody = ProductFamily;
	}
	/**
	 * No description
	 * @tags product_families
	 * @name ProductFamiliesDelete
	 * @summary delete product family
	 * @request DELETE:/admin/product_families/{product_family_id}
	 * @response `200` `void` 200 ok deleted, response with no data
	 */
	export namespace ProductFamiliesDelete {
		export type RequestParams = { productFamilyId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags application
	 * @name ApplicationsProductPartialUpdate
	 * @summary update application product
	 * @request PATCH:/admin/applications/{application_id}/product/{product_id}
	 * @response `200` `void` OK
	 */
	export namespace ApplicationsProductPartialUpdate {
		export type RequestParams = { applicationId: string; productId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags application
	 * @name ApplicationsProductsAvailableDetail
	 * @summary get available products for switching to
	 * @request GET:/admin/applications/{application_id}/products_available
	 * @response `200` `(AvailableProductFamily)[]` OK
	 */
	export namespace ApplicationsProductsAvailableDetail {
		export type RequestParams = { applicationId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = AvailableProductFamily[];
	}
	/**
	 * No description
	 * @tags application
	 * @name ApplicationsAccountManagerUpdate
	 * @summary update application account manager id
	 * @request PUT:/admin/applications/{application_id}/account_manager
	 * @response `200` `Application` updated application
	 */
	export namespace ApplicationsAccountManagerUpdate {
		export type RequestParams = { applicationId: string };
		export type RequestQuery = {};
		export type RequestBody = { account_manager_id?: string | null };
		export type RequestHeaders = {};
		export type ResponseBody = Application;
	}
	/**
	 * No description
	 * @tags files
	 * @name ApplicationsFilesArchiveDetail
	 * @summary download files in archive
	 * @request GET:/admin/applications/{application_id}/files_archive/{file_ids}
	 * @response `200` `File` zip archive with selected files
	 */
	export namespace ApplicationsFilesArchiveDetail {
		export type RequestParams = { applicationId: string; fileIds: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = File;
	}
	/**
	 * No description
	 * @tags signatories
	 * @name ApplicationsSignatoriesCreate
	 * @summary Create new signatories
	 * @request POST:/admin/applications/{application_id}/signatories
	 * @response `200` `SignatoriesResponse` created signatory object
	 */
	export namespace ApplicationsSignatoriesCreate {
		export type RequestParams = { applicationId: string };
		export type RequestQuery = {};
		export type RequestBody = { data?: SignatoriesRequest };
		export type RequestHeaders = {};
		export type ResponseBody = SignatoriesResponse;
	}
	/**
	 * No description
	 * @tags signatories
	 * @name ApplicationsSignatoriesDetail
	 * @summary Get all signatories for application
	 * @request GET:/admin/applications/{application_id}/signatories
	 * @response `200` `SignatoriesResponse` signatories for this application
	 */
	export namespace ApplicationsSignatoriesDetail {
		export type RequestParams = { applicationId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = SignatoriesResponse;
	}
	/**
	 * No description
	 * @tags loan
	 * @name ApplicationsGenerateContractCreate
	 * @summary generate loan contract
	 * @request POST:/admin/applications/{application_id}/generate_contract
	 * @response `200` `{ url?: string }` generates loan contract and adds to user files
	 */
	export namespace ApplicationsGenerateContractCreate {
		export type RequestParams = { applicationId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = { url?: string };
	}
	/**
	 * No description
	 * @tags payments
	 * @name LoansPaymentProviderCreate
	 * @summary connect to an application provider
	 * @request POST:/admin/loans/{loan_id}/payment_provider
	 * @response `200` `void` application/loan connected to payment provider
	 */
	export namespace LoansPaymentProviderCreate {
		export type RequestParams = { loanId: string };
		export type RequestQuery = { type: "mangopay" };
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags payments
	 * @name LoansPaymentProviderPartialUpdate
	 * @summary change payment provider data identifiers
	 * @request PATCH:/admin/loans/{loan_id}/payment_provider
	 * @response `200` `void` application/loan connected to payment provider
	 */
	export namespace LoansPaymentProviderPartialUpdate {
		export type RequestParams = { loanId: string };
		export type RequestQuery = { type: "mangopay" };
		export type RequestBody = PaymentProviderInfo;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags payments
	 * @name LoansPaymentProviderDetail
	 * @summary get payment provider data identifiers
	 * @request GET:/admin/loans/{loan_id}/payment_provider
	 * @response `200` `PaymentProviderInfo` application/loan connected to payment provider
	 */
	export namespace LoansPaymentProviderDetail {
		export type RequestParams = { loanId: string };
		export type RequestQuery = { type: "mangopay" };
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = PaymentProviderInfo;
	}
	/**
	 * No description
	 * @tags signatories
	 * @name SignatoriesRequirementsPartialUpdate
	 * @summary Update signatory requirements
	 * @request PATCH:/admin/signatories/{signatory_id}/requirements
	 * @response `200` `SignatoryRequirements` signatory requirments for this application
	 */
	export namespace SignatoriesRequirementsPartialUpdate {
		export type RequestParams = { signatoryId: string };
		export type RequestQuery = {};
		export type RequestBody = SignatoryRequirementsAdminRequest;
		export type RequestHeaders = {};
		export type ResponseBody = SignatoryRequirements;
	}
	/**
	 * No description
	 * @tags signatories
	 * @name SignatoriesRequirementsDetail
	 * @summary Get signatory requiremens
	 * @request GET:/admin/signatories/{signatory_id}/requirements
	 * @response `200` `(SignatoryRequirements)[]` signatory requirments for this application
	 */
	export namespace SignatoriesRequirementsDetail {
		export type RequestParams = { signatoryId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = SignatoryRequirements[];
	}
	/**
	 * No description
	 * @tags signatories
	 * @name SignatoriesKycDetail
	 * @summary Get signatory kyc status
	 * @request GET:/admin/signatories/{signatory_id}/kyc
	 * @response `200` `SignatoryKycStatus` signatory kyc status
	 */
	export namespace SignatoriesKycDetail {
		export type RequestParams = { signatoryId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = SignatoryKycStatus;
	}
	/**
	 * No description
	 * @tags signatories
	 * @name SignatoriesGenerateContractCreate
	 * @summary generate guarantor contract
	 * @request POST:/admin/signatories/{signatory_id}/generate_contract
	 * @response `200` `void` generates guarantor contract for signatory and adds to user files
	 */
	export namespace SignatoriesGenerateContractCreate {
		export type RequestParams = { signatoryId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags signatories
	 * @name ApplicationsSignatoriesRequirementsDetail
	 * @request GET:/admin/applications/{application_id}/signatories_requirements
	 * @response `200` `(SignatoryRequirements)[]` all signatories requirments for this application
	 */
	export namespace ApplicationsSignatoriesRequirementsDetail {
		export type RequestParams = { applicationId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = SignatoryRequirements[];
	}
	/**
	 * No description
	 * @tags signatories
	 * @name SignatoriesPartialUpdate
	 * @request PATCH:/admin/signatories/{signatory_id}
	 * @response `200` `SignatoryResponse` updated signatory object
	 */
	export namespace SignatoriesPartialUpdate {
		export type RequestParams = { signatoryId: string };
		export type RequestQuery = {};
		export type RequestBody = Signatory;
		export type RequestHeaders = {};
		export type ResponseBody = SignatoryResponse;
	}
	/**
	 * No description
	 * @tags signatories
	 * @name SignatoriesDetail
	 * @request GET:/admin/signatories/{signatory_id}
	 * @response `200` `SignatoryResponse` return signatory object
	 */
	export namespace SignatoriesDetail {
		export type RequestParams = { signatoryId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = SignatoryResponse;
	}
	/**
	 * No description
	 * @tags signatories
	 * @name SignatoriesDelete
	 * @summary delete specified signatory
	 * @request DELETE:/admin/signatories/{signatory_id}
	 * @response `200` `void` 200 ok response with no data
	 */
	export namespace SignatoriesDelete {
		export type RequestParams = { signatoryId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * @description get applicaiton loan object
	 * @tags loans
	 * @name ApplicationsLoanDetail
	 * @request GET:/admin/applications/{application_id}/loan
	 * @response `200` `Loan` loan object
	 */
	export namespace ApplicationsLoanDetail {
		export type RequestParams = { applicationId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = Loan;
	}
	/**
	 * @description get application loan repayment plan in csv format
	 * @tags loans
	 * @name ApplicationsLoanRepaymentPlanDetail
	 * @request GET:/admin/applications/{application_id}/loan_repayment_plan
	 * @response `200` `string` loan repayment plan csv table
	 */
	export namespace ApplicationsLoanRepaymentPlanDetail {
		export type RequestParams = { applicationId: string };
		export type RequestQuery = { export_type?: "csv" };
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = string;
	}
	/**
	 * @description get all loans
	 * @tags loans
	 * @name LoansList
	 * @request GET:/admin/loans
	 * @response `200` `{ pagination?: LegacyPagination, result?: (Loan)[] }` loans object
	 */
	export namespace LoansList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = { pagination?: LegacyPagination; result?: Loan[] };
	}
	/**
	 * @description get loan object
	 * @tags loans
	 * @name LoansDetail
	 * @request GET:/admin/loans/{loan_id}
	 * @response `200` `Loan` loan object
	 */
	export namespace LoansDetail {
		export type RequestParams = { loanId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = Loan;
	}
	/**
	 * @description update loan object
	 * @tags loans
	 * @name LoansPartialUpdate
	 * @request PATCH:/admin/loans/{loan_id}
	 * @response `200` `Loan` loan object
	 */
	export namespace LoansPartialUpdate {
		export type RequestParams = { loanId: string };
		export type RequestQuery = {};
		export type RequestBody = { external_reference?: string };
		export type RequestHeaders = {};
		export type ResponseBody = Loan;
	}
	/**
	 * @description generate payment plan document
	 * @tags loans
	 * @name LoansGeneratePaymentPlanCreate
	 * @request POST:/admin/loans/{loan_id}/generate_payment_plan
	 * @response `200` `void` ok
	 */
	export namespace LoansGeneratePaymentPlanCreate {
		export type RequestParams = { loanId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * @description tag loan object with debt note
	 * @tags loans
	 * @name LoansDebtNotePartialUpdate
	 * @request PATCH:/admin/loans/{loan_id}/debt_note
	 * @response `200` `Loan` loan object
	 */
	export namespace LoansDebtNotePartialUpdate {
		export type RequestParams = { loanId: string };
		export type RequestQuery = {};
		export type RequestBody = DebtNote;
		export type RequestHeaders = {};
		export type ResponseBody = Loan;
	}
	/**
	 * @description remove debt note from loan
	 * @tags loans
	 * @name LoansDebtNoteDelete
	 * @request DELETE:/admin/loans/{loan_id}/debt_note
	 * @response `200` `Loan` loan object
	 */
	export namespace LoansDebtNoteDelete {
		export type RequestParams = { loanId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = Loan;
	}
	/**
	 * @description update loan status or payout_date
	 * @tags loans
	 * @name LoansStatusPartialUpdate
	 * @request PATCH:/admin/loans/{loan_id}/status
	 * @response `200` `Loan` loan object
	 */
	export namespace LoansStatusPartialUpdate {
		export type RequestParams = { loanId: string };
		export type RequestQuery = {};
		export type RequestBody = { loan_status?: LoanStatus };
		export type RequestHeaders = {};
		export type ResponseBody = Loan;
	}
	/**
	 * @description update loan status or payout_date
	 * @tags loans
	 * @name LoansPayoutDatePartialUpdate
	 * @request PATCH:/admin/loans/{loan_id}/payout_date
	 * @response `200` `Loan` loan object
	 */
	export namespace LoansPayoutDatePartialUpdate {
		export type RequestParams = { loanId: string };
		export type RequestQuery = {};
		export type RequestBody = { payout_date: string };
		export type RequestHeaders = {};
		export type ResponseBody = Loan;
	}
	/**
	 * @description generate  payment confirmation document
	 * @tags loans
	 * @name LoansGeneratePaymentConfirmationCreate
	 * @request POST:/admin/loans/{loan_id}/generate_payment_confirmation
	 * @response `200` `{ url?: string }` ok
	 */
	export namespace LoansGeneratePaymentConfirmationCreate {
		export type RequestParams = { loanId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = { url?: string };
	}
	/**
	 * No description
	 * @tags loans
	 * @name LoansSignatoriesDetail
	 * @summary signatories
	 * @request GET:/admin/loans/{loan_id}/signatories
	 * @response `200` `SignatoriesResponse` signatories for this loan
	 */
	export namespace LoansSignatoriesDetail {
		export type RequestParams = { loanId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = SignatoriesResponse;
	}
	/**
	 * @description get loan transactions
	 * @tags loans
	 * @name LoansTransactionsDetail
	 * @request GET:/admin/loans/{loan_id}/transactions
	 * @response `200` `LoanTransactionsResponse` loan transaction response
	 */
	export namespace LoansTransactionsDetail {
		export type RequestParams = { loanId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = LoanTransactionsResponse;
	}
	/**
	 * No description
	 * @tags financials
	 * @name LoansFinancialDataDetail
	 * @request GET:/admin/loans/{loan_id}/financial_data
	 * @response `200` `{ financials: (FinancialData)[] }` get financials
	 */
	export namespace LoansFinancialDataDetail {
		export type RequestParams = { loanId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = { financials: FinancialData[] };
	}
	/**
	 * No description
	 * @tags financials
	 * @name ApplicationsFinancialDataCreate
	 * @request POST:/admin/applications/{application_id}/financial_data
	 * @response `200` `{ financials: (FinancialData)[] }` created financials
	 */
	export namespace ApplicationsFinancialDataCreate {
		export type RequestParams = { applicationId: string };
		export type RequestQuery = {};
		export type RequestBody = { financials: FinancialDataRequest[] };
		export type RequestHeaders = {};
		export type ResponseBody = { financials: FinancialData[] };
	}
	/**
	 * No description
	 * @tags financials
	 * @name ApplicationsFinancialDataDetail
	 * @request GET:/admin/applications/{application_id}/financial_data
	 * @response `200` `{ financials: (FinancialData)[] }` get financials
	 */
	export namespace ApplicationsFinancialDataDetail {
		export type RequestParams = { applicationId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = { financials: FinancialData[] };
	}
	/**
	 * No description
	 * @tags kpis
	 * @name ApplicationsKpisDetail
	 * @request GET:/admin/applications/{application_id}/kpis
	 * @response `200` `{ kpis: (KPIs)[] }` get kpis
	 */
	export namespace ApplicationsKpisDetail {
		export type RequestParams = { applicationId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = { kpis: KPIs[] };
	}
	/**
	 * No description
	 * @tags score
	 * @name ApplicationsScoreDetail
	 * @request GET:/admin/applications/{application_id}/score
	 * @response `200` `ScoringResult` get application score
	 */
	export namespace ApplicationsScoreDetail {
		export type RequestParams = { applicationId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = ScoringResult;
	}
	/**
	 * No description
	 * @tags score
	 * @name LoansScoresDetail
	 * @request GET:/admin/loans/{loan_id}/scores
	 * @response `200` `{ scores: (ScoringResult)[] }` get loan score
	 */
	export namespace LoansScoresDetail {
		export type RequestParams = { loanId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = { scores: ScoringResult[] };
	}
	/**
	 * No description
	 * @tags kpi
	 * @name ApplicationsScoringFeedbackPartialUpdate
	 * @request PATCH:/admin/applications/{application_id}/scoring_feedback
	 * @response `200` `ScoringFeedback` created signatory object
	 */
	export namespace ApplicationsScoringFeedbackPartialUpdate {
		export type RequestParams = { applicationId: string };
		export type RequestQuery = {};
		export type RequestBody = ScoringFeedback;
		export type RequestHeaders = {};
		export type ResponseBody = ScoringFeedback;
	}
	/**
	 * No description
	 * @tags kpi
	 * @name ApplicationsScoringFeedbackDetail
	 * @request GET:/admin/applications/{application_id}/scoring_feedback
	 * @response `200` `ScoringFeedback` created signatory object
	 */
	export namespace ApplicationsScoringFeedbackDetail {
		export type RequestParams = { applicationId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = ScoringFeedback;
	}
	/**
	 * No description
	 * @tags kpi
	 * @name ApplicationsScoringFeedbackDelete
	 * @request DELETE:/admin/applications/{application_id}/scoring_feedback
	 * @response `200` `void` created signatory object
	 */
	export namespace ApplicationsScoringFeedbackDelete {
		export type RequestParams = { applicationId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags loans
	 * @name ApplicationsLoanContractCreate
	 * @request POST:/admin/applications/{application_id}/loan_contract
	 * @response `200` `{ url?: string }` loan contract url
	 */
	export namespace ApplicationsLoanContractCreate {
		export type RequestParams = { applicationId: string };
		export type RequestQuery = {};
		export type RequestBody = { filename?: string };
		export type RequestHeaders = {};
		export type ResponseBody = { url?: string };
	}
	/**
	 * No description
	 * @tags loans
	 * @name ApplicationsLoanContractDetail
	 * @request GET:/admin/applications/{application_id}/loan_contract
	 * @response `200` `{ url?: string, file?: string }` loan contract url and filename
	 */
	export namespace ApplicationsLoanContractDetail {
		export type RequestParams = { applicationId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = { url?: string; file?: string };
	}
	/**
	 * No description
	 * @tags loans
	 * @name ApplicationsLoanContractDelete
	 * @request DELETE:/admin/applications/{application_id}/loan_contract
	 * @response `200` `void` loan contract url
	 */
	export namespace ApplicationsLoanContractDelete {
		export type RequestParams = { applicationId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags checklist_template
	 * @name ChecklistsCreate
	 * @request POST:/admin/checklists
	 * @response `200` `ChecklistTemplateResponse` checklist template object
	 */
	export namespace ChecklistsCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = { type?: ChecklistType; checklist_template?: ChecklistTemplate };
		export type RequestHeaders = {};
		export type ResponseBody = ChecklistTemplateResponse;
	}
	/**
	 * No description
	 * @tags checklist_template
	 * @name ChecklistsDetail
	 * @request GET:/admin/checklists/{checklist_id}
	 * @response `200` `ChecklistTemplateResponse` checklist template object
	 */
	export namespace ChecklistsDetail {
		export type RequestParams = { checklistId: number };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = ChecklistTemplateResponse;
	}
	/**
	 * @description get last ( active ) checklist for specified type
	 * @tags checklist_template
	 * @name ChecklistsLastList
	 * @request GET:/admin/checklists/last
	 * @response `200` `ChecklistTemplateResponse` checklist template object
	 */
	export namespace ChecklistsLastList {
		export type RequestParams = {};
		export type RequestQuery = { type: ChecklistTemplateResponse };
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = ChecklistTemplateResponse;
	}
	/**
	 * No description
	 * @tags teylor_application_documents_checklists
	 * @name TeylorApplicationDocumentsChecklistsCreate
	 * @request POST:/admin/teylor_application_documents_checklists
	 * @response `200` `ApplicationChecklistResponse` teylor_application_documents_checklists checklist object
	 */
	export namespace TeylorApplicationDocumentsChecklistsCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = {
			checklist_id?: number;
			application_id?: string;
			checklist_data?: ChecklistUpdateMap;
		};
		export type RequestHeaders = {};
		export type ResponseBody = ApplicationChecklistResponse;
	}
	/**
	 * No description
	 * @tags teylor_application_documents_checklists
	 * @name TeylorApplicationDocumentsChecklistsDetail
	 * @request GET:/admin/teylor_application_documents_checklists/{application_checklist_id}
	 * @response `200` `ApplicationChecklistResponse` teylor_application_documents_checklists checklist object
	 */
	export namespace TeylorApplicationDocumentsChecklistsDetail {
		export type RequestParams = { applicationChecklistId: number };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = ApplicationChecklistResponse;
	}
	/**
	 * No description
	 * @tags teylor_application_documents_checklists
	 * @name TeylorApplicationDocumentsChecklistsPartialUpdate
	 * @request PATCH:/admin/teylor_application_documents_checklists/{application_checklist_id}
	 * @response `200` `ApplicationChecklistResponse` teylor_application_documents_checklists checklist object
	 */
	export namespace TeylorApplicationDocumentsChecklistsPartialUpdate {
		export type RequestParams = { applicationChecklistId: number };
		export type RequestQuery = {};
		export type RequestBody = { checklist_data?: ChecklistUpdateMap };
		export type RequestHeaders = {};
		export type ResponseBody = ApplicationChecklistResponse;
	}
	/**
	 * No description
	 * @tags checklists
	 * @name ApplicationChecklistsCreate
	 * @request POST:/admin/application_checklists
	 * @response `200` `ApplicationChecklistResponse` application checklist object
	 */
	export namespace ApplicationChecklistsCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = {
			checklist_id?: number;
			application_id?: string;
			checklist_data?: ChecklistUpdateMap;
		};
		export type RequestHeaders = {};
		export type ResponseBody = ApplicationChecklistResponse;
	}
	/**
	 * No description
	 * @tags checklists
	 * @name ApplicationChecklistsDetail
	 * @request GET:/admin/application_checklists/{application_checklist_id}
	 * @response `200` `ApplicationChecklistResponse` application checklist object
	 */
	export namespace ApplicationChecklistsDetail {
		export type RequestParams = { applicationChecklistId: number };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = ApplicationChecklistResponse;
	}
	/**
	 * No description
	 * @tags checklists
	 * @name ApplicationChecklistsPartialUpdate
	 * @request PATCH:/admin/application_checklists/{application_checklist_id}
	 * @response `200` `ApplicationChecklistResponse` application checklist object
	 */
	export namespace ApplicationChecklistsPartialUpdate {
		export type RequestParams = { applicationChecklistId: number };
		export type RequestQuery = {};
		export type RequestBody = { checklist_data?: ChecklistUpdateMap };
		export type RequestHeaders = {};
		export type ResponseBody = ApplicationChecklistResponse;
	}
	/**
	 * No description
	 * @tags four_eye_checklists
	 * @name FourEyeChecklistsCreate
	 * @request POST:/admin/four_eye_checklists
	 * @response `200` `ApplicationChecklistResponse` application checklist object
	 */
	export namespace FourEyeChecklistsCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = {
			checklist_id?: number;
			application_id?: string;
			checklist_data?: ChecklistUpdateMap;
		};
		export type RequestHeaders = {};
		export type ResponseBody = ApplicationChecklistResponse;
	}
	/**
	 * No description
	 * @tags four_eye_checklists
	 * @name FourEyeChecklistsDetail
	 * @request GET:/admin/four_eye_checklists/{application_checklist_id}
	 * @response `200` `ApplicationChecklistResponse` application checklist object
	 */
	export namespace FourEyeChecklistsDetail {
		export type RequestParams = { applicationChecklistId: number };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = ApplicationChecklistResponse;
	}
	/**
	 * No description
	 * @tags four_eye_checklists
	 * @name FourEyeChecklistsPartialUpdate
	 * @request PATCH:/admin/four_eye_checklists/{application_checklist_id}
	 * @response `200` `ApplicationChecklistResponse` application checklist object
	 */
	export namespace FourEyeChecklistsPartialUpdate {
		export type RequestParams = { applicationChecklistId: number };
		export type RequestQuery = {};
		export type RequestBody = { checklist_data?: ChecklistUpdateMap };
		export type RequestHeaders = {};
		export type ResponseBody = ApplicationChecklistResponse;
	}
	/**
	 * No description
	 * @tags checklists
	 * @name ApplicationsApplicationChecklistDetail
	 * @request GET:/admin/applications/{application_id}/application_checklist
	 * @response `200` `ApplicationChecklistResponse` application checklist object
	 */
	export namespace ApplicationsApplicationChecklistDetail {
		export type RequestParams = { applicationId: number };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = ApplicationChecklistResponse;
	}
	/**
	 * No description
	 * @tags checklists
	 * @name ApplicationsApplicationChecklistDelete
	 * @request DELETE:/admin/applications/{application_id}/application_checklist
	 * @response `200` `void` application checklist object
	 */
	export namespace ApplicationsApplicationChecklistDelete {
		export type RequestParams = { applicationId: number };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags checklists
	 * @name ApplicationsFourEyeChecklistDetail
	 * @request GET:/admin/applications/{application_id}/four_eye_checklist
	 * @response `200` `ApplicationChecklistResponse` application checklist object
	 */
	export namespace ApplicationsFourEyeChecklistDetail {
		export type RequestParams = { applicationId: number };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = ApplicationChecklistResponse;
	}
	/**
	 * No description
	 * @tags societe-generale
	 * @name ApplicationsReportsCreate
	 * @request POST:/admin/applications/{application_id}/reports
	 * @response `200` `File` application pdf report
	 */
	export namespace ApplicationsReportsCreate {
		export type RequestParams = { applicationId: number };
		export type RequestQuery = { type: "socgen" | "tin" };
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = File;
	}
	/**
	 * No description
	 * @tags societe-generale
	 * @name ApplicationsSocgenCustomInfoCreate
	 * @request POST:/admin/applications/{application_id}/socgen_custom_info
	 * @response `200` `void` ok
	 */
	export namespace ApplicationsSocgenCustomInfoCreate {
		export type RequestParams = { applicationId: number };
		export type RequestQuery = {};
		export type RequestBody = {
			object_info?: Record<string, { provision?: string; finanzierungsstruktur?: string }>;
		};
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags societe-generale
	 * @name ApplicationsBankSpecificInfoDetail
	 * @request GET:/admin/applications/{application_id}/bank_specific_info
	 * @response `200` `(Record<string, string>)[]` ok
	 */
	export namespace ApplicationsBankSpecificInfoDetail {
		export type RequestParams = { applicationId: number };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = Record<string, string>[];
	}
	/**
	 * No description
	 * @tags signatories
	 * @name SignatoriesRequirementsGuarantorContractsCreate
	 * @request POST:/admin/signatories_requirements/{signatory_id}/guarantor_contracts
	 * @response `200` `{ url?: string }` updated signatory object
	 */
	export namespace SignatoriesRequirementsGuarantorContractsCreate {
		export type RequestParams = { signatoryId: string };
		export type RequestQuery = {};
		export type RequestBody = { filename: string };
		export type RequestHeaders = {};
		export type ResponseBody = { url?: string };
	}
	/**
	 * @description get signatories identification status
	 * @tags identification
	 * @name IdentificationStatusList
	 * @request GET:/admin/identification_status
	 * @response `200` `{ idents?: (IdentificationStatus)[], pagination?: LegacyPagination }` signatory ident status
	 */
	export namespace IdentificationStatusList {
		export type RequestParams = {};
		export type RequestQuery = {
			signatory_name?: string;
			company_name?: string;
			provider_status?:
				| "SUCCESS"
				| "SUCCESS_DATA_CHANGED"
				| "FRAUD_SUSPICION_CONFIRMED"
				| "IDENT_EXPIRED"
				| "ABORTED"
				| "REVIEW_PENDING"
				| "FRAUD_SUSPICION_PENDING";
			is_complete?: boolean;
			page?: number;
			size?: number;
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = { idents?: IdentificationStatus[]; pagination?: LegacyPagination };
	}
	/**
	 * @description require signatory to perform video identification
	 * @tags identification
	 * @name SignatoriesRequirementsVideoIdentsCreate
	 * @request POST:/admin/signatories_requirements/{signatory_id}/video_idents
	 * @response `200` `SignatoryIdentStatus` signatory ident status
	 */
	export namespace SignatoriesRequirementsVideoIdentsCreate {
		export type RequestParams = { signatoryId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = SignatoryIdentStatus;
	}
	/**
	 * @description get signatory video identification status
	 * @tags identification
	 * @name SignatoriesRequirementsVideoIdentsDetail
	 * @request GET:/admin/signatories_requirements/{signatory_id}/video_idents
	 * @response `200` `SignatoryIdentStatus` signatory ident status
	 */
	export namespace SignatoriesRequirementsVideoIdentsDetail {
		export type RequestParams = { signatoryId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = SignatoryIdentStatus;
	}
	/**
	 * No description
	 * @tags identification
	 * @name IdentificationStatusDetail
	 * @request GET:/admin/identification_status/{transactioin_id}
	 * @response `200` `SignatoryIdentStatus` signatory ident status
	 */
	export namespace IdentificationStatusDetail {
		export type RequestParams = { transactioinId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = SignatoryIdentStatus;
	}
	/**
	 * No description
	 * @tags identification
	 * @name IdentificationDocumentDefinitionCreate
	 * @request POST:/admin/identification/document_definition
	 * @response `200` `void` signatory ident status
	 */
	export namespace IdentificationDocumentDefinitionCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = {
			definition_id?: string;
			definition_name?: string;
			signature_info?: object;
		};
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags bo_users
	 * @name BoUsersList
	 * @summary Get list of bo_users
	 * @request GET:/admin/bo_users
	 * @response `200` `({ id?: string, email?: string, name?: string })[]` success
	 */
	export namespace BoUsersList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = { id?: string; email?: string; name?: string }[];
	}
	/**
	 * No description
	 * @tags bo_users
	 * @name BoUsersCreate
	 * @summary Create a bo_user (admin)
	 * @request POST:/admin/bo_users
	 * @response `200` `void` successfully created bo_user
	 */
	export namespace BoUsersCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = { email?: string; name?: string; roles?: string[] };
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags bo_users
	 * @name BoUsersEnableCreate
	 * @summary Enable the bo_user (admin) account
	 * @request POST:/admin/bo_users/{bo_user_id}/enable
	 * @response `200` `void` successfully enabled bo_user
	 */
	export namespace BoUsersEnableCreate {
		export type RequestParams = { boUserId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags bo_users
	 * @name BoUsersDisableCreate
	 * @summary Disable the bo_user (admin) account
	 * @request POST:/admin/bo_users/{bo_user_id}/disable
	 * @response `200` `void` successfully disabled bo_user
	 */
	export namespace BoUsersDisableCreate {
		export type RequestParams = { boUserId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags bo_users
	 * @name BoUsersResetCreate
	 * @summary Reset the bo_user (admin) password. User will receive an email with a link for setting a new password.
	 * @request POST:/admin/bo_users/{bo_user_id}/reset
	 * @response `200` `void` successfully reset password for bo_user
	 */
	export namespace BoUsersResetCreate {
		export type RequestParams = { boUserId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags bo_users
	 * @name BoUsersResendInviteCreate
	 * @summary Resend the account setup invitation for the bo_user (admin). User will receive an email with a link for setting a password.
	 * @request POST:/admin/bo_users/{bo_user_id}/resend_invite
	 * @response `200` `void` successfully sent a new invitation
	 */
	export namespace BoUsersResendInviteCreate {
		export type RequestParams = { boUserId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags bo_user_role
	 * @name BoUserRolesList
	 * @summary Get list of bo_user_roles
	 * @request GET:/admin/bo_user_roles
	 * @response `200` `(BOUserRole)[]` success
	 */
	export namespace BoUserRolesList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = BOUserRole[];
	}
	/**
	 * No description
	 * @tags debt_note
	 * @name DebtNotesList
	 * @summary Get debt note
	 * @request GET:/admin/debt_notes
	 * @response `200` `{ page?: number, limit?: number, debt_notes?: (DebtNote)[] }` debt notes list
	 */
	export namespace DebtNotesList {
		export type RequestParams = {};
		export type RequestQuery = { page?: number; limit?: number };
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = { page?: number; limit?: number; debt_notes?: DebtNote[] };
	}
	/**
	 * No description
	 * @tags debt_note
	 * @name DebtNotesCreate
	 * @summary Create new debt note
	 * @request POST:/admin/debt_notes
	 * @response `200` `DebtNote`
	 */
	export namespace DebtNotesCreate {
		export type RequestParams = {};
		export type RequestQuery = { page?: number; limit?: number };
		export type RequestBody = { name: string };
		export type RequestHeaders = {};
		export type ResponseBody = DebtNote;
	}
	/**
	 * No description
	 * @tags debt_note
	 * @name DebtNotesDetail
	 * @summary Get debt note by id
	 * @request GET:/admin/debt_notes/{debt_note_id}
	 * @response `200` `DebtNote`
	 */
	export namespace DebtNotesDetail {
		export type RequestParams = { debtNoteId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = DebtNote;
	}
	/**
	 * No description
	 * @tags debt_note
	 * @name DebtNotesPartialUpdate
	 * @summary Update debt note name
	 * @request PATCH:/admin/debt_notes/{debt_note_id}
	 * @response `200` `DebtNote`
	 */
	export namespace DebtNotesPartialUpdate {
		export type RequestParams = { debtNoteId: string };
		export type RequestQuery = {};
		export type RequestBody = { name: string };
		export type RequestHeaders = {};
		export type ResponseBody = DebtNote;
	}
	/**
	 * No description
	 * @tags debt_note
	 * @name DebtNotesDelete
	 * @summary Delete debt note by id
	 * @request DELETE:/admin/debt_notes/{debt_note_id}
	 * @response `200` `void` delete object
	 */
	export namespace DebtNotesDelete {
		export type RequestParams = { debtNoteId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags debt_note
	 * @name DebtNotesLoansDetail
	 * @summary Get loans tagged with debt note
	 * @request GET:/admin/debt_notes/{debt_note_id}/loans
	 * @response `200` `{ page?: number, limit?: number, loans?: (Loan)[] }` loan tagged with debt note
	 */
	export namespace DebtNotesLoansDetail {
		export type RequestParams = { debtNoteId: string };
		export type RequestQuery = { page?: number; limit?: number };
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = { page?: number; limit?: number; loans?: Loan[] };
	}
	/**
	 * No description
	 * @tags application
	 * @name ApplicationsStatusPartialUpdate
	 * @summary Update application status
	 * @request PATCH:/admin/applications/{application_id}/status
	 * @response `200` `Application` ok
	 * @response `400` `{ errors: ({ error: { code: ErrorCode, message: string } })[] }` status change impossible
	 */
	export namespace ApplicationsStatusPartialUpdate {
		export type RequestParams = { applicationId: string };
		export type RequestQuery = {};
		export type RequestBody = {
			status: string;
			rejectedReason?: string;
			status_awaiting_payout?: { fronting_bank_iban?: string };
		};
		export type RequestHeaders = {};
		export type ResponseBody = Application;
	}
	/**
	 * No description
	 * @tags application
	 * @name ApplicationsNextStatusValidateDetail
	 * @summary Query if status can be changed, get all failed requirements for status change
	 * @request GET:/admin/applications/{application_id}/next_status_validate
	 * @response `200` `StatusPendingActions` status change is possible if no errors returned
	 */
	export namespace ApplicationsNextStatusValidateDetail {
		export type RequestParams = { applicationId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = StatusPendingActions;
	}
	/**
	 * @description get application pricing
	 * @tags application
	 * @name ApplicationsPricingDetail
	 * @request GET:/admin/applications/{application_id}/pricing
	 * @response `200` `ApplicationPricing` pricing object
	 */
	export namespace ApplicationsPricingDetail {
		export type RequestParams = { applicationId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = ApplicationPricing;
	}
	/**
	 * @description set application pricing
	 * @tags application
	 * @name ApplicationsPricingCreate
	 * @request POST:/admin/applications/{application_id}/pricing
	 * @response `200` `ApplicationPricing` set pricing object success
	 */
	export namespace ApplicationsPricingCreate {
		export type RequestParams = { applicationId: string };
		export type RequestQuery = {};
		export type RequestBody = IncomingApplicationPricing;
		export type RequestHeaders = {};
		export type ResponseBody = ApplicationPricing;
	}
	/**
	 * @description get payments for the installment
	 * @tags installment
	 * @name InstallmentsPaymentsDetail
	 * @request GET:/admin/installments/{installment_id}/payments
	 * @response `200` `InstallmentPaymentsResponse` installment payments
	 */
	export namespace InstallmentsPaymentsDetail {
		export type RequestParams = { installmentId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = InstallmentPaymentsResponse;
	}
	/**
	 * @description Requst payment for an installment
	 * @tags installment
	 * @name InstallmentsPaymentsCreate
	 * @request POST:/admin/installments/{installment_id}/payments
	 * @response `200` `void` request payment success
	 */
	export namespace InstallmentsPaymentsCreate {
		export type RequestParams = { installmentId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * @description Manually add a payment transaction to an installment
	 * @tags installment
	 * @name InstallmentsPaymentsManualCreateCreate
	 * @request POST:/admin/installments/{installment_id}/payments/manual_create
	 * @response `200` `void` request payment success
	 */
	export namespace InstallmentsPaymentsManualCreateCreate {
		export type RequestParams = { installmentId: string };
		export type RequestQuery = {};
		export type RequestBody = BankTransactionCreateData;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * @description Requst that payments for an instsallment are reconciled against posted bank transactions
	 * @tags installment
	 * @name InstallmentsReconcilePaymentsCreate
	 * @request POST:/admin/installments/{installment_id}/reconcile_payments
	 * @response `200` `void` request reconcile success
	 */
	export namespace InstallmentsReconcilePaymentsCreate {
		export type RequestParams = { installmentId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags settings
	 * @name SettingsStatusOwnersList
	 * @summary Get application status owner settings
	 * @request GET:/admin/settings/status_owners
	 * @response `200` `(StatusOwnerSettingsItem)[]` success
	 */
	export namespace SettingsStatusOwnersList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = StatusOwnerSettingsItem[];
	}
	/**
	 * No description
	 * @tags settings
	 * @name SettingsStatusOwnersUpdate
	 * @summary Update application status owner settings
	 * @request PUT:/admin/settings/status_owners
	 * @response `200` `void` success
	 */
	export namespace SettingsStatusOwnersUpdate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = { settings?: StatusOwnerSettingsItem[] };
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags tasks
	 * @name TasksList
	 * @summary Get list of Tasks with optional filters
	 * @request GET:/admin/tasks
	 * @response `200` `{ pagination?: Pagination, result?: (TaskResponse)[] }` success
	 */
	export namespace TasksList {
		export type RequestParams = {};
		export type RequestQuery = {
			page?: number;
			page_size?: number;
			application_id?: string;
			loan_id?: string;
			due_from?: string;
			due_to?: string;
			assignee?: string[];
			status?: string[];
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = { pagination?: Pagination; result?: TaskResponse[] };
	}
	/**
	 * @description Create new Task
	 * @tags tasks
	 * @name TasksCreate
	 * @request POST:/admin/tasks
	 * @response `200` `void` success
	 */
	export namespace TasksCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = CreateTaskRequest;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * @description Update Task details
	 * @tags tasks
	 * @name TasksPartialUpdate
	 * @request PATCH:/admin/tasks/{id}
	 * @response `200` `void` success
	 */
	export namespace TasksPartialUpdate {
		export type RequestParams = { id: string };
		export type RequestQuery = {};
		export type RequestBody = UpdateTaskRequest;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * @description Delete Task
	 * @tags tasks
	 * @name TasksDelete
	 * @request DELETE:/admin/tasks/{id}
	 * @response `200` `void` success
	 */
	export namespace TasksDelete {
		export type RequestParams = { id: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * @description Update Task status
	 * @tags tasks
	 * @name TasksStatusPartialUpdate
	 * @request PATCH:/admin/tasks/{id}/status
	 * @response `200` `void` success
	 */
	export namespace TasksStatusPartialUpdate {
		export type RequestParams = { id: string };
		export type RequestQuery = {};
		export type RequestBody = { status_id?: string };
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * @description Get task status settings
	 * @tags tasks, settings
	 * @name SettingsTaskStatusesList
	 * @request GET:/admin/settings/task_statuses
	 * @response `200` `(TaskStatus)[]` success
	 */
	export namespace SettingsTaskStatusesList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = TaskStatus[];
	}
	/**
	 * @description Create task status setting
	 * @tags tasks, settings
	 * @name SettingsTaskStatusesCreate
	 * @request POST:/admin/settings/task_statuses
	 * @response `200` `void` success
	 */
	export namespace SettingsTaskStatusesCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = CreateTaskStatusSettingRequest;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * @description Update task status setting
	 * @tags tasks, settings
	 * @name SettingsTaskStatusesPartialUpdate
	 * @request PATCH:/admin/settings/task_statuses/{id}
	 * @response `200` `void` success
	 */
	export namespace SettingsTaskStatusesPartialUpdate {
		export type RequestParams = { id: string };
		export type RequestQuery = {};
		export type RequestBody = UpdateTaskStatusSettingRequest;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * @description Get task tag settings
	 * @tags tasks, settings
	 * @name SettingsTaskTagsList
	 * @request GET:/admin/settings/task_tags
	 * @response `200` `(TaskTag)[]` success
	 */
	export namespace SettingsTaskTagsList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = TaskTag[];
	}
	/**
	 * @description Create task tag setting
	 * @tags tasks, settings
	 * @name SettingsTaskTagsCreate
	 * @request POST:/admin/settings/task_tags
	 * @response `200` `void` success
	 */
	export namespace SettingsTaskTagsCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = CreateTaskTagSettingRequest;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * @description Update task tag setting
	 * @tags tasks, settings
	 * @name SettingsTaskTagsPartialUpdate
	 * @request PATCH:/admin/settings/task_tags/{id}
	 * @response `200` `void` success
	 */
	export namespace SettingsTaskTagsPartialUpdate {
		export type RequestParams = { id: string };
		export type RequestQuery = {};
		export type RequestBody = UpdateTaskTagSettingRequest;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * @description Get user data
	 * @tags users
	 * @name UsersDetail
	 * @request GET:/admin/users/{id}
	 * @response `200` `UserResponse` success
	 */
	export namespace UsersDetail {
		export type RequestParams = { id: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = UserResponse;
	}
	/**
	 * @description Get user companies
	 * @tags users
	 * @name UsersCompaniesDetail
	 * @request GET:/admin/users/{id}/companies
	 * @response `200` `{ pagination: LegacyPagination, companies: (AdminCompany)[] }` success
	 */
	export namespace UsersCompaniesDetail {
		export type RequestParams = { id: string };
		export type RequestQuery = {
			page?: number;
			page_size?: number;
			name?: string;
			name_like?: string;
			post_code?: string;
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = { pagination: LegacyPagination; companies: AdminCompany[] };
	}
	/**
	 * @description Get user applications
	 * @tags users
	 * @name UsersApplicationsDetail
	 * @request GET:/admin/users/{id}/applications
	 * @response `200` `{ pagination: LegacyPagination, applications: (AdminApplicationListItem)[] }` success
	 */
	export namespace UsersApplicationsDetail {
		export type RequestParams = { id: string };
		export type RequestQuery = {
			page?: number;
			page_size?: number;
			text?: string;
			status?: string;
			provider?: string;
			status_owner_id?: string;
			account_manager_id?: string;
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = {
			pagination: LegacyPagination;
			applications: AdminApplicationListItem[];
		};
	}
	/**
	 * @description Resend confirmation email
	 * @tags users
	 * @name UsersResendConfEmailCreate
	 * @request POST:/admin/users/{id}/resend_conf_email
	 * @response `200` `void` success
	 */
	export namespace UsersResendConfEmailCreate {
		export type RequestParams = { id: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * @description Reset user
	 * @tags users
	 * @name UsersResetCreate
	 * @request POST:/admin/users/{id}/reset
	 * @response `200` `void` success
	 */
	export namespace UsersResetCreate {
		export type RequestParams = { id: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * @description Get companies
	 * @tags companies
	 * @name CompaniesList
	 * @request GET:/admin/companies/
	 * @response `200` `{ pagination?: LegacyPagination, companies?: (AdminCompany)[] }` success
	 */
	export namespace CompaniesList {
		export type RequestParams = {};
		export type RequestQuery = {
			page?: number;
			page_size?: number;
			name?: string;
			name_like?: string;
			post_code?: string;
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = { pagination?: LegacyPagination; companies?: AdminCompany[] };
	}
	/**
	 * @description Update company by id
	 * @tags companies
	 * @name CompaniesPartialUpdate
	 * @request PATCH:/admin/companies/{companyId}
	 * @response `200` `{ companyId: string, taxIdNumber: string, businessPurpose: string }` success
	 */
	export namespace CompaniesPartialUpdate {
		export type RequestParams = { companyId: string };
		export type RequestQuery = {};
		export type RequestBody = { taxIdNumber?: string; businessPurpose?: string };
		export type RequestHeaders = {};
		export type ResponseBody = { companyId: string; taxIdNumber: string; businessPurpose: string };
	}
	/**
	 * @description Get companies
	 * @tags companies
	 * @name CompaniesUsersDetail
	 * @request GET:/admin/companies/{company_name}/{company_postcode}/users
	 * @response `200` `{ pagination: LegacyPagination, companyUsers: (CompanyUserResponse)[] }` success
	 */
	export namespace CompaniesUsersDetail {
		export type RequestParams = { companyName: string; companyPostcode: string };
		export type RequestQuery = { page?: number; page_size?: number };
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = {
			pagination: LegacyPagination;
			companyUsers: CompanyUserResponse[];
		};
	}
	/**
	 * @description set all idents as completed ( testing tool ). Only works on dev/local/test environment for user with dev_only_bypass_checks permission
	 * @tags dev-hacks
	 * @name DevHacksApplicationsCompleteIdentsCreate
	 * @request POST:/admin/dev-hacks/applications/{application_id}/complete_idents
	 * @response `200` `void` success
	 */
	export namespace DevHacksApplicationsCompleteIdentsCreate {
		export type RequestParams = { applicationId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * @description List notes
	 * @tags notes
	 * @name NotesList
	 * @request GET:/admin/notes
	 * @response `200` `{ pagination?: Pagination, result?: (NoteResponse)[] }` success
	 */
	export namespace NotesList {
		export type RequestParams = {};
		export type RequestQuery = {
			page?: number;
			page_size?: number;
			subject_kind?: NoteSubjectKind;
			subject_id?: string;
			created_by?: string;
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = { pagination?: Pagination; result?: NoteResponse[] };
	}
	/**
	 * @description Create Note
	 * @tags notes
	 * @name NotesCreate
	 * @request POST:/admin/notes
	 * @response `200` `NoteResponse` success
	 */
	export namespace NotesCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = CreateNoteRequest;
		export type RequestHeaders = {};
		export type ResponseBody = NoteResponse;
	}
	/**
	 * @description Get single Note
	 * @tags notes
	 * @name NotesDetail
	 * @request GET:/admin/notes/{id}
	 * @response `200` `NoteResponse` success
	 */
	export namespace NotesDetail {
		export type RequestParams = { id: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = NoteResponse;
	}
	/**
	 * @description Update Note
	 * @tags notes
	 * @name NotesPartialUpdate
	 * @request PATCH:/admin/notes/{id}
	 * @response `200` `NoteResponse` success
	 * @response `403` `void` forbidden
	 */
	export namespace NotesPartialUpdate {
		export type RequestParams = { id: string };
		export type RequestQuery = {};
		export type RequestBody = UpdateNoteRequest;
		export type RequestHeaders = {};
		export type ResponseBody = NoteResponse;
	}
	/**
	 * @description Delete Note
	 * @tags notes
	 * @name NotesDelete
	 * @request DELETE:/admin/notes/{id}
	 * @response `200` `void` success
	 * @response `403` `void` forbidden
	 */
	export namespace NotesDelete {
		export type RequestParams = { id: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags tenants
	 * @name TenantsList
	 * @summary List tenants
	 * @request GET:/admin/tenants/
	 * @response `200` `(Tenant)[]` tenants
	 */
	export namespace TenantsList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = Tenant[];
	}
	/**
	 * @description Create new tenant
	 * @tags tenants
	 * @name TenantsCreate
	 * @request POST:/admin/tenants/
	 * @response `200` `Tenant` created object
	 */
	export namespace TenantsCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = TenantCreateRequest;
		export type RequestHeaders = {};
		export type ResponseBody = Tenant;
	}
	/**
	 * @description update tenant
	 * @tags tenants
	 * @name TenantsPartialUpdate
	 * @request PATCH:/admin/tenants/{id}
	 * @response `200` `Tenant` updated object
	 */
	export namespace TenantsPartialUpdate {
		export type RequestParams = { id: string };
		export type RequestQuery = {};
		export type RequestBody = Tenant;
		export type RequestHeaders = {};
		export type ResponseBody = Tenant;
	}
	/**
	 * No description
	 * @tags tenants
	 * @name TenantsDelete
	 * @summary delete tenants
	 * @request DELETE:/admin/tenants/{id}
	 * @response `200` `void` 200 ok deleted, response with no data
	 */
	export namespace TenantsDelete {
		export type RequestParams = { id: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * @description get tenant
	 * @tags tenants
	 * @name TenantsDetail
	 * @request GET:/admin/tenants/{id}
	 * @response `200` `Tenant` tenant object
	 */
	export namespace TenantsDetail {
		export type RequestParams = { id: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = Tenant;
	}
	/**
	 * @description create partner
	 * @tags partner
	 * @name PartnersCreate
	 * @request POST:/admin/partners/
	 * @response `200` `{ partner_id: string }` newly created partner id
	 */
	export namespace PartnersCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = { name: string; tenant_id: string; partner_key: string };
		export type RequestHeaders = {};
		export type ResponseBody = { partner_id: string };
	}
	/**
	 * @description get partners
	 * @tags partner
	 * @name PartnersList
	 * @request GET:/admin/partners/
	 * @response `200` `{ pagination: { total_rows?: number, current_page?: number, items_per_page?: number }, result: (PartnerResponse)[] }` partners
	 */
	export namespace PartnersList {
		export type RequestParams = {};
		export type RequestQuery = { page: number; page_size: number; text?: string; status: string };
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = {
			pagination: { total_rows?: number; current_page?: number; items_per_page?: number };
			result: PartnerResponse[];
		};
	}
	/**
	 * @description update partner
	 * @tags partner
	 * @name PartnersPartialUpdate
	 * @request PATCH:/admin/partners/{id}
	 * @response `200` `PartnerResponse` updated config
	 */
	export namespace PartnersPartialUpdate {
		export type RequestParams = { id: string };
		export type RequestQuery = {};
		export type RequestBody = { name?: string; partner_key?: string };
		export type RequestHeaders = {};
		export type ResponseBody = PartnerResponse;
	}
	/**
	 * @description delete partner
	 * @tags partner
	 * @name PartnersDelete
	 * @request DELETE:/admin/partners/{id}
	 * @response `200` `void` Deleted partner
	 */
	export namespace PartnersDelete {
		export type RequestParams = { id: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * @description get partner
	 * @tags partner
	 * @name PartnersDetail
	 * @request GET:/admin/partners/{id}
	 * @response `200` `PartnerResponseAdmin` partner
	 */
	export namespace PartnersDetail {
		export type RequestParams = { id: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = PartnerResponseAdmin;
	}
	/**
	 * No description
	 * @tags partner
	 * @name PartnersPusersDetail
	 * @summary Get partner users
	 * @request GET:/admin/partners/{id}/pusers
	 * @response `200` `{ pagination?: Pagination, result?: (PartnerUser)[] }` tenants
	 */
	export namespace PartnersPusersDetail {
		export type RequestParams = { id: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = { pagination?: Pagination; result?: PartnerUser[] };
	}
	/**
	 * No description
	 * @tags partner
	 * @name PartnersEnableCreate
	 * @summary Enable the partner
	 * @request POST:/admin/partners/{id}/enable
	 * @response `200` `void` successfully enabled partner
	 */
	export namespace PartnersEnableCreate {
		export type RequestParams = { id: string };
		export type RequestQuery = {};
		export type RequestBody = { is_enabled?: boolean };
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * @description update partner config
	 * @tags partner
	 * @name PartnersConfigPartialUpdate
	 * @request PATCH:/admin/partners/{id}/config
	 * @response `200` `PartnerConfig` updated config
	 */
	export namespace PartnersConfigPartialUpdate {
		export type RequestParams = { id: string };
		export type RequestQuery = {};
		export type RequestBody = PartnerConfig;
		export type RequestHeaders = {};
		export type ResponseBody = PartnerConfig;
	}
	/**
	 * @description get partner config
	 * @tags partner
	 * @name PartnersConfigDetail
	 * @request GET:/admin/partners/{id}/config
	 * @response `200` `PartnerConfigWithSelectors` partner config
	 */
	export namespace PartnersConfigDetail {
		export type RequestParams = { id: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = PartnerConfigWithSelectors;
	}
	/**
	 * @description create partner default product selector
	 * @tags partner
	 * @name PartnersDefaultProductSelectionCreate
	 * @request POST:/admin/partners/{id}/default_product_selection
	 * @response `200` `PartnerDefaultProductSelector` created partner default product selector
	 */
	export namespace PartnersDefaultProductSelectionCreate {
		export type RequestParams = { id: string };
		export type RequestQuery = {};
		export type RequestBody = PartnerDefaultProductSelectorRequest;
		export type RequestHeaders = {};
		export type ResponseBody = PartnerDefaultProductSelector;
	}
	/**
	 * @description get partner default products selector
	 * @tags partner
	 * @name PartnersDefaultProductSelectionDetail
	 * @request GET:/admin/partners/{id}/default_product_selection
	 * @response `200` `(PartnerDefaultProductSelector)[]` Get partner default product selector
	 */
	export namespace PartnersDefaultProductSelectionDetail {
		export type RequestParams = { id: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = PartnerDefaultProductSelector[];
	}
	/**
	 * @description update partner default product selector
	 * @tags partner
	 * @name PartnersDefaultProductSelectionPartialUpdate
	 * @request PATCH:/admin/partners/{id}/default_product_selection/{default_product_selection_id}
	 * @response `200` `PartnerDefaultProductSelector` updated partner default product selector
	 */
	export namespace PartnersDefaultProductSelectionPartialUpdate {
		export type RequestParams = { id: string; defaultProductSelectionId: string };
		export type RequestQuery = {};
		export type RequestBody = PartnerDefaultProductSelectorRequest;
		export type RequestHeaders = {};
		export type ResponseBody = PartnerDefaultProductSelector;
	}
	/**
	 * @description delete partner default product selector
	 * @tags partner
	 * @name PartnersDefaultProductSelectionDelete
	 * @request DELETE:/admin/partners/{id}/default_product_selection/{default_product_selection_id}
	 * @response `200` `void` Deleted partner default product selector
	 */
	export namespace PartnersDefaultProductSelectionDelete {
		export type RequestParams = { id: string; defaultProductSelectionId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
}

export namespace User {
	/**
	 * No description
	 * @tags account
	 * @name ProfileList
	 * @summary Get the user's profile
	 * @request GET:/user/profile
	 * @response `200` `UserProfile` return user profile
	 */
	export namespace ProfileList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = UserProfile;
	}
	/**
	 * No description
	 * @tags account
	 * @name ProfilePartialUpdate
	 * @summary Update user's profile
	 * @request PATCH:/user/profile/{profile_id}
	 * @response `200` `UserProfile` update user profile
	 */
	export namespace ProfilePartialUpdate {
		export type RequestParams = { profileId: string };
		export type RequestQuery = {};
		export type RequestBody = UserProfileUpdate;
		export type RequestHeaders = {};
		export type ResponseBody = UserProfile;
	}
	/**
	 * No description
	 * @tags account
	 * @name UsersCreate
	 * @summary create new user
	 * @request POST:/user/users
	 * @response `200` `void` user created
	 */
	export namespace UsersCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = UserCreate;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags account
	 * @name AccountRegisterCreate
	 * @summary register new user
	 * @request POST:/user/account/register
	 * @response `200` `void` account created
	 */
	export namespace AccountRegisterCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = UserRegister;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags account
	 * @name AccountRecoverCreate
	 * @summary reset password for existing user, confirm email
	 * @request POST:/user/account/recover
	 * @response `200` `void` account created
	 */
	export namespace AccountRecoverCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = UserRegister;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags account
	 * @name AccountInviteAcceptCreate
	 * @summary reset password, confirm email, set T&C flags, language and region for invited signatory
	 * @request POST:/user/account/invite_accept
	 * @response `200` `void` account created
	 */
	export namespace AccountInviteAcceptCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = InvitedUserRegister;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags account
	 * @name AccountLoginCreate
	 * @summary login user
	 * @request POST:/user/account/login
	 * @response `200` `void` logged in
	 */
	export namespace AccountLoginCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = UserLogin;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags application
	 * @name ApplicationsList
	 * @summary get user's applications
	 * @request GET:/user/applications/
	 * @response `200` `UserApplicationsResponse` application
	 */
	export namespace ApplicationsList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = UserApplicationsResponse;
	}
	/**
	 * No description
	 * @tags application
	 * @name ApplicationsCreate
	 * @summary create new application
	 * @request POST:/user/applications/
	 * @response `200` `Application` application
	 */
	export namespace ApplicationsCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = ApplicationCreate;
		export type RequestHeaders = {};
		export type ResponseBody = Application;
	}
	/**
	 * No description
	 * @tags application
	 * @name ApplicationsDetail
	 * @request GET:/user/applications/{application_id}
	 * @response `200` `UserApplication` application
	 */
	export namespace ApplicationsDetail {
		export type RequestParams = { applicationId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = UserApplication;
	}
	/**
	 * No description
	 * @tags application
	 * @name ApplicationsPartialUpdate
	 * @request PATCH:/user/applications/{application_id}
	 * @response `200` `UserApplication` application
	 */
	export namespace ApplicationsPartialUpdate {
		export type RequestParams = { applicationId: string };
		export type RequestQuery = {};
		export type RequestBody = {
			confirmAllSignatoriesAdded?: boolean;
			sepaMandatAccepted?: boolean;
			taxIdNumber?: string;
		};
		export type RequestHeaders = {};
		export type ResponseBody = UserApplication;
	}
	/**
	 * No description
	 * @tags application
	 * @name ApplicationsBankinfoPartialUpdate
	 * @request PATCH:/user/applications/{application_id}/bankinfo
	 * @response `200` `UserApplication` application
	 */
	export namespace ApplicationsBankinfoPartialUpdate {
		export type RequestParams = { applicationId: string };
		export type RequestQuery = {};
		export type RequestBody = { bankName?: string; iban?: string };
		export type RequestHeaders = {};
		export type ResponseBody = UserApplication;
	}
	/**
	 * No description
	 * @tags signatories
	 * @name ApplicationsSignatoriesRequirementsPartialUpdate
	 * @summary Update signatory accept flags
	 * @request PATCH:/user/applications/{application_id}/signatories/{signatory_id}/requirements
	 * @response `200` `SignatoryRequirements` signatory requirements
	 */
	export namespace ApplicationsSignatoriesRequirementsPartialUpdate {
		export type RequestParams = { applicationId: string; signatoryId: string };
		export type RequestQuery = {};
		export type RequestBody = SignatoryRequirementsUserRequest;
		export type RequestHeaders = {};
		export type ResponseBody = SignatoryRequirements;
	}
	/**
	 * No description
	 * @tags signatories
	 * @name SignatoriesKycDetail
	 * @summary Get signatory kyc status
	 * @request GET:/user/signatories/{signatory_id}/kyc
	 * @response `200` `SignatoryKycStatus` signatory kyc status
	 */
	export namespace SignatoriesKycDetail {
		export type RequestParams = { signatoryId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = SignatoryKycStatus;
	}
	/**
	 * No description
	 * @tags signatories
	 * @name SignatoriesRequirementsDetail
	 * @summary get signatories requirements
	 * @request GET:/user/signatories/{signatory_id}/requirements
	 * @response `200` `(SignatoryRequirements)[]` signatory requirments for this application
	 */
	export namespace SignatoriesRequirementsDetail {
		export type RequestParams = { signatoryId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = SignatoryRequirements[];
	}
	/**
	 * No description
	 * @tags signatories
	 * @name ApplicationsSignatoriesRequirementsDetail
	 * @request GET:/user/applications/{application_id}/signatories_requirements
	 * @response `200` `(SignatoryRequirements)[]` all signatories requirments for this application
	 */
	export namespace ApplicationsSignatoriesRequirementsDetail {
		export type RequestParams = { applicationId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = SignatoryRequirements[];
	}
	/**
	 * No description
	 * @tags signatories
	 * @name ApplicationsSignatoriesDetail
	 * @request GET:/user/applications/{application_id}/signatories
	 * @response `200` `SignatoriesResponse` all signatories for this application
	 */
	export namespace ApplicationsSignatoriesDetail {
		export type RequestParams = { applicationId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = SignatoriesResponse;
	}
	/**
	 * No description
	 * @tags signatories
	 * @name SignatoriesPartialUpdate
	 * @request PATCH:/user/signatories/{signatory_id}
	 * @response `200` `SignatoryResponse` updated signatory object
	 */
	export namespace SignatoriesPartialUpdate {
		export type RequestParams = { signatoryId: string };
		export type RequestQuery = {};
		export type RequestBody = SignatoryResponse;
		export type RequestHeaders = {};
		export type ResponseBody = SignatoryResponse;
	}
	/**
	 * No description
	 * @tags signatories
	 * @name SignatoriesDetail
	 * @request GET:/user/signatories/{signatory_id}
	 * @response `200` `SignatoryResponse` return signatory object
	 */
	export namespace SignatoriesDetail {
		export type RequestParams = { signatoryId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = SignatoryResponse;
	}
	/**
	 * No description
	 * @tags signatories
	 * @name SignatoriesDelete
	 * @summary delete specified signatory
	 * @request DELETE:/user/signatories/{signatory_id}
	 * @response `200` `void` 200 ok response with no data
	 */
	export namespace SignatoriesDelete {
		export type RequestParams = { signatoryId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags finance object
	 * @name ApplicationsFinanceObjectsCreate
	 * @summary Create finance object
	 * @request POST:/user/applications/{application_id}/finance_objects
	 * @response `201` `FinanceObjectCreateResponse` created finance object
	 */
	export namespace ApplicationsFinanceObjectsCreate {
		export type RequestParams = { applicationId: string };
		export type RequestQuery = {};
		export type RequestBody = FinanceObjectRequest;
		export type RequestHeaders = {};
		export type ResponseBody = FinanceObjectCreateResponse;
	}
	/**
	 * No description
	 * @tags finance object
	 * @name ApplicationsFinanceObjectsDetail
	 * @summary Get application finance objects
	 * @request GET:/user/applications/{application_id}/finance_objects
	 * @response `200` `(FinanceObjectGetResponse)[]` list of finance objects
	 */
	export namespace ApplicationsFinanceObjectsDetail {
		export type RequestParams = { applicationId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = FinanceObjectGetResponse[];
	}
	/**
	 * No description
	 * @tags finance object
	 * @name ApplicationsFinanceObjectsUpdate
	 * @summary Update finance object
	 * @request PUT:/user/applications/{application_id}/finance_objects/{finance_object_id}
	 * @response `200` `void` updated finance object
	 */
	export namespace ApplicationsFinanceObjectsUpdate {
		export type RequestParams = { applicationId: string; financeObjectId: string };
		export type RequestQuery = {};
		export type RequestBody = FinanceObjectRequest;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags finance object
	 * @name ApplicationsFinanceObjectsDelete
	 * @summary Delete finance object
	 * @request DELETE:/user/applications/{application_id}/finance_objects/{finance_object_id}
	 * @response `200` `void` deleted finance object
	 */
	export namespace ApplicationsFinanceObjectsDelete {
		export type RequestParams = { applicationId: string; financeObjectId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags loans
	 * @name ApplicationsLoanContractDetail
	 * @request GET:/user/applications/{application_id}/loan_contract
	 * @response `200` `LoanContract` loan contract url and filename
	 */
	export namespace ApplicationsLoanContractDetail {
		export type RequestParams = { applicationId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = LoanContract;
	}
	/**
	 * No description
	 * @tags application
	 * @name ApplicationsStatusPartialUpdate
	 * @summary Update application status
	 * @request PATCH:/user/applications/{application_id}/status
	 * @response `200` `Application` ok
	 * @response `400` `{ errors?: ({ error?: { code?: ErrorCode } })[] }` status change impossible
	 */
	export namespace ApplicationsStatusPartialUpdate {
		export type RequestParams = { applicationId: string };
		export type RequestQuery = {};
		export type RequestBody = UpdateApplicationStatusRequestBody;
		export type RequestHeaders = {};
		export type ResponseBody = Application;
	}
	/**
	 * No description
	 * @tags application
	 * @name ApplicationsNextStatusValidateDetail
	 * @summary Query if status can be changed, get all failed requirents for status change
	 * @request GET:/user/applications/{application_id}/next_status_validate
	 * @response `200` `StatusPendingActions` status change is possible if no errors retunred
	 */
	export namespace ApplicationsNextStatusValidateDetail {
		export type RequestParams = { applicationId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = StatusPendingActions;
	}
	/**
	 * @description Get list of documents for the application
	 * @tags documents
	 * @name ApplicationsDocumentsDetail
	 * @request GET:/user/applications/{id}/documents
	 * @response `200` `DocumentsResponse` Pagination and list of documents for the application
	 */
	export namespace ApplicationsDocumentsDetail {
		export type RequestParams = { id: string };
		export type RequestQuery = {
			document_category?: DocumentCategory;
			related_entity_kind?: RelatedEntityKind;
			related_entity_id?: string;
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = DocumentsResponse;
	}
	/**
	 * @description Upload a document for the application
	 * @tags documents
	 * @name ApplicationsDocumentsCreate
	 * @request POST:/user/applications/{id}/documents
	 * @response `200` `UploadDocumentResponse` URL to upload the data to S3
	 */
	export namespace ApplicationsDocumentsCreate {
		export type RequestParams = { id: string };
		export type RequestQuery = {
			document_category?: DocumentCategory;
			related_entity_kind?: RelatedEntityKind;
			related_entity_id?: string;
		};
		export type RequestBody = UploadDocumentPayload;
		export type RequestHeaders = {};
		export type ResponseBody = UploadDocumentResponse;
	}
	/**
	 * @description Get download URL for the document
	 * @tags documents
	 * @name ApplicationsDocumentsDownloadUrlDetail
	 * @request GET:/user/applications/{appId}/documents/{docId}/download_url
	 * @response `200` `DocumentUrlResponse` Download URL for the document
	 */
	export namespace ApplicationsDocumentsDownloadUrlDetail {
		export type RequestParams = { appId: string; docId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = DocumentUrlResponse;
	}
}

export namespace Applications {
	/**
	 * No description
	 * @tags application
	 * @name ApplicationsCreate
	 * @summary create new user and application
	 * @request POST:/applications/
	 * @response `200` `Application` application
	 */
	export namespace ApplicationsCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = ApplicationAndUserCreate;
		export type RequestHeaders = {};
		export type ResponseBody = Application;
	}
}

export namespace Entitlements {
	/**
	 * No description
	 * @tags entitlements
	 * @name EntitlementsList
	 * @summary List entitlements
	 * @request GET:/entitlements
	 * @response `200` `{ pagination?: Pagination, result?: (Entitlement)[] }` entitlements
	 */
	export namespace EntitlementsList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = { pagination?: Pagination; result?: Entitlement[] };
	}
	/**
	 * No description
	 * @tags entitlements
	 * @name EntitlementsCreate
	 * @summary Create entitlement
	 * @request POST:/entitlements
	 * @response `201` `Entitlement` created entitlement
	 */
	export namespace EntitlementsCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = EntitlementRequest;
		export type RequestHeaders = {};
		export type ResponseBody = Entitlement;
	}
	/**
	 * No description
	 * @tags entitlements
	 * @name EntitlementsPartialUpdate
	 * @summary Update entitlement
	 * @request PATCH:/entitlements/{entitlement_id}
	 * @response `200` `Entitlement` updated entitlement
	 */
	export namespace EntitlementsPartialUpdate {
		export type RequestParams = { entitlementId: number };
		export type RequestQuery = {};
		export type RequestBody = EntitlementRequest;
		export type RequestHeaders = {};
		export type ResponseBody = Entitlement;
	}
	/**
	 * No description
	 * @tags entitlements
	 * @name EntitlementsDelete
	 * @summary Delete entitlement
	 * @request DELETE:/entitlements/{entitlement_id}
	 * @response `200` `void` deleted entitlement
	 */
	export namespace EntitlementsDelete {
		export type RequestParams = { entitlementId: number };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
}

export namespace Partners {
	/**
	 * No description
	 * @tags partner_entitlements
	 * @name EntitlementsDetail
	 * @summary List partner entitlements
	 * @request GET:/partners/{id}/entitlements
	 * @response `200` `{ result?: (PartnerEntitlement)[] }` entitlements
	 */
	export namespace EntitlementsDetail {
		export type RequestParams = { id: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = { result?: PartnerEntitlement[] };
	}
	/**
	 * No description
	 * @tags partner_entitlements
	 * @name EntitlementsCreate
	 * @summary Create partner entitlement
	 * @request POST:/partners/{id}/entitlements
	 * @response `201` `PartnerEntitlement` created partner entitlement
	 */
	export namespace EntitlementsCreate {
		export type RequestParams = { id: string };
		export type RequestQuery = {};
		export type RequestBody = PartnerEntitlementRequest;
		export type RequestHeaders = {};
		export type ResponseBody = PartnerEntitlement;
	}
	/**
	 * No description
	 * @tags partner_entitlements
	 * @name EntitlementsDelete
	 * @summary Delete partner entitlement
	 * @request DELETE:/partners/{id}/entitlements/{entitlement_id}
	 * @response `200` `void` deleted partner entitlement
	 */
	export namespace EntitlementsDelete {
		export type RequestParams = { id: string; entitlementId: number };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
}

export namespace Config {
	/**
	 * @description get public features and partner theme config for FE
	 * @tags config, partner
	 * @name ConfigList
	 * @request GET:/config
	 * @response `200` `ConfigResponse` public features and partner theme config
	 */
	export namespace ConfigList {
		export type RequestParams = {};
		export type RequestQuery = { partner_key?: string };
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = ConfigResponse;
	}
}

export namespace Partner {
	/**
	 * @description get partner theme config for FE
	 * @tags partner
	 * @name KeyConfigDetail
	 * @request GET:/partner/key/{id}/config
	 * @response `200` `PartnerConfigResponse` partner theme config
	 */
	export namespace KeyConfigDetail {
		export type RequestParams = { id: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = PartnerConfigResponse;
	}
}

export namespace SupportRequest {
	/**
	 * @description send support request
	 * @tags support
	 * @name SupportRequestCreate
	 * @request POST:/support_request
	 * @response `200` `void` OK
	 */
	export namespace SupportRequestCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = { phone: string };
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
}

export namespace Contact {
	/**
	 * @description send contact request
	 * @tags support
	 * @name ContactCreate
	 * @request POST:/contact
	 * @response `200` `void` OK
	 */
	export namespace ContactCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = { page: string; email: string; name: string; message: string };
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
}

export namespace CompanySearch {
	/**
	 * @description Get list of companies matching either the `name` or `country`
	 * @tags company search
	 * @name CompanySearchList
	 * @request GET:/company_search
	 * @response `200` `CompanySearchResponse` List of companies
	 */
	export namespace CompanySearchList {
		export type RequestParams = {};
		export type RequestQuery = { name?: string; country?: string };
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = CompanySearchResponse;
	}
}

export namespace Api {
	/**
	 * @description Get application logs list
	 * @tags application
	 * @name AdminApplicationsLogsDetail
	 * @request GET:/api/admin/applications/{appId}/logs
	 * @response `200` `{ logs: ({ applicationId?: string, created?: string, data?: string, user_id?: string, whoCreated?: string })[] }` List of logs
	 */
	export namespace AdminApplicationsLogsDetail {
		export type RequestParams = { appId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = {
			logs: {
				applicationId?: string;
				created?: string;
				data?: string;
				user_id?: string;
				whoCreated?: string;
			}[];
		};
	}
}
