import styled, { css } from "styled-components";
import {
	Card as AntdCard,
	CardProps,
	Col,
	Button as AntdButton,
	Divider,
	Progress,
	Row,
	Tag as AntdTag,
	TagProps as AntdTagsProps,
	Typography,
	Space,
} from "antd";
import { DeleteOutlined, EditOutlined, RedoOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

import { SignatoryResponse } from "@teylor-tools/Api";
import { useTranslations } from "../translations/translations";

const { Text } = Typography;

const Card = styled(AntdCard)`
	max-width: 720px;
`;
interface TagProps extends AntdTagsProps {
	$isPrimary: boolean;
}

const Tag = styled(AntdTag)<TagProps>`
	border-radius: 2px;
	text-transform: uppercase;
	color: var(--teylor-text-secondary);
	background-color: ${({ $isPrimary }) => ($isPrimary ? "var(--ant-primary-1)" : "")};
	border: none;
`;

const PercentageInfo = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const RedoIcon = styled(RedoOutlined)`
	transform: rotate(-90deg);
`;

const Button = styled(AntdButton)<{ $themedLinkColor?: string }>`
	${({ $themedLinkColor }) =>
		$themedLinkColor &&
		css`
			span {
				color: ${$themedLinkColor};
			}

			&:hover {
				opacity: 0.8;
			}
		`}
`;

const ContentWrapper = styled.div`
	display: grid;
	justify-content: center;
	gap: 6px;
`;

interface RepresentativeCardProps extends CardProps {
	representative: SignatoryResponse;
	representativeNationality: string;
	showEditTinButton: boolean;
	onEditTin: () => void;
	onEditRepresentative: (id: string) => void;
	onDeleteRepresentative: (representative: SignatoryResponse) => void;
	refetchRepresentatives?: () => Promise<void> | void;
	showExtra?: boolean;
	themedLinkColor?: string;
	showSchufa?: boolean;
}

const RepresentativeCard = ({
	representative,
	representativeNationality,
	showEditTinButton,
	onEditTin,
	onEditRepresentative,
	onDeleteRepresentative,
	refetchRepresentatives,
	showExtra = false,
	themedLinkColor,
	showSchufa = false,
	...props
}: RepresentativeCardProps) => {
	const t = useTranslations();

	const representativeName = `${representative.first_name} ${representative.last_name}`;
	const percentageOwned = Number.parseFloat(representative.percentage_owned);

	const { is_beneficial_owner, is_sole_signatory, is_contract_signatory, is_legal_signatory } =
		representative;

	return (
		<Card
			title={representativeName}
			extra={
				showExtra && (
					<>
						<Button
							type="link"
							onClick={() => onEditRepresentative(representative.signatory_id)}
							icon={<EditOutlined />}
							$themedLinkColor={themedLinkColor}
						/>
						<Button
							type="link"
							onClick={() => onDeleteRepresentative(representative)}
							icon={<DeleteOutlined />}
							$themedLinkColor={themedLinkColor}
						/>
					</>
				)
			}
			{...props}
		>
			<Row gutter={8} align="middle">
				<Col flex="1 0 8" style={{ minWidth: "33%" }}>
					<ContentWrapper>
						<Text type="secondary">{t.representativeCard.beneficialOwner}</Text>
						<Text type="secondary">{t.representativeCard.legalSignatory}</Text>
						<Text type="secondary">{t.representativeCard.willingToSign}</Text>
						<Text type="secondary">{t.representativeCard.willingToSign}</Text>
					</ContentWrapper>
				</Col>
				<Col flex="0 1 16">
					<Row gutter={[4, 4]}>
						<Col>
							<ContentWrapper>
								<div>
									<Tag $isPrimary={is_beneficial_owner}>{is_beneficial_owner ? t.yes : t.no}</Tag>
								</div>
								<div>
									<Tag $isPrimary={is_legal_signatory}>{is_legal_signatory ? t.yes : t.no}</Tag>
								</div>
								<div>
									<Tag $isPrimary={is_contract_signatory}>
										{is_contract_signatory ? t.yes : t.no}
									</Tag>
								</div>
								<div>
									<Tag $isPrimary={is_sole_signatory}>{is_sole_signatory ? t.yes : t.no}</Tag>
								</div>
							</ContentWrapper>
						</Col>
						<Col>
							<PercentageInfo>
								<Progress
									type="dashboard"
									percent={percentageOwned}
									width={65}
									format={(percent) => (percent !== undefined ? `${percent}%` : "N/A")}
									success={{ percent: 0 }}
								/>
								<div>
									<Text type="secondary">{t.representativeCard.ownership}</Text>
								</div>
							</PercentageInfo>
						</Col>
					</Row>
				</Col>
			</Row>

			<Divider />

			<Row gutter={16}>
				<Col span={8}>
					<Text type="secondary">{t.representativeCard.phone}</Text>
				</Col>
				<Col span={16}>
					<Text>{representative.telephone}</Text>
				</Col>
			</Row>
			<Row gutter={16}>
				<Col span={8}>
					<Text type="secondary">{t.representativeCard.email}</Text>
				</Col>
				<Col span={16}>
					<Text>{representative.email}</Text>
				</Col>
			</Row>

			<Divider />

			<Row gutter={16}>
				<Col span={8}>
					<Text type="secondary">{t.representativeCard.address}</Text>
				</Col>
				<Col span={16}>
					<Text
						style={{
							whiteSpace: "pre-line",
						}}
					>{`${representative.street}, ${representative.street_number},\n${representative.postcode}, ${representative.location},\n${representative.country}`}</Text>
				</Col>
			</Row>

			<Divider />

			<Row gutter={16}>
				<Col span={8}>
					<Text type="secondary">{t.representativeCard.nationality}</Text>
				</Col>
				<Col span={16}>
					<Text>{representativeNationality}</Text>
				</Col>
			</Row>
			<Row gutter={16}>
				<Col span={8}>
					<Text type="secondary">{t.representativeCard.dateOfBirth}</Text>
				</Col>
				<Col span={16}>
					<Text>{dayjs(representative.birthdate).format("YYYY.MM.DD")}</Text>
				</Col>
			</Row>
			<Row gutter={16}>
				<Col span={8}>
					<Text type="secondary">{t.representativeCard.tin}</Text>
				</Col>
				<Col span={16}>
					{representative.taxid ? (
						<>
							<Text>{representative.taxid}</Text>
							{showEditTinButton && (
								<Button
									type="link"
									onClick={onEditTin}
									icon={<EditOutlined />}
									$themedLinkColor={themedLinkColor}
								/>
							)}
						</>
					) : (
						<Button
							type="link"
							onClick={onEditTin}
							style={{ padding: 0, height: "auto" }}
							$themedLinkColor={themedLinkColor}
						>
							{t.representativeCard.addTin}
						</Button>
					)}
				</Col>
			</Row>
			{showSchufa && (
				<>
					<Divider />
					<Row gutter={16}>
						<Col span={8}>
							<Text type="secondary">{t.representativeCard.schufaScore}</Text>
						</Col>
						<Col span={16}>
							<Space>
								<Text>{representative.credit_score}</Text>
								<AntdTag style={{ margin: 0 }}>
									{`${t.representativeCard.lastUpdated}: ${dayjs(
										representative.credit_score_updated_at
									).format("YYYY.MM.DD")}`}
								</AntdTag>
								<Button
									type="text"
									size="small"
									onClick={() => refetchRepresentatives && refetchRepresentatives()}
									icon={<RedoIcon />}
								/>
							</Space>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={8}>
							<Text type="secondary">{t.representativeCard.details}</Text>
						</Col>
						<Col span={16}>
							<Text>{representative.credit_score_info_text}</Text>
						</Col>
					</Row>
				</>
			)}
		</Card>
	);
};

export default RepresentativeCard;
