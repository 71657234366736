import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "src/store/reducers/rootReducer";
import { Layout as AntdLayout, Typography } from "antd";
import styled from "styled-components";

const { Footer: AntdFooter } = AntdLayout;
const { Text, Link } = Typography;

const Footer = styled(AntdFooter)`
	background-color: #fbfbfb;
	text-align: center;
`;

const FooterLink = styled(Link)`
	margin: 4px;
	color: #00000073 !important;
`;

const PageFooter = () => {
	const { t } = useTranslation();
	const { terms, privacy_policy, imprint } =
		useSelector((state: RootState) => state.configState.urlList?.value) || {};

	return (
		<Footer>
			<FooterLink href={terms} target="_blank" rel="noreferrer">
				{t("terms-and-conditions")}
			</FooterLink>
			<Text type="secondary">|</Text>
			<FooterLink href={privacy_policy} target="_blank" rel="noreferrer">
				{t("privacy-policy")}
			</FooterLink>
			<Text type="secondary">|</Text>
			<FooterLink href={imprint} target="_blank" rel="noreferrer">
				{t("imprint")}
			</FooterLink>
		</Footer>
	);
};

export default PageFooter;
