import LanguageDetector from "i18next-browser-languagedetector";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { en, de } from "./Dictionary";

export const resources = {
	en,
	de,
};

export type language = keyof typeof resources;

export const languages = Object.keys(resources);

export const languageNames: Record<language, string> = {
	en: "English",
	de: "Deutsch",
};

const FALLBACK_LNG = "de";

const customLngDetector = {
	name: "teylor-lng-detector",
	lookup() {
		// navigator
		const browserLanguage: string = navigator.language.split("-")[0];
		return browserLanguage && languages.includes(browserLanguage) ? browserLanguage : FALLBACK_LNG;
	},
};

const languageDetectorOptions = {
	order: ["cookie", "teylor-lng-detector"],
	lookupCookie: "i18next",
	caches: ["cookie"],
};

const lngDetector = new LanguageDetector();
lngDetector.addDetector(customLngDetector);

const i18nInitialize = () => {
	void i18n
		.use(lngDetector)
		.use(initReactI18next)
		.init({
			detection: languageDetectorOptions, // This configures the language detector
			fallbackLng: FALLBACK_LNG,
			ns: ["common"],
			defaultNS: "common",
			cleanCode: true,

			debug: false,

			interpolation: {
				escapeValue: false, // Not needed for react, so set to false
			},

			react: {
				useSuspense: false,
			},

			resources,
		});
};

export default i18nInitialize;
