import { countriesDe, countriesEn } from "@teylor-tools/utils/countryList";

export const en = {
	common: {
		back: "Back",
		yes: "Yes",
		no: "No",
		finished: "Finished",
		imprint: "Imprint",
		"in-progress": "In Progress",
		login: "Login",
		next: "Next",
		"privacy-policy": "Privacy Policy",
		change: "Change",
		months: "Months",
		delete: "Delete",
		cancel: "Cancel",
		save: "Save",
		add: "Add",
		edit: "Edit",
		update: "Update",
		headerNeedHelp: "Need help? Reach out",

		application: {
			address: "Address",
			"add-company-bank-information": "Add company bank information",
			"add-bank-information": "Add Bank Information",
			"add-representatives": "Add company representatives",
			"add-tin": "Add company TIN",
			"bank-information": "Bank Information",
			"bank-name": "Bank Name",
			"bank-name-placeholder": "Enter the full name of the Bank",
			change: "Change",
			"company-bank-information": "Company Bank Information",
			"company-information": "Company Information",
			"company-representatives": "Company Representatives",
			"company-tin": "Company TIN",
			"complete-digital-signature": "Complete digital signature",
			"completed-and-signed-on": "Completed and signed on",
			"confirm-email": "Confirm your email address through the email we’ve sent you",
			documents: "Documents",
			"download-agreement": "Download Agreement",
			founded: "Founded",
			"hr-number": "HR number",
			iban: "IBAN",
			"iban-label": "Account IBAN Number",
			"iban-placeholder": "Eg. DE94BARC10201530093459",
			"indicative-monthly-rate": "Indicative Monthly Rate",
			"indicative-monthly-rate-tooltip":
				"This will be finalised once your loan application is reviewed.",
			"interest-rate": "Interest Rate",
			"in-review-alert":
				"This loan application is in review by our staff. We’ll be in touch with more information soon.",
			"legal-form": "Legal form",
			loans: "Loans",
			"loan-amount": "Loan amount",
			"loan-details": "Loan Details",
			"monthly-rate": "Monthly Rate",
			name: "Name",
			"not-completed": "Not Completed",
			"no-information-added": "No information added",
			overview: "Overview",
			"pending-items": "Pending Items",
			"pending-items-subtitle":
				"Please go through the following items so that your loan can be processed.",
			"resend-email": "Re-send Email",
			sign: "Sign",
			signatures: "Signatures",
			"signatures-alert": "Complete the steps below to complete the digital signature process.",
			"signatures-complete-alert":
				"This loan application is in review by our staff. We’ll be in touch with more information soon.",
			"signatures-complete-title": "Signing process complete",
			"signatures-complete-subtitle": "Thank you for completing all the required steps.",
			"signature-download-signed-contract": "Download signed contract",
			"signatures-step-tin-title": "Complete Missing Personal Details",
			"signatures-step-video-title": "Video Identification",
			"signatures-step-video-and-signature-title": "Video Identification & Signature",
			"signatures-step-guarantor-title": "Guarantor Agreement",
			"signatures-step-terms-title": "Terms & Conditions",
			"signatures-step-video-content-1": "Follow these steps to identify yourself",
			"signatures-step-video-and-sign-content-1":
				"Follow these steps to identify yourself and digitally sign ",
			"signatures-step-video-content-2": "your loan contract",
			"signatures-step-video-content-3":
				"\n\n1. Prepare an official ID Document.\n2. Make sure you are using a device with a camera and good internet.\n3. If necessary, turn off your adblocker.\n4. Click the button below to start.",
			"signatures-step-guarantor-alert":
				"Please send a physical copy of the signed agreement to the mailing address on the document.",
			"signatures-step-guarantor-content-1":
				"Follow these steps to sign the guarantor agreement\n\n",
			"signatures-step-guarantor-content-2": "1. ",
			"signatures-step-guarantor-content-3": "Download the agreement",
			"signatures-step-guarantor-content-4": ", then print and sign it.\n",
			"signatures-step-guarantor-content-5":
				"2. Scan and upload the signed agreement by clicking the upload button below.\n3. Send a physical copy of the signed agreement to the mailing address listed on the document. ",
			"signatures-step-terms-1":
				"I grant the payment processing partner of Teylor AG, a SEPA direct debit mandate for recurring payments, authorise the collection of payments from my bank account by direct debit and instruct my credit institution to honour the direct debits. I may request reimbursement of the debited amount within eight weeks, beginning with the debit date. The General Terms of Use of the partner, GC re Mangopay apply.",
			"signatures-step-terms-2":
				"I confirm that I digitally sign this contract and understand that this contract is legally binding",
			"signatures-tin-label": "TIN (Tax ID number or Tax Number)",
			"start-video-identification": "Start Video Identification",
			"start-video-identification-and-sign": "Start Video Identification & Sign Contract",
			step1: "Loan Information",
			step2: "Company Name",
			step3: "Company Details",
			step4: "Account Creation",
			submit: "Submit",
			"signatures-complete-previous-steps": "Please complete the previous points to proceed.",
			"submit-application": "Submit Application",
			"submit-application-tooltip": "Please complete pending items first",
			"submit-signature": "Submit Signature",
			"submit-loan-application": "Submit Loan Application",
			"tax-number": "Tax Number",
			term: "Term",
			"tin-for": "TIN for {{ name }}",
			"tin-placeholder": "Eg. 12345671234",
			"tin-was-updated": "TIN was updated",
			upload: "Upload",
			"uploaded-on": "Uploaded on",
			"upload-signed-agreement": "Upload Signed Agreement",
			"upload-documents": "Upload required documents",
			"verification-email-send": "Verification email was sent",
			submitApplicationConfirm: "Yes, Submit Application",
			noAdjustRepresentatives: "No, Adjust Representatives",
			allRepresentativesAdded: "Have all required company representatives been added?",
			beforeSubmitConfirmSignatory:
				"Before you submit, please make sure that you have added every signatory and every person that owns 25% or more of the company.",
			companyRepresentativesList: "Company representatives added:",
			loanType: "Loan Type",
		},

		authentication: {
			"already-have-account": "Do you already have an account?",
			"click-to-login": "Click here to login",
			"confirm-email-error-title": "Something went wrong.",
			"confirm-email-error-subtitle": "Your email was not confirmed.",
			"confirm-email-success-title": "Your e-mail address has been confirmed.",
			"confirm-email-success-subtitle": "You can now log in and proceed with your application.",
			"go-to-login": "Go to Login",
			"go-to-login-link-1": "Have your password?",
			"go-to-login-link-2": "Go to Login",
			"forgot-password-title": "Forgot your password?",
			"forgot-password-subtitle":
				"Enter your email address and you will receive an email with a link to create a new password.",
			"forgot-password-link-1": "Forgot your password?",
			"forgot-password-link-2": "Click here",
			"new-application-link-1": "Don't have an account yet?",
			"new-application-link-2": "Click here to submit an application",
			"new-password": "New password",
			"new-password-repeat": "Repeat new password",
			password: "Password",
			"password-repeat": "Repeat password",
			"password-reset-email-sent-title": "Password reset e-mail sent",
			"password-reset-email-sent-subtitle":
				"If an account with this email address exists, an email has been sent with a link to reset the password.",
			"password-reset-success-title": "Your password was successfully changed",
			"password-reset-success-subtitle": "You can proceed to login.",
			"reset-password": "Reset password",
			"reset-password-button": "Reset Password",
			"reset-password-subtitle": "Please enter a new password for your account.",
			register: "Register",
			"registration-success-title": "Your password was successfully set",
			"registration-success-subtitle": "You can proceed to login.",
			"set-password": "Set password",
			"set-password-button": "Set Password",
			"set-password-subtitle": "Please set a password for your account.",
			"view-my-loans": "View my loans",
		},

		"company-type": {
			SELF_EMPLOYED: "Self Employed",
			FREIE_BERUFE: "Free Professions",
			GMBH: "GmbH",
			GMBH_CO_KG: "GmbH & Co. KG",
			KGAA: "Kommanditgesellschaft auf Aktien",
			AG: "AG",
			GBR: "Gesellschaft bürgerlichen Rechts",
			OHG: "Offene Handelsgesellschaft",
			PARTG: "Partnerschaftsgesellschaft",
			KG: "Kommanditgesellschaft (KG)",
			UG: "Unternehmergesellschaft (UG)",
			SOLE_PROPRIETORSHIP: "Sole Proprietorship",
			EG: "Eingetragene Genossenschaft",
			EK: "Eingetragener Kaufmann",
		},

		allBanksLoanPurposes: {
			general_liquidity: "Liquidity / General Loan",
			object_financing: "Machinery & Vehicle financing",
			real_estate: "Real estate",
			factoring: "Factoring",
			other: "Other",
		},

		"error-component": {
			"error-code": "Error code: {{ errorCode }}",
			"subtitle-1":
				"An error has occurred while processing your request. This might be a one-off event, so please try your request again.",
			"subtitle-2": "If this problem persists, please contact us and we can help:",
			title: "Something unexpected went wrong.",
		},

		"error-messages": {
			"bank-name-invalid": "Please provide a valid bank name",
			"business-purpose-invalid": "Please provide a business purpose.",
			"city-invalid": "Please provide valid city",
			"company-name-invalid": "Please review your company name and try again.",
			"country-invalid": "Please select country",
			"detailed-loan-purpose-invalid": "Detailed loan purpose must be at least 30 characters.",
			"email-invalid": "Please provide valid e-mail address.",
			"forgot-password-error": "Error updating password",
			"house-number-invalid": "Please provide valid house number",
			"hr-number-invalid": "The HR number needs to be between 3 and 30 characters.",
			"iban-invalid": "Please provide a valid IBAN",
			"company-type-invalid": "Legal form is required",
			"login-error-title": "Something went wrong, please try again.",
			"login-wrong-email-or-password": "Incorrect email or password.",
			"logout-error": "Error while logging out.",
			"no-empty": "Field cannot be empty",
			"password-invalid":
				"Password must be a minimum of 8 characters including at least 1 uppercase, 1 lowercase, 1 number and 1 special character.",
			"password-too-short": "Password must be a minimum of 8 characters.",
			"password-not-complex":
				"Password does not meet the complexity requirements. Passwords must have at least 8 characters including at least 1 uppercase, 1 lowercase, 1 number and 1 special character.",
			"password-repeat-invalid": "Password doesn't match.",
			"registration-error": "Registration failed",
			"post-code-invalid": "Please review your postal code.",
			"something-went-wrong": "Something went wrong.",
			"street-invalid": "Please provide valid street address",
			"tax-number-invalid": "Please provide valid tax number.",
			"terms-and-conditions-invalid":
				"Please accept the terms and conditions and privacy policy to continue.",
			"dashboard-profile-api-error": "Error while getting your profile data.",
			"dashboard-representatives-error": "Error when getting representatives data",
			"dashboard-applications-list-error": "Error getting applications",
			"dashboard-delete-representative-error": "Error deleting a representative",
			"error-try-again": "Error while processing data. Please try again",
			"error-updating-tin": "Error updating TIN",
			"file-upload-fail": "Error when uploading file",
			"file-delete-fail": "Error when deleting file",
			"file-not-available": "File not available",
			"file-upload-in-progress": "File is still uploading",
			"logged-out": "You've been logged out",
			"login-error": "Error while logging in. Please try again.",
			"error-getting-guarantor-agreement": "Error getting guarantor agreement file",
			"invite-token-invalid": "The invitation token is either expired or invalid",
		},
		"error-code": {
			err_auth_email_conflict: "User with this e-mail address already exists",
			err_application_signatory_ident_not_done: "Video identification not complete",
			err_share_percentage_greater_then_100: "Total share ownership exceeds 100%.",
			err_application_signatories_sole_or_atleast_2:
				"No sole signatory or multiple joint signatories specified.",
			full: {
				err_share_percentage_greater_then_100:
					"Total share ownership exceeds 100%. Please check that the correct share ownership has been assigned.",
				err_application_signatories_sole_or_atleast_2:
					"No sole signatory or multiple joint signatories specified. Please make sure information entered is correct.",
			},
		},

		form: {
			"account-creation-title": "Please create an account to see your offer",
			"business-purpose": "What does your company do?",
			"business-purpose-placeholder": "Example: Manufacturing machines",
			city: "City",
			"city-placeholder": "Eg. Berlin",
			"company-details-title": "Please enter your company details",
			"company-name": "Company name",
			"company-name-placeholder": "Example GmbH",
			"company-name-title": "For which company would you like to submit an application?",
			"company-name-not-found-text": "Not found? No problem!",
			"company-name-not-found-link": "Click here to enter the company details manually.",
			"complete-application": "Complete Application",
			country: "Country",
			"country-placeholder": "Choose one",
			"detailed-loan-purpose": "Detailed loan purpose",
			"detailed-loan-purpose-limit": "(min. 30 characters)",
			"detailed-loan-purpose-placeholder": "We need a loan so that we...",
			duration: "Duration",
			email: "E-mail Address",
			"email-placeholder": "E-mail Address",
			"house-number": "House number",
			"house-number-placeholder": "Eg. 103",
			"hr-number": "HR number",
			"hr-number-placeholder": "234502",
			"company-type": "Legal form",
			"company-type-placeholder": "Select Legal form",
			"loan-amount": "Loan amount",
			"login-link-1": "Do you already have an account?",
			"login-link-2": "Click here to login",
			"loan-info-title": "Please choose an amount and duration",
			months: "months",
			optional: "(can be added later)",
			"offer-loan-amount": "Loan\nAmount",
			"offer-loan-duration": "Loan\nDuration",
			"offer-monthly-rate": "Indicative Monthly Rate",
			"offer-rejected-title":
				"Unfortunately, we cannot calculate your loan conditions at this time.",
			"offer-rejected-text-1":
				"Thank you for submitting your loan application. Our automated application review process has shown that we cannot calculate your loan conditions at this time.",
			"offer-rejected-text-2":
				"For more information or to try a new new application, please contact us directly:",
			"offer-rejected-return": "Return to Website",
			"offer-success-title": "We have an indicative offer for you!",
			"offer-success-offer-id": "Offer ID:",
			"offer-success-text-1":
				"The numbers shown above are an estimate based on your credit score. The final offer must still be calculated.",
			"offer-success-text-2":
				"In order to finalise the offer, we require some additional information from you. In the next step, you will be asked to provide us with some additional information and documentation so that our credit team can finalise your offer.",
			password: "Password",
			"password-repeat": "Repeat Password",
			"post-code": "Postcode",
			"post-code-placeholder": "10722",
			street: "Street address",
			"street-placeholder": "Eg. Bahnhofstraße",
			"tax-number": "TIN (Tax ID number or Tax Number)",
			"tax-number-placeholder": "Example: 1234567890",
			"terms-and-conditions":
				"I accept the <1>general terms and conditions</1> and <3>data protection conditions</3> of Teylor AG. I understand that the account I am creating is managed and provided by Teylor.",
			"terms-and-conditions-partner":
				"I accept the <1>terms and conditions</1> and <3>data protection policy</3> of {{displayName}}.",
			"field-required": "Field is required",
			"field-invalid": "Provide valid value",
			"all-fields-required": "All fields are required",
			date: {
				day: "DD",
				month: "MM",
				year: "YYYY",
			},
			"need-more-amount": "Do you need a higher amount?",
			"need-more-duration": "Do you need a longer duration?",
		},

		dashboard: {
			email: "Email",
			phone: "Phone",
			"my-loans": "My applications",
			settings: "Settings",
			"log-out": "Log out",
			"new-loan": "New application",
			"action-required": "Action required",
			view: "View",
			"complete-application": "Complete Application",
			ownership: "Ownership",
			"beneficial-owner": "Beneficial Owner",
			"willing-to-sign": "Willing to Sign",
			"can-sign-alone": "Can Sign Alone",
			address: "Address",
			nationality: "Nationality",
			dob: "Date of Birth",
			tin: "TIN",
			"add-representative": "Add Company Representative",
			"new-representative": "New Representative",
			"edit-representative": "Edit Company Representative",
			"representative-added": "Signatory added",
			"no-loans-title": "There are no loans under your account",
			"no-loans-subtitle": "Apply for a new loan by clicking the button below.",
			"complete-section-alert":
				"Please complete this section to be able to submit your application for further processing.",
			"no-representatives-title": "No Company Representatives added yet.",
			"are-you-sure": "Are you sure?",
			"delete-representative-info":
				"Information for <strong>{{name}}</strong> will be permanently deleted. This cannot be undone.",
			"file-upload-success": "File has been successfully added",
			"file-delete-success": "File has been successfully deleted",
			"file-upload-fail": "Error when uploading file {{name}}",
			"first-name": "First Name",
			"last-name": "Last Name",
			"phone-number": "Phone Number",
			street: "Street",
			"house-number": "House number",
			city: "City",
			"change-year": "Change Year",
			postcode: "Postcode",
			country: "Country",
			"legal-signatory": "Legal Signatory",
			"percentage-ownership": "Percentage Ownership",
			"add-tin": "Add TIN",
			"edit-tin": "Edit TIN",
			"tin-for-person": "TIN (Tax ID number or Tax Number) for <strong>{{name}}</strong>",
			"tin-added-successfully": "TIN added successfully",
			"representative-not-contacted":
				"The representative will not be contacted before the offer is accepted.",
			"drag-drop-below": "Drag & Drop the required documents in the sections below",
			"drop-to-group": "Drop file to upload to this group",
			"annual-statements": "Audited annual statements available up to year:",
			upload: "Upload",
			company_doc_year_end_stmt: "Audited Annual Statements for {{year1}} and {{year2}}",
			company_doc_bwaandsusa: "BWA with SuSa for {{year}}",
			"company_doc_bwaandsusa-2": "BWA with SuSa for {{year1}} and {{year2}}",
			"company_doc_bwaandsusa-info": "No older than 3 months",
			"company_doc_bwaandsusa-info-2": "No older than 3 months for {{year}}",
			"company_doc_bwaandsusa-info-tooltip":
				"The SuSa must contain all accounts including unbooked accounts and deposit values",
			company_doc_debt_ledger: "Current Debt Register (Bankenspiegel)",
			"company_doc_debt_ledger-info": "No older than 14 days",
			"company_doc_debt_ledger-info-tooltip":
				"Should include all existing bank liabilities, including interest, instalment, remaining debt.",
			other: "Other Documents",
			"other-info": "(Optional)",
			"other-info-tooltip":
				"Any other documents that should be included. e.g. Consolidated Financial statements and documents explaining Group Structure for companies that are part of a group.",
			"not-sure-who-add": "Not sure who to add here?",
			"not-sure-who-add-link-text": "Read our FAQs",
			"empty-title": "Who should be added here?",
			"empty-section-1-title": "Legal signatories:",
			"empty-section-1-point-1":
				"People legally authorised to solely or jointly sign contracts on the company’s behalf",
			"empty-section-1-point-2":
				"For our purposes only legal signatories that are going to sign the loan contract are required",
			"empty-section-2-title": "All Ultimate Beneficial Owners (UBO):",
			"empty-section-2-point-1":
				"People who directly or indirectly (through other companies) own at least 25% of the company",
			"empty-section-3-title": "Guarantors:",
			"empty-section-3-point-1": "People who will be providing personal guarantees",
			"empty-section-3-point-2":
				"Guarantors combined should directly or indirectly own more than 50% of the company",
			"switch-year-modal-title": "Are you sure?",
			"switch-year-modal-subtitle":
				"Changing the basis year will delete all previously uploaded documents. Are you sure you want to proceed?",
			"schufa-score": "SCHUFA score",
			details: "Details",
			"last-updated": "Last updated",
		},

		"not-found-page": {
			links: "Here’s a list of some helpful links instead:",
			"link-1": "Apply for a new loan",
			"link-2": "View my loans",
			subtitle: "Error code: 404",
			title: "Sorry, we can’t seem to find this page.",
		},

		settings: {
			"change-password": "Change Password",
			"delete-account": "Delete account",
			"delete-account-subtitle":
				"To delete your account, please contact us on <strong>{{ phone }}</strong> or <strong>{{ email }}</strong> and a member of our team will handle your request.",
			email: "Email",
			"needs-verification": "Needs verification",
			password: "Password",
			"password-change-success": "Password changed successfully",
			"password-old": "Current Password",
			"password-new": "New Password",
			"password-repeat": "Repeat New Password",
			verified: "Verified",
			resendVerificationEmail: "Resend Verification Email",
		},

		"terms-and-conditions": "Terms and Conditions",

		"application-status": {
			closed: "Closed",
			"not-completed": "Not Completed",
			"waiting-for-signatures": "Waiting for Signatures",
			"in-review": "In Review",
			"in-review-after-signature": "In Review",
			"paid-out": "Paid Out",
		},

		countries: countriesEn,

		pageTitle: {
			"new-application": "New Application",
			"application-list": "My Applications",
			"application-overview": "Application Overview",
			settings: "Settings",
			login: "Login",
			forgotPassword: "Forgot Password",
			resetPassword: "Reset Password",
			register: "Register",
			emailConfirmation: "E-mail Confirmation",
			notFound: "Page cannot be found",
		},
	},
};

export const de = {
	common: {
		back: "Zurück",
		yes: "Ja",
		no: "Nein",
		finished: "Abgeschlossen",
		imprint: "Impressum",
		"in-progress": "In Bearbeitung",
		login: "Login",
		next: "Weiter",
		"privacy-policy": "Datenschutzerklärung",
		change: "Ändern",
		months: "Monate",
		delete: "Löschen",
		cancel: "Abbrechen",
		save: "Speichern",
		add: "Hinzufügen",
		edit: "Anpassen",
		update: "Aktualisieren",
		headerNeedHelp: "Benötigen Sie Hilfe?",

		application: {
			address: "Adresse",
			"add-company-bank-information": "Bankdaten des Unternehmens hinzufügen",
			"add-bank-information": "Bankdaten hinzufügen",
			"add-representatives": "Personen hinzufügen",
			"add-tin": "Firmen-TIN hinzufügen",
			"bank-information": "Bank Informationen",
			"bank-name": "Bankname",
			"bank-name-placeholder": "Geben Sie den vollständigen Banknamen an",
			change: "Ändern",
			"company-bank-information": "Bankdaten des Unternehmens",
			"company-information": "Firmeninformationen",
			"company-representatives": "Personen",
			"company-tin": "Firmen-TIN",
			"complete-digital-signature": "Digitale Unterschrift abschließen",
			"completed-and-signed-on": "Ausgefüllt und unterzeichnet am",
			"confirm-email":
				"Bestätigen Sie Ihre E-Mail-Adresse in der E-Mail, die wir Ihnen geschickt haben",
			documents: "Dokumente",
			"download-agreement": "Vereinbarung herunterladen",
			founded: "Gegründet",
			"hr-number": "HR Nummer",
			iban: "IBAN",
			"iban-label": "IBAN Kontonummer",
			"iban-placeholder": "z.B. DE94BARC10201530093459",
			"indicative-monthly-rate": "Indikative monatliche Rate",
			"indicative-monthly-rate-tooltip":
				"Diese wird finalisiert, sobald Ihr Kreditantrag geprüft wurde.",
			"interest-rate": "Zinsrate",
			"in-review-alert":
				"Dieser Kreditantrag wird derzeit von unseren Mitarbeitern geprüft. Wir werden uns in Kürze mit weiteren Informationen melden.",
			"legal-form": "Rechtsform",
			loans: "Kredite",
			"loan-amount": "Kreditbetrag",
			"loan-details": "Kreditinformationen",
			"monthly-rate": "Monatliche Rate",
			name: "Name",
			"not-completed": "Nicht abgeschlossen",
			"no-information-added": "Keine Informationen hinzugefügt",
			overview: "Übersicht",
			"pending-items": "Offene Punkte",
			"pending-items-subtitle":
				"Bitte gehen Sie die folgenden Punkte durch, damit Ihr Kredit bearbeitet werden kann.",
			"resend-email": "E-Mail erneut senden",
			sign: "Unterschreiben",
			signatures: "Unterschriften",
			"signatures-alert":
				"Die folgenden Schritte sind notwendig um Ihren Vertrag digital zu unterzeichnen.",
			"signatures-complete-alert":
				"Dieser Kreditantrag wird derzeit von unseren Mitarbeitern geprüft. Wir werden uns in Kürze mit weiteren Informationen melden.",
			"signatures-complete-title": "Unterschrift abgeschlossen",
			"signatures-complete-subtitle":
				"Vielen Dank, dass Sie alle erforderlichen Schritte abgeschlossen haben.",
			"signature-download-signed-contract": "Unterzeichneten Vertrag herunterladen",
			"signatures-step-tin-title": "Fehlende persönliche Angaben vervollständigen",
			"signatures-step-video-title": "Video-Identifizierung",
			"signatures-step-video-and-signature-title": "Video-Identifizierung & Unterschrift",
			"signatures-step-guarantor-title": "Bürgschaftsvertrag",
			"signatures-step-terms-title": "Allgemeine Geschäftsbedingungen",
			"signatures-step-video-content-1": "Folgen Sie diesen Schritten, um sich zu identifizieren",
			"signatures-step-video-and-sign-content-1":
				"Folgen Sie diesen Schritten, um sich zu identifizieren und digital zu unterschreiben ",
			"signatures-step-video-content-2": "Ihre Kreditvertrag",
			"signatures-step-video-content-3":
				"\n\n1. Halten Sie ein offizielles Ausweisdokument bereit.\n2. Stellen Sie sicher, dass Sie ein Gerät mit einer Kamera und stabiler Internetverbindung verwenden.\n3. Deaktivieren Sie gegebenenfalls Ihren Adblocker.\n4. Klicken Sie auf die Schaltfläche unten, um zu starten.",
			"signatures-step-guarantor-alert":
				"Bitte senden Sie eine physische Kopie der unterzeichneten Vereinbarung an die auf dem Dokument angegebene Postanschrift.",
			"signatures-step-guarantor-content-1": "So unterzeichnen Sie Ihren Bürgschaftsvertrag:\n\n",
			"signatures-step-guarantor-content-2": "1. ",
			"signatures-step-guarantor-content-3": "Vereinbarung herunterladen",
			"signatures-step-guarantor-content-4": ", dann ausdrucken und unterschreiben.\n",
			"signatures-step-guarantor-content-5":
				"2. Scannen Sie die unterzeichnete Vereinbarung ein und laden Sie sie hoch, indem Sie auf die Schaltfläche Hochladen unten klicken.\n3. Senden Sie eine physische Kopie der unterzeichneten Vereinbarung an die auf dem Dokument angegebene Postanschrift. ",
			"signatures-step-terms-1":
				"Ich erteile dem Zahlungsabwicklungspartner der Teylor AG ein SEPA-Lastschriftmandat für wiederkehrende Zahlungen, ermächtige ihn, die Zahlungen von meinem Konto mittels Lastschrift einzuziehen und weise mein Kreditinstitut an, die Lastschriften einzulösen. Ich kann innerhalb von acht Wochen, beginnend mit dem Belastungsdatum, die Erstattung des belasteten Betrages verlangen. Es gelten die Allgemeinen Nutzungsbedingungen des Partners, GC re Mangopay.",
			"signatures-step-terms-2":
				"Ich bestätige, dass ich diesen Vertrag digital unterschreibe und verstehe, dass dieser Vertrag rechtsverbindlich ist.",
			"signatures-tin-label": "TIN (Tax ID Nummer oder Steuernummer)",
			"start-video-identification": "Video-Identifikation starten",
			"start-video-identification-and-sign": "Video-Identifikation starten & Vertrag unterzeichnen",
			step1: "Darlehensinformationen",
			step2: "Name der Firma",
			step3: "Firmendetails",
			step4: "Konto-Erstellung",
			submit: "Einreichen",
			"signatures-complete-previous-steps":
				"Bitte erledigen Sie die vorherigen Punkte, um fortzufahren.",
			"submit-application": "Antrag einreichen",
			"submit-application-tooltip": "Bitte füllen Sie zuerst die offenen Punkte aus",
			"submit-signature": "Unterschrift einreichen",
			"submit-loan-application": "Kreditantrag einreichen",
			"tax-number": "Steuernummer",
			term: "Term",
			"tin-for": "TIN für {{ name }}",
			"tin-placeholder": "Eg. 12345671234",
			"tin-was-updated": "TIN wurde aktualisert",
			upload: "Hochladen",
			"uploaded-on": "Hochgeladen am",
			"upload-signed-agreement": "Unterschriebene Vereinbarung hochladen",
			"upload-documents": "Benötigte Dokumente hochladen",
			"verification-email-send": "Verifizierungs-E-Mail wurde gesendet",
			submitApplicationConfirm: "Ja, den Antrag einreichen",
			noAdjustRepresentatives: "Nein, Personendaten anpassen",
			allRepresentativesAdded: "Sind alle erforderlichen Personen hinzugefügt worden?",
			beforeSubmitConfirmSignatory:
				"Vergewissern Sie sich vor dem Einreichen, dass Sie alle Unterzeichner und alle Personen, die zu 25 % oder mehr an dem Unternehmen beteiligt sind, angegeben haben.",
			beforeSubmitConfirmDetails:
				"Bitte vergewissern Sie sich, dass Sie alle Angaben im Antrag auf Vollständigkeit und Richtigkeit überprüft haben, bevor Sie bestätigen.",
			companyRepresentativesList: "Personen hinzugefügt:",
			loanType: "Kreditart",
		},

		authentication: {
			"already-have-account": "Haben Sie bereits ein Konto?",
			"click-to-login": "Zum Anmelden hier klicken",
			"confirm-email-error-title": "Etwas ist schief gelaufen.",
			"confirm-email-error-subtitle": "Ihre E-Mail wurde nicht bestätigt.",
			"confirm-email-success-title": "Ihre E-Mail Adresse wurde bestätigt.",
			"confirm-email-success-subtitle":
				"Sie können sich nun einloggen und mit Ihrem Antrag fortfahren.",
			"go-to-login": "Zum Login",
			"go-to-login-link-1": "Haben Sie Ihr Passwort?",
			"go-to-login-link-2": "Zum Login",
			"forgot-password-title": "Passwort vergessen?",
			"forgot-password-subtitle":
				"Geben Sie Ihre E-Mail-Adresse ein und Sie erhalten eine E-Mail mit einem Link zur Erstellung eines neuen Passworts.",
			"forgot-password-link-1": "Passwort vergessen?",
			"forgot-password-link-2": "Klicken Sie hier.",
			"new-application-link-1": "Noch kein Nutzerkonto?",
			"new-application-link-2": "Klicken Sie hier um einen Antrag einzureichen.",
			"new-password": "Neues Passwort",
			"new-password-repeat": "Neues Passwort wiederholen",
			password: "Passwort",
			"password-repeat": "Passwort wiederholen",
			"password-reset-email-sent-title": "E-Mail zum Zurücksetzen des Passworts gesendet",
			"password-reset-email-sent-subtitle":
				"Wenn ein Konto mit dieser E-Mail-Adresse existiert, wurde eine E-Mail mit einem Link zum Zurücksetzen des Passworts versandt.",
			"password-reset-success-title": "Ihr Passwort wurde erfolgreich geändert",
			"password-reset-success-subtitle": "Sie können mit der Anmeldung fortfahren.",
			"reset-password": "Passwort zurücksetzen",
			"reset-password-button": "Passwort zurücksetzen",
			"reset-password-subtitle": "Bitte geben Sie ein neues Passwort für Ihr Konto ein.",
			register: "Registrieren",
			"registration-success-title": "Ihr Passwort wurde erfolgreich festgelegt",
			"registration-success-subtitle": "Sie können mit der Anmeldung fortfahren.",
			"set-password": "Passwort festlegen",
			"set-password-button": "Passwort festlegen",
			"set-password-subtitle": "Bitte legen Sie ein Passwort für Ihr Konto fest.",
			"view-my-loans": "Meine Kredite anzeigen",
		},

		"company-type": {
			SELF_EMPLOYED: "Selbstständig",
			FREIE_BERUFE: "Freie Berufe",
			GMBH: "GmbH",
			GMBH_CO_KG: "GmbH & Co. KG",
			KGAA: "Kommanditgesellschaft auf Aktien",
			AG: "AG",
			GBR: "Gesellschaft bürgerlichen Rechts",
			OHG: "Offene Handelsgesellschaft",
			PARTG: "Partnerschaftsgesellschaft",
			KG: "Kommanditgesellschaft (KG)",
			UG: "Unternehmergesellschaft (UG)",
			SOLE_PROPRIETORSHIP: "Einzelfirma",
			EG: "Eingetragene Genossenschaft",
			EK: "Eingetragener Kaufmann",
		},

		allBanksLoanPurposes: {
			general_liquidity: "Liquidität / Allgemeiner Kredit",
			object_financing: "Finanzierung von Maschinen und Fahrzeugen",
			real_estate: "Immobilien",
			factoring: "Factoring",
			other: "Sonstiges",
		},

		"error-component": {
			"error-code": "Fehlercode: {{ errorCode }}",
			"subtitle-1":
				"Bei der Bearbeitung Ihrer Anfrage ist ein Fehler aufgetreten. Dies könnte ein einmaliges Ereignis sein. Bitte versuchen Sie Ihre Anfrage erneut.",
			"subtitle-2":
				"Wenn dieses Problem weiterhin besteht, kontaktieren Sie uns bitte, damit wir Ihnen helfen können:",
			title: "Etwas Unerwartetes ist schief gelaufen.",
		},

		"error-messages": {
			"bank-name-invalid": "Bitte geben Sie einen gültigen Banknamen an",
			"business-purpose-invalid": "Bitte geben Sie einen Geschäftszweck an.",
			"city-invalid": "Bitte gültige Stadt angeben",
			"company-name-invalid": "Bitte überprüfen Sie Ihren Firmennamen und versuchen Sie es erneut.",
			"country-invalid": "Bitte wählen Sie ein Land",
			"detailed-loan-purpose-invalid":
				"Der detaillierte Verwendungszweck muss mindestens 30 Zeichen umfassen.",
			"email-invalid": "Bitte gültige E-Mail angeben",
			"forgot-password-error": "Fehler beim Aktualisieren des Passworts",
			"house-number-invalid": "Bitte gültige Hausnummer angeben",
			"hr-number-invalid": "Die HR-Nummer muss zwischen 3 und 30 Zeichen lang sein.",
			"iban-invalid": "Bitte geben Sie eine gültige IBAN an",
			"company-type-invalid": "Rechtsform ist erforderlich",
			"login-error-title": "Es ist ein Fehler aufgetreten, bitte versuchen Sie es erneut.",
			"login-wrong-email-or-password": "Falsche E-Mail oder falsches Passwort.",
			"logout-error": "Fehler beim Abmelden.",
			"no-empty": "Feld darf nicht leer sein",
			"password-invalid":
				"Das Passwort muss aus mindestens 8 Zeichen bestehen, davon mindestens 1 Großbuchstabe, 1 Kleinbuchstabe, 1 Zahl und 1 Sonderzeichen.",
			"password-too-short": "Das Passwort muss aus mindestens 8 Zeichen bestehen.",
			"password-not-complex":
				"Das Passwort entspricht nicht den Komplexitätsanforderungen. Passwörter müssen aus mindestens 8 Zeichen bestehen, davon mindestens 1 Großbuchstabe, 1 Kleinbuchstabe, 1 Zahl und 1 Sonderzeichen.",
			"password-repeat-invalid": "Die Passwörter stimmen nicht über ein.",
			"registration-error": "Registrierung fehlgeschlagen",
			"post-code-invalid": "Bitte überprüfen Sie Ihre Postleitzahl.",
			"something-went-wrong": "Etwas ist schief gelaufen.",
			"street-invalid": "Bitte geben Sie eine gültige Adresse an",
			"tax-number-invalid": "Bitte gültige Steuernummer angeben.",
			"terms-and-conditions-invalid":
				"Um fortzufahren, akzeptieren Sie bitte die Geschäftsbedingungen und Datenschutzbestimmungen",
			"dashboard-profile-api-error": "Fehler beim Abrufen Ihrer Profildaten.",
			"dashboard-representatives-error": "Fehler beim Abrufen von Personendaten.",
			"dashboard-applications-list-error": "Fehler beim Abrufen von Anträgen.",
			"dashboard-delete-representative-error": "Fehler beim Löschen eines Vertreters.",
			"error-try-again": "Fehler bei der Verarbeitung von Daten. Bitte versuchen Sie es erneut.",
			"error-updating-tin": "Fehler bei der Aktualisierung der TIN.",
			"file-upload-fail": "Fehler beim Hochladen der Datei.",
			"file-delete-fail": "Fehler beim Löschen einer Datei.",
			"file-not-available": "Datei nicht verfügbar.",
			"file-upload-in-progress": "Datei wird noch hochgeladen.",
			"logged-out": "Sie wurden abgemeldet",
			"login-error": "Fehler beim Einloggen. Bitte versuchen Sie es erneut.",
			"error-getting-guarantor-agreement": "Fehler beim Abrufen des Bürgschaftsvertrages",
			"invite-token-invalid": "Das Einladungstoken ist entweder abgelaufen oder ungültig",
		},
		"error-code": {
			err_auth_email_conflict: "Benutzer mit dieser E-Mail Adresse existiert bereits.",
			err_application_signatory_ident_not_done: "Videoidentifikation nicht vollständig.",
			err_share_percentage_greater_then_100: "Der Gesamtanteilsbesitz übersteigt 100%.",
			err_application_signatories_sole_or_atleast_2:
				"Kein Einzelunterzeichner oder mehrere Mitunterzeichner angegeben.",
			full: {
				err_share_percentage_greater_then_100:
					"Der Gesamtanteilsbesitz übersteigt 100%. Bitte prüfen Sie, ob jeweils der korrekte Anteilsbesitz zugewiesen wurde.",
				err_application_signatories_sole_or_atleast_2:
					"Kein Einzelunterzeichner oder mehrere Mitunterzeichner angegeben. Bitte stellen Sie sicher, dass die eingegebenen Informationen korrekt sind.",
			},
		},

		form: {
			"account-creation-title": "Bitte erstellen Sie ein Konto, um Ihr Angebot zu sehen.",
			"business-purpose": "Was macht Ihr Unternehmen?",
			"business-purpose-placeholder": "Beispiel: Herstellung von Maschinen",
			city: "Stadt",
			"city-placeholder": "z.B. Berlin",
			"company-details-title": "Bitte geben Sie Ihre Firmendaten ein",
			"company-name": "Name des Unternehmens",
			"company-name-placeholder": "Muster GmbH",
			"company-name-title": "Für welches Unternehmen möchten Sie einen Antrag stellen?",
			"company-name-not-found-text": "Nicht gefunden? Kein Problem!",
			"company-name-not-found-link":
				"Klicken Sie hier, um die Unternehmensdaten manuell einzugeben.",
			"complete-application": "Antrag abschliessen",
			country: "Land",
			"country-placeholder": "Bitte auswählen",
			"detailed-loan-purpose": "Detaillierter Darlehenszweck",
			"detailed-loan-purpose-limit": "(mind. 30 Zeichen)",
			"detailed-loan-purpose-placeholder": "Wir brauchen einen Kredit, damit wir...",
			duration: "Laufzeit",
			email: "E-mail Adresse",
			"email-placeholder": "E-mail Adresse",
			"house-number": "Hausnummer",
			"house-number-placeholder": "z.B. 103",
			"hr-number": "HR Nummer",
			"hr-number-placeholder": "234502",
			"company-type": "Rechtsform",
			"company-type-placeholder": "Rechtsform wählen",
			"loan-amount": "Darlehensbetrag",
			"login-link-1": "Haben Sie bereits ein Konto?",
			"login-link-2": "Zum Anmelden hier klicken",
			"loan-info-title": "Bitte wählen Sie einen Betrag und eine Dauer aus",
			months: "Monate",
			optional: "(kann später hinzugefügt werden)",
			"offer-loan-amount": "Kreditbetrag",
			"offer-loan-duration": "Laufzeit",
			"offer-monthly-rate": "Indikative Monatliche Rate",
			"offer-rejected-title":
				"Leider können wir Ihre Darlehenskonditionen zum jetzigen Zeitpunkt nicht berechnen.",
			"offer-rejected-text-1":
				"Vielen Dank, dass Sie Ihren Kreditantrag eingereicht haben. Unsere automatisierte Antragsprüfung hat ergeben, dass wir Ihre Darlehenskonditionen zum jetzigen Zeitpunkt nicht berechnen können.",
			"offer-rejected-text-2":
				"Für weitere Informationen oder um einen weiteren Antrag einzureichen, wenden Sie sich bitte direkt an uns:",
			"offer-rejected-return": "Zurück zur Website",
			"offer-success-title": "Wir haben ein unverbindliches Angebot für Sie!",
			"offer-success-offer-id": "Angebots-ID:",
			"offer-success-text-1":
				"Die oben genannten Zahlen sind eine Schätzung auf der Grundlage Ihrer Kreditwürdigkeit. Das endgültige Angebot muss noch berechnet werden.",
			"offer-success-text-2":
				"Um das Angebot finalisieren zu können, benötigen wir einige zusätzliche Informationen von Ihnen. Im nächsten Schritt werden Sie gebeten, uns einige zusätzliche Informationen und Unterlagen zur Verfügung zu stellen, damit unser Kreditteam Ihr Angebot finalisieren kann.",
			password: "Passwort",
			"password-repeat": "Passwort wiederholen",
			"post-code": "Postleitzahl",
			"post-code-placeholder": "10722",
			street: "Straße",
			"street-placeholder": "Eg. Bahnhofstraße",
			"tax-number": "TIN (Tax ID Nummer or Steuernummer)",
			"tax-number-placeholder": "z.B. 1234567890",
			"terms-and-conditions":
				"Ich akzeptiere die <1>Allgemeinen Geschäftsbedingungen</1> und die <3>Datenschutzerklärungen</3> der Teylor AG. Mir ist bewusst, dass mein Konto von Teylor geführt und verwaltet wird.",
			"terms-and-conditions-partner":
				"Ich akzeptiere die <1>Allgemeinen Geschäftsbedigungen</1> und <3>Datenschutzerklärung</3> von {{displayName}}.",
			"field-required": "Feld ist erforderlich",
			"field-invalid": "Geben Sie einen gültigen Wert an",
			"all-fields-required": "Alle Felder sind erforderlich",
			date: {
				day: "TT",
				month: "MM",
				year: "JJJJ",
			},
			"need-more-amount": "Benötigen Sie einen höheren Betrag?",
			"need-more-duration": "Benötigen Sie eine längere Laufzeit?",
		},

		dashboard: {
			email: "Email",
			phone: "Phone",
			"my-loans": "Meine Anträge",
			settings: "Einstellungen",
			"log-out": "Ausloggen",
			"new-loan": "Neuen Antrag",
			"action-required": "Aktion erforderlich",
			view: "Einsehen",
			"complete-application": "Antrag abschließen",
			ownership: "Eigentümerschaft",
			"beneficial-owner": "Wirtschaftlich Berechtigter",
			"willing-to-sign": "Unterschriftswillig",
			"can-sign-alone": "Darf alleine unterschreiben",
			address: "Adresse",
			nationality: "Nationalität",
			dob: "Geburtsdatum",
			tin: "TIN",
			"add-representative": "Person hinzufügen",
			"new-representative": "Neue Person",
			"edit-representative": "Personendaten anpassen",
			"representative-added": "Person hinzugefügt",
			"no-loans-title": "Es sind keine Anträge mit Ihrem Konto verbunden.",
			"no-loans-subtitle":
				"Beantragen Sie einen neuen Kredit, indem Sie auf die Schaltfläche unten klicken.",
			"complete-section-alert":
				"Bitte füllen Sie diesen Abschnitt aus, um Ihren Antrag zur weiteren Bearbeitung einreichen zu können.",
			"no-representatives-title": "Noch keine Personen hinzugefügt.",
			"are-you-sure": "Sind Sie sicher?",
			"delete-representative-info":
				"Die Informationen für <strong>{{name}}</strong> werden dauerhaft gelöscht. Dies kann nicht rückgängig gemacht werden.",
			"file-upload-success": "Datei wurde erfolgreich hinzugefügt",
			"file-delete-success": "Datei wurde erfolgreich gelöscht",
			"file-upload-fail": "Fehler beim Hochladen der Datei {{name}}",
			"first-name": "Vorname",
			"last-name": "Nachname",
			"phone-number": "Telefonnummer",
			street: "Straße",
			"house-number": "Hausnummer",
			city: "Stadt",
			"change-year": "Jahr ändern",
			postcode: "Postleitzahl",
			country: "Land",
			"legal-signatory": "Unterschriftsberechtigter",
			"percentage-ownership": "Prozent der Anteile",
			"add-tin": "TIN hinzufügen",
			"edit-tin": "Tin ändern",
			"tin-for-person": "TIN (Tax ID Nummer oder Steuernummer) für <strong>{{ name }}</strong>",
			"tin-added-successfully": "TIN erfolgreich hinzugefügt",
			"representative-not-contacted":
				"Die Person wird nicht kontaktiert bis das Angbote akzeptiert wurde.",
			"drag-drop-below":
				"Ziehen Sie die erforderlichen Dokumente per Drag & Drop in die folgenden Abschnitte",
			"drop-to-group": "Datei zum Hochladen in diese Gruppe ablegen",
			"annual-statements": "Geprüfte Jahresabschlüsse bis zum Jahr verfügbar:",
			upload: "Hochladen",
			company_doc_year_end_stmt: "Geprüfte Jahresabschlüsse für {{year1}} und {{year2}}",
			company_doc_bwaandsusa: "BWA mit SuSa für {{year}}",
			"company_doc_bwaandsusa-2": "BWA mit SuSa für {{year1}} und {{year2}}",
			"company_doc_bwaandsusa-info": "Nicht älter als 3 Monate",
			"company_doc_bwaandsusa-info-2": "Nicht älter als 3 Monate für {{year}}",
			"company_doc_bwaandsusa-info-tooltip":
				"Die SuSa muss alle Konten einschließlich der ungebuchten Konten und Depositkonten enthalten",
			company_doc_debt_ledger: "Bankenspiegel",
			"company_doc_debt_ledger-info": "Nicht älter als 14 Tage",
			"company_doc_debt_ledger-info-tooltip":
				"Sollte alle bestehenden Bankverbindlichkeiten enthalten, einschließlich Zinsen, Ratenzahlung und Restschuld.",
			other: "Andere Dokumente",
			"other-info": "(Optional)",
			"other-info-tooltip":
				"Alle anderen Dokumente, die beigefügt werden sollten, z. B. konsolidierte Abschlüsse und Dokumente zur Erläuterung der Konzernstruktur für Unternehmen, die Teil eines Konzerns sind.",
			"not-sure-who-add": "Sie wissen nicht, wen Sie hier hinzufügen sollen?",
			"not-sure-who-add-link-text": "Lesen Sie unsere FAQs",
			"empty-title": "Wer sollte hier hinzugefügt werden?",
			"empty-section-1-title": "Unterschriftsberechtigte",
			"empty-section-1-point-1":
				"Personen, die rechtlich befugt sind, Verträge im Namen des Unternehmens allein oder gemeinsam zu unterzeichnen",
			"empty-section-1-point-2":
				"Für unsere Zwecke sind nur die gesetzlichen Unterzeichner erforderlich, die den Darlehensvertrag unterzeichnen werden.",
			"empty-section-2-title": "Alle wirtschaftlich Berechtigten (UBO):",
			"empty-section-2-point-1":
				"Personen, die direkt oder indirekt (über andere Unternehmen) mindestens 25 % des Unternehmens besitzen",
			"empty-section-3-title": "Bürgen:",
			"empty-section-3-point-1": "Personen, die persönliche Bürgschaften abgeben werden.",
			"empty-section-3-point-2":
				"Die Bürgen sollten zusammen direkt oder indirekt mehr als 50% des Unternehmens besitzen",
			"switch-year-modal-title": "Sind Sie sicher?",
			"switch-year-modal-subtitle":
				"Wenn Sie den Jahrgang ändern, werden alle hochgeladenen Dokumente gelöscht. Sind Sie sicher dass Sie fortfahren möchten?",
			"schufa-score": "SCHUFA score",
			details: "Einzelheiten",
			"last-updated": "Zuletzt aktualisiert",
		},

		"not-found-page": {
			links: "Hier finden Sie stattdessen eine Liste mit hilfreichen Links:",
			"link-1": "Beantragen Sie einen neuen Kredit",
			"link-2": "Meine Kredite anzeigen",
			subtitle: "Fehlercode: 404",
			title: "Es tut uns leid, wir können diese Seite nicht finden.",
		},

		settings: {
			"change-password": "Passwort ändern",
			"delete-account": "Konto löschen",
			"delete-account-subtitle":
				"Um Ihr Konto zu löschen, kontaktieren Sie und bitte unter <strong>{{ phone }}</strong> oder <strong>{{ email }}</strong> und ein Mitglied unseres Teams wird Ihre Anfrage bearbeiten.",
			email: "Email",
			"needs-verification": "Verifizierung erforderlich",
			password: "Passwort",
			"password-change-success": "Passwort erfolgreich geändert",
			"password-old": "Aktuelles Passwort",
			"password-new": "Neues Passwort",
			"password-repeat": "Neues Passwort wiederholen",
			verified: "Verifiziert",
			resendVerificationEmail: "Bestätigungsmail erneut senden",
		},

		"terms-and-conditions": "Allgemeine Geschäftsbedingungen",

		"application-status": {
			closed: "Gschlossen",
			"not-completed": "Nicht abgeschlossen",
			"waiting-for-signatures": "Warten auf Unterschriften",
			"in-review": "In Prüfung",
			"in-review-after-signature": "In Prüfung",
			"paid-out": "Ausgezahlt",
		},

		countries: countriesDe,

		pageTitle: {
			"new-application": "Neuer Antrag",
			"application-list": "Meine Anträge",
			"application-overview": "Antrag Übersicht",
			settings: "Einstellungen",
			login: "Login",
			forgotPassword: "Passwort vergessen",
			resetPassword: "Passwort zurücksetzen",
			register: "Registrieren",
			emailConfirmation: "E-mail Bestätigung",
			notFound: "Seite nicht gefunden",
		},
	},
};
