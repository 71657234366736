import { Steps as AntdSteps } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const StepsWrapper = styled.div`
	padding: 24px 0;
	background-color: #ffffff;
	width: 100%;
	margin-bottom: 38px;
`;

const Steps = styled(AntdSteps)`
	width: 100%;
	max-width: var(--teylor-header-width);
	margin: 0 auto;
	padding: 0 50px;
`;

const Step = styled(AntdSteps.Step)`
	&.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon > .ant-steps-icon {
		color: ${({ theme }) => theme.color_cta_text};
	}
`;

interface Props {
	stepIndex: number;
}

const ApplicationSteps = ({ stepIndex }: Props) => {
	const { t } = useTranslation();

	return (
		<StepsWrapper>
			<Steps current={stepIndex} size="small">
				<Step title={t("application.step1")} />
				<Step title={t("application.step2")} />
				<Step title={t("application.step3")} />
				<Step title={t("application.step4")} />
			</Steps>
		</StepsWrapper>
	);
};

export default ApplicationSteps;
