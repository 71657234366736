import { createContext, useContext, useState, Dispatch } from "react";
import { Outlet } from "react-router-dom";

import { SelectedCompany } from "@ui/form/form-items/company-select-form-item/CompanySelectFormItem";

const LOAN_DEFAULT_DURATION = 48;
const LOAN_DEFAULT_AMOUNT = 100000;

export enum CompanyTypes {
	GMBH = "GMBH",
	GMBH_CO_KG = "GMBH_CO_KG",
	EK = "EK",
	AG = "AG",
	SELF_EMPLOYED = "SELF_EMPLOYED",
	FREIE_BERUFE = "FREIE_BERUFE",
	KGAA = "KGAA",
	GBR = "GBR",
	OHG = "OHG",
	PARTG = "PARTG",
	KG = "KG",
	UG = "UG",
	SOLE_PROPRIETORSHIP = "SOLE_PROPRIETORSHIP",
	EG = "EG",
}

export interface LoanDetails {
	loanSize: number;
	loanDuration: number;
}

export type CompanyDetails = {
	companyType?: CompanyTypes;
	hrNumber: string;
	businessPurpose: string;
	allBanksLoanPurpose: string;
	purposeDescription: string;
	taxIdNumber: string;
} & SelectedCompany;

export type ApplicationFormState = {
	companyName: string;
} & LoanDetails &
	CompanyDetails;

interface ApplicationForm {
	applicationForm: ApplicationFormState;
	setApplicationForm: Dispatch<React.SetStateAction<ApplicationFormState>>;
	isSelectedCompany: boolean;
	setIsSelectedCompany: Dispatch<React.SetStateAction<boolean>>;
}

const ApplicationFormContext = createContext<ApplicationForm | undefined>(undefined);

export const emptyApplication: ApplicationFormState = {
	loanSize: LOAN_DEFAULT_AMOUNT,
	loanDuration: LOAN_DEFAULT_DURATION,
	companyName: "",
	companyType: undefined,
	companyPostCode: "",
	hrNumber: "",
	businessPurpose: "",
	allBanksLoanPurpose: "",
	purposeDescription: "",
	taxIdNumber: "",
	street: "",
	houseNumber: "",
	companyLocation: "",
	companyCountry: "",
};

export const ApplicationFormProvider = () => {
	const [applicationForm, setApplicationForm] = useState(emptyApplication);
	const [isSelectedCompany, setIsSelectedCompany] = useState(false);

	return (
		<ApplicationFormContext.Provider
			value={{
				applicationForm,
				setApplicationForm,
				isSelectedCompany,
				setIsSelectedCompany,
			}}
		>
			<Outlet />
		</ApplicationFormContext.Provider>
	);
};

export const useApplicationForm = () => {
	const context = useContext(ApplicationFormContext);
	if (context === undefined) {
		throw new Error("useApplicationForm must be used within ApplicationFormProvider");
	}

	return context;
};
