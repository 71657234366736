import { Fragment, useCallback, useEffect, useMemo, useReducer, useState } from "react";

import {
	Col,
	Divider,
	message,
	Row,
	Select,
	Space,
	Spin,
	Tooltip,
	Typography,
	Upload,
	Modal,
} from "antd";
import {
	CloudUploadOutlined,
	FileAddOutlined,
	FileTextOutlined,
	InfoCircleOutlined,
	ExclamationCircleOutlined,
} from "@ant-design/icons";
import styled from "styled-components";
import { UploadRequestOption } from "rc-upload/lib/interface";
import { UploadFile } from "antd/es/upload/interface";
import { RcFile } from "antd/lib/upload";

import {
	ApplicationStatuses,
	Document,
	DocumentCategory as Category,
	DocumentsResponse,
	DocumentUrlResponse,
	UploadDocumentPayload,
	UploadDocumentResponse,
} from "@teylor-tools/Api";
import { Axios, ErrorResponse } from "src/utils/Axios";
import ThemedButton from "src/components/themed-ui/ThemedButton";
import {
	ActionType,
	documentsUploadReducer,
} from "src/pages/dashboard/components/DocumentsUpload/DocumentsUploadReducer";
import { useTranslation } from "react-i18next";

const { Text, Title } = Typography;
const { Option } = Select;

const SpinWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-grow: 1;
	margin-top: calc(50vh - 200px);
`;

const Wrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

const UploadRow = styled.div`
	margin: 10px 0;
	width: 100%;
`;

const Dragger = styled(Upload.Dragger)`
	padding: 4px 18px;
	background: #fff !important;
`;

const IconFileAdd = styled(FileAddOutlined)`
	font-size: 18px;
	color: var(--ant-primary-color);
`;

const IconFileText = styled(FileTextOutlined)`
	opacity: 0.35;
	font-size: 18px;
`;

const IconInfo = styled(InfoCircleOutlined)`
	opacity: 0.5;
	font-size: 12px;
`;

const ExclamationCircleOutlinedIcon = styled(ExclamationCircleOutlined)`
	font-size: 24px;
	margin-top: 2px;
	color: var(--ant-error-color);
`;

const categories = [
	Category.CompanyDocYearEndStmt,
	Category.CompanyDocBwaandsusa,
	Category.CompanyDocDebtLedger,
	Category.Other,
] as const;

type DisplayedCategory = typeof categories[number];

export type Documents = {
	[key in DisplayedCategory]: UploadFile[];
};

interface DocumentsBlock {
	category: DisplayedCategory;
	categoryDetails?: string;
	categoryDetails2?: string;
	categoryDetailsTooltip?: string;
	isRequired: boolean;
}

const currentYear = new Date().getFullYear();
const previousYear = currentYear - 1;
const twoYearsBack = currentYear - 2;

interface Props {
	applicationId: string;
	applicationStatus: ApplicationStatuses;
	getPendingItems: () => void;
}

const DocumentsUpload = ({ applicationId, applicationStatus, getPendingItems }: Props) => {
	const [documents, dispatch] = useReducer(documentsUploadReducer, {} as Documents);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [selectedYear, setSelectedYear] = useState(previousYear);
	const [openSwitchYearModal, setOpenSwitchYearModal] = useState(false);
	const { t } = useTranslation();

	const API_URL = useMemo(() => `/user/applications/${applicationId}/documents`, [applicationId]);

	const isEditable = applicationStatus === ApplicationStatuses.Open;

	const documentsBlocks: DocumentsBlock[] = useMemo(
		() => [
			{
				category: Category.CompanyDocYearEndStmt,
				isRequired: true,
			},
			{
				category: Category.CompanyDocBwaandsusa,
				categoryDetails: t("dashboard.company_doc_bwaandsusa-info"),
				categoryDetails2: t("dashboard.company_doc_bwaandsusa-info-2", {
					year: currentYear,
				}),
				categoryDetailsTooltip: t("dashboard.company_doc_bwaandsusa-info-tooltip"),
				isRequired: true,
			},
			{
				category: Category.CompanyDocDebtLedger,
				categoryDetails: t("dashboard.company_doc_debt_ledger-info"),
				categoryDetailsTooltip: t("dashboard.company_doc_debt_ledger-info-tooltip"),
				isRequired: true,
			},
			{
				category: Category.Other,
				categoryDetails: t("dashboard.other-info"),
				categoryDetailsTooltip: t("dashboard.other-info-tooltip"),
				isRequired: false,
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const assignDocsToBlocks = useCallback((docs: Document[]) => {
		const newDocs = categories.reduce<Documents>((acc, category) => {
			const categoryDocs = docs.filter((doc) => doc.document_category === category);
			acc[category] = categoryDocs.map(
				(doc) =>
					({
						uid: doc.document_id,
						status: "success",
						name: doc.document_name,
					} as UploadFile)
			);
			return acc;
		}, {} as Documents);

		dispatch({ type: ActionType.SET_ALL_DOCUMENTS, newDocs });
	}, []);

	useEffect(() => {
		setIsLoading(true);

		Axios.get<DocumentsResponse>(API_URL)
			.then(({ data }) => assignDocsToBlocks(data.result || []))
			.catch((err: ErrorResponse) => {
				void Axios.error(err, t("error-messages.something-went-wrong"));
				assignDocsToBlocks([]);
			})
			.finally(() => setIsLoading(false));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleUpload = async (options: UploadRequestOption, category: Category) => {
		const { file, onSuccess, onError } = options;

		let documentId; // will be retrieved from getUploadInfo()
		const documentRandomId = `id-${Math.random()}`; // is needed to set file state before we retrieve an id
		const documentName = (file as RcFile).name;

		dispatch({
			type: ActionType.ADD_DOCUMENT,
			payload: {
				newDoc: {
					uid: documentRandomId,
					originFileObj: file,
					name: documentName,
					status: "uploading",
				} as UploadFile,
				category,
			},
		});

		try {
			const uploadInfo: UploadDocumentResponse = await getUploadInfo(documentName, category);
			documentId = uploadInfo.document_id;
			dispatch({
				type: ActionType.UPDATE_DOCUMENT_ID,
				payload: { category, oldId: documentRandomId, newId: documentId },
			});

			await uploadFile(uploadInfo.upload_url, file as RcFile);

			await updateFileStatus(uploadInfo.document_id, true);

			void message.success(t("dashboard.file-upload-success"));
			dispatch({
				type: ActionType.UPDATE_DOCUMENT_STATUS,
				payload: {
					category,
					docId: uploadInfo.document_id,
					newStatus: "success",
				},
			});

			getPendingItems();

			onSuccess && onSuccess("Success");
		} catch (err) {
			void Axios.error(err as ErrorResponse, t("error-messages.file-upload-fail"));
			onError && onError({} as Error);

			if (documentId) {
				dispatch({
					type: ActionType.UPDATE_DOCUMENT_STATUS,
					payload: {
						category,
						docId: documentId,
						newStatus: "error",
					},
				});
				await updateFileStatus(documentId, false);
			} else {
				await updateFileStatus(documentRandomId, false);
			}
		}
	};

	const getUploadInfo = async (
		fileName: string,
		category: Category
	): Promise<UploadDocumentResponse> => {
		const res = await Axios.post<UploadDocumentPayload, UploadDocumentResponse>(API_URL, {
			file_name: fileName,
			document_category: category,
		});
		return res.data;
	};

	const uploadFile = async (url: string, file: RcFile) => {
		await Axios.put(url, file, {
			headers: {
				"Content-Type": file.type,
				"x-ms-blob-type": "BlockBlob",
			},
		});
	};

	const updateFileStatus = async (documentId: string, isSuccessful: boolean) => {
		await Axios.patch(`${API_URL}/${documentId}/upload_success`, {
			is_upload_successful: isSuccessful,
		});
	};

	const handleRemove = (file: UploadFile, category: Category, showMsg = true) => {
		void Axios.delete(`${API_URL}/${file.uid}`)
			.then(() => {
				dispatch({
					type: ActionType.REMOVE_DOCUMENT,
					payload: { id: file.uid, category },
				});

				getPendingItems();

				if (showMsg) void message.success(t("dashboard.file-delete-success"));
			})
			.catch((err: ErrorResponse) => {
				if (showMsg) void Axios.error(err, t("error-messages.file-delete-fail"));
			});
	};

	const handlePreview = (file: UploadFile) => {
		switch (file.status) {
			case "success":
				void Axios.get<DocumentUrlResponse>(`${API_URL}/${file.uid}/download_url`)
					.then(({ data }) => window.open(data.download_url))
					.catch(
						(err) => void Axios.error(err as ErrorResponse, t("error-messages.error-try-again"))
					);
				break;
			case "uploading":
				void message.error(t("error-messages.file-upload-in-progress"));
				break;
			default:
				void message.error(t("error-messages.file-not-available"));
		}
	};

	const renderBlockTitle = (block: DocumentsBlock) => {
		const { category } = block;
		const requiredMark = block.isRequired ? <Text type="danger">*</Text> : <></>;
		let title;

		switch (category) {
			case Category.CompanyDocYearEndStmt:
				title = t(`dashboard.${category}`, {
					year1: selectedYear - 1,
					year2: selectedYear,
				});
				break;
			case Category.CompanyDocBwaandsusa:
				if (selectedYear === previousYear) {
					title = t(`dashboard.${category}`, { year: currentYear });
				} else {
					title = t(`dashboard.${category}-2`, {
						year1: previousYear,
						year2: currentYear,
					});
				}
				break;
			default:
				title = t(`dashboard.${category}`);
		}

		return (
			<>
				{requiredMark} {title}
			</>
		);
	};

	const renderInfo = (block: DocumentsBlock) => {
		if (block.category === Category.CompanyDocBwaandsusa && selectedYear === twoYearsBack) {
			return block.categoryDetails2;
		} else {
			return block.categoryDetails;
		}
	};

	const handleYearChange = (year: number) => {
		setSelectedYear(year);

		Object.keys(documents).forEach((category: string) => {
			const files: UploadFile[] = documents[category];
			files.forEach((file) => handleRemove(file, category as DisplayedCategory, false));
		});
	};

	if (isLoading) {
		return (
			<SpinWrapper>
				<Spin />
			</SpinWrapper>
		);
	}

	return (
		<>
			<Wrapper>
				{isEditable && (
					<div style={{ marginBottom: 32, textAlign: "center" }}>
						<IconFileAdd style={{ marginRight: 8 }} />
						<Text type="secondary">{t("dashboard.drag-drop-below")}</Text>
					</div>
				)}

				<div>
					<Text strong>{t("dashboard.annual-statements")}</Text>
					<Select
						style={{ width: 80, marginLeft: 8 }}
						value={selectedYear}
						onChange={(v) => {
							const categories = Object.keys(documents) as DisplayedCategory[];
							const hasUploadedDocuments = categories.some(
								(category) => !!documents[category]?.length
							);
							if (hasUploadedDocuments) {
								setOpenSwitchYearModal(true);
							} else {
								handleYearChange(v);
							}
						}}
						disabled={!isEditable}
					>
						<Option key={previousYear} value={previousYear}>
							{previousYear}
						</Option>
						<Option key={twoYearsBack} value={twoYearsBack}>
							{twoYearsBack}
						</Option>
					</Select>
				</div>

				{documentsBlocks.map((block, idx) => (
					<Fragment key={block.category}>
						{idx === documentsBlocks.length - 1 && <Divider />}
						<UploadRow>
							<Dragger
								fileList={documents[block.category]}
								customRequest={(options) => {
									void handleUpload(options, block.category);
								}}
								onRemove={(file) => handleRemove(file, block.category)}
								onPreview={handlePreview}
								disabled={!isEditable}
							>
								<Row gutter={12} align="middle">
									<Col>
										<IconFileText />
									</Col>
									<Col>
										{isEditable ? (
											<Text strong>{renderBlockTitle(block)}</Text>
										) : (
											<Text strong type="secondary">
												{renderBlockTitle(block)}
											</Text>
										)}
									</Col>
									<Col>
										<Space>
											<Typography.Text type="secondary">{renderInfo(block)}</Typography.Text>
											{block.categoryDetailsTooltip && (
												<Tooltip placement="top" title={block.categoryDetailsTooltip}>
													<IconInfo />
												</Tooltip>
											)}
										</Space>
									</Col>
									<Col flex="auto"></Col>
									<Col>
										<ThemedButton
											type={`${block.isRequired ? "primary" : "ghost"}`}
											icon={<CloudUploadOutlined />}
											disabled={!isEditable}
										>
											{t("dashboard.upload")}
										</ThemedButton>
									</Col>
								</Row>
							</Dragger>
						</UploadRow>
					</Fragment>
				))}
			</Wrapper>
			{openSwitchYearModal && (
				<Modal
					visible={openSwitchYearModal}
					onCancel={() => setOpenSwitchYearModal(false)}
					cancelText={t("cancel")}
					okText={t("dashboard.change-year")}
					okButtonProps={{ danger: true }}
					onOk={() => {
						handleYearChange(selectedYear === previousYear ? twoYearsBack : previousYear);
						setOpenSwitchYearModal(false);
					}}
				>
					<Space align="start">
						<ExclamationCircleOutlinedIcon />
						<div>
							<Title level={4}>{t("dashboard.switch-year-modal-title")}</Title>
							<Text>{t("dashboard.switch-year-modal-subtitle")}</Text>
						</div>
					</Space>
				</Modal>
			)}
		</>
	);
};

export default DocumentsUpload;
