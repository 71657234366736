import { ChangeEvent, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Button, Card, Checkbox, Col, Form, message, Row, Space, Typography } from "antd";
import {
	CheckCircleFilled,
	CheckCircleOutlined,
	CloseCircleOutlined,
	CloseOutlined,
	DownloadOutlined,
	FilePdfOutlined,
	UploadOutlined,
	VideoCameraOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";

import ThemedButton from "src/components/themed-ui/ThemedButton";
import ThemedExternalLink from "src/components/themed-ui/ThemedExternalLink";
import TinFormItem, { parseTin } from "@ui/form/form-items/tin-form-item/TinFormItem";
import IdNowLogo from "src/assets/idnow.png";
import {
	Document,
	DocumentCategory,
	DocumentsResponse,
	DocumentUrlResponse,
	LoanContract,
	PendingActionType,
	RelatedEntityKind,
	SignatoryWithId,
	StatusPendingAction,
	UploadDocumentPayload,
	UploadDocumentResponse,
	User,
	UserApplication,
} from "@teylor-tools/Api";
import { Axios, ErrorResponse } from "src/utils/Axios";
import { yearMonthDay } from "@teylor-tools/utils/dateFormats";
import { getStatus, Status } from "../utils/status";
import LoanDetails from "./LoanDetails";
import Alert from "src/components/Alert";

const { Text, Title } = Typography;

const TermsWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const IdentificationWrapper = styled.div`
	display: flex;
	justify-content: space-between;
`;

const TitleWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const CheckCircleIcon = styled(CheckCircleFilled)`
	color: var(--ant-success-color);
	font-size: 21px;
	margin-right: 8px;
`;

const CheckCirclOutlinedIcon = styled(CheckCircleOutlined)`
	color: var(--ant-success-color);
	font-size: 56px;
	margin-top: 8px;
	margin-bottom: 24px;
`;

const SuccessCardWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	margin: 40px 0;
`;

const FilePdfIcon = styled(FilePdfOutlined)`
	color: var(--ant-primary-color);
`;

const GuarantorAgreementFileWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(4, auto);
	align-items: center;
	gap: 8px;
`;

const InputUpload = styled.input`
	display: none;
`;

enum Step {
	TIN,
	VIDEO,
	GUARANTOR_AGREEMENT,
	TC,
}

interface UpdateFileStatusParams {
	documentId?: string;
	isSuccessful: boolean;
}

interface Props {
	application: UserApplication;
	signatory: SignatoryWithId;
	updateRepresentatives: () => void;
	pendingItems: StatusPendingAction[];
	getPendingItems: () => void;
}

const Signatures = ({
	application,
	signatory,
	updateRepresentatives,
	pendingItems,
	getPendingItems,
}: Props) => {
	const { t } = useTranslation();
	const [tinForm] = Form.useForm();
	const [submitForm] = Form.useForm();
	const [loadingUpload, setLoadingUpload] = useState(false);
	const [loadingSubmit, setLoadingSubmit] = useState(false);
	const [loanContractUrl, setLoanContractUrl] = useState<string>();
	const guarantorAgreementUploadRef = useRef<HTMLInputElement>(null);
	const [guarantorAgreementFile, setGuarantorAgreementFile] = useState<Document | undefined>();

	const status = getStatus(application.status);

	const isStepCompleted = (actionType: PendingActionType): boolean => {
		const action = pendingItems.find((item) => item.action === actionType);
		return !action?.errors?.some((error) => error.signatories?.includes(signatory.signatory_id));
	};

	const completedTinStep = isStepCompleted(PendingActionType.SignatoriesTaxidSpecified);
	const completedVideoStep = isStepCompleted(PendingActionType.SignatoriesIdentCompleted);
	const completedGuarantorAgreementStep = isStepCompleted(
		PendingActionType.GuarantorContractUploaded
	);

	const showVideoStep = signatory.requirements?.is_video_id_required;
	const showGuarantorAgreementStep = signatory.requirements?.is_ubo_signature_required;

	const isSigned = [
		completedTinStep,
		completedGuarantorAgreementStep,
		...(showVideoStep ? [completedVideoStep] : []),
		isStepCompleted(PendingActionType.SepaMandateAccepted),
		isStepCompleted(PendingActionType.LegalSignatureAccepted),
	].every((isCompleted) => isCompleted);

	const showTinStep = !isSigned;
	const showTCStep = !isSigned && signatory.requirements?.is_loan_signature_required;

	const stepsToDisplay = [
		...(showTinStep ? [Step.TIN] : []),
		...(showVideoStep ? [Step.VIDEO] : []),
		...(showGuarantorAgreementStep ? [Step.GUARANTOR_AGREEMENT] : []),
		...(showTCStep ? [Step.TC] : []),
	];

	const termsDisabled = [
		completedTinStep,
		completedGuarantorAgreementStep,
		...(showVideoStep ? [completedVideoStep] : []),
	].some((stepCompletion) => !stepCompletion);

	const alertMsg =
		status === Status.NOT_COMPLETED
			? t("application.signatures-alert")
			: status === Status.IN_REVIEW_AFTER_SIGNATURE
			? t("application.signatures-complete-alert")
			: "";

	const handleUpdateTin = ({ taxid }: { taxid: string }) => {
		if (taxid && signatory.signatory_id) {
			Axios.patch<
				User.SignatoriesPartialUpdate.RequestBody,
				User.SignatoriesPartialUpdate.ResponseBody
			>(`/user/signatories/${signatory.signatory_id}`, {
				...signatory,
				taxid: parseTin(taxid),
			}).then(
				() => {
					void message.success(t("application.tin-was-updated"));
					updateRepresentatives();
					getPendingItems();
				},
				(err: ErrorResponse) => void Axios.error(err, t("error-messages.error-try-again"))
			);
		}
	};

	const getUploadInfo = async (file: File) => {
		if (!application.applicationId) return;
		const uploadResponse = await Axios.post<UploadDocumentPayload, UploadDocumentResponse>(
			`/user/applications/${application.applicationId}/documents`,
			{
				file_name: file.name,
				document_category: DocumentCategory.GuarantorContractSigned,
				related_entity_kind: RelatedEntityKind.Signatory,
				related_entity_id: signatory.signatory_id,
			}
		);

		return uploadResponse?.data;
	};

	const uploadFile = async (file: File, url?: string) => {
		if (!url) return;
		await Axios.put(url, file, {
			headers: {
				"Content-Type": file.type,
				"x-ms-blob-type": "BlockBlob",
			},
		});
	};

	const updateFileStatus = async ({ documentId, isSuccessful }: UpdateFileStatusParams) => {
		if (!documentId) return;

		await Axios.patch(
			`/user/applications/${application.applicationId}/documents/${documentId}/upload_success`,
			{
				is_upload_successful: isSuccessful,
			}
		);
	};

	const getDocuments = async () => {
		const params: User.ApplicationsDocumentsDetail.RequestQuery = {
			document_category: DocumentCategory.GuarantorContractSigned,
			related_entity_kind: RelatedEntityKind.Signatory,
			related_entity_id: signatory.signatory_id,
		};

		return Axios.get<DocumentsResponse>(
			`/user/applications/${application.applicationId}/documents`,
			{ params }
		).then(
			({ data }) => setGuarantorAgreementFile(data.result[0]),
			(err: ErrorResponse) =>
				void Axios.error(err, t("error-messages.error-getting-guarantor-agreement"))
		);
	};

	const handleUploadGuarantorAgreement = async (e: ChangeEvent<HTMLInputElement>) => {
		if (!e.target.files) return;
		setLoadingUpload(true);
		const file = e.target.files[0];
		try {
			const uploadInfo = await getUploadInfo(file);
			const { document_id, upload_url } = uploadInfo || {};

			try {
				await uploadFile(file, upload_url);
				await updateFileStatus({
					documentId: document_id,
					isSuccessful: true,
				});
				await getDocuments();
				void message.success(t("dashboard.file-upload-success"));
			} catch (err) {
				await updateFileStatus({
					documentId: document_id,
					isSuccessful: false,
				});
				void Axios.error(err as ErrorResponse, t("error-messages.file-upload-fail"));
			}
		} catch (err) {
			void Axios.error(err as ErrorResponse, t("error-messages.file-upload-fail"));
		} finally {
			setLoadingUpload(false);
			getPendingItems();
		}

		if (guarantorAgreementUploadRef?.current?.value) {
			guarantorAgreementUploadRef.current.value = "";
		}
	};

	const handleDownload = () => {
		if (!application.applicationId || !guarantorAgreementFile?.document_id) return;
		Axios.get<DocumentUrlResponse>(
			`/user/applications/${application.applicationId}/documents/${guarantorAgreementFile.document_id}/download_url`
		)
			.then(({ data }) => window.open(data.download_url))
			.catch((err) => void Axios.error(err as ErrorResponse, t("error-messages.error-try-again")));
	};

	const handleDelete = async () => {
		if (!application.applicationId || !guarantorAgreementFile?.document_id) return;
		try {
			await Axios.delete(
				`/user/applications/${application.applicationId}/documents/${guarantorAgreementFile.document_id}`
			);
			await getDocuments();
			void message.success(t("dashboard.file-delete-success"));
			getPendingItems();
		} catch (err) {
			void Axios.error(err as ErrorResponse, t("error-messages.file-delete-fail"));
		}
	};

	const handleSubmitApplication = () => {
		setLoadingSubmit(true);
		Axios.patch<
			User.ApplicationsSignatoriesRequirementsPartialUpdate.RequestBody,
			User.ApplicationsSignatoriesRequirementsPartialUpdate.ResponseBody
		>(
			`/user/applications/${application.applicationId}/signatories/${signatory.signatory_id}/requirements`,
			{
				signatory_id: signatory.signatory_id,
				application_id: application.applicationId,
				sepa_mandate_accepted: true,
				legal_signature_accepted: true,
			}
		)
			.then(() => getPendingItems())
			.catch(
				(err: ErrorResponse) => void Axios.error(err, t("error-messages.something-went-wrong"))
			)
			.finally(() => setLoadingSubmit(false));
	};

	useEffect(() => {
		Axios.get<LoanContract>(`/user/applications/${application.applicationId}/loan_contract`).then(
			(response) => setLoanContractUrl(response.data.download_url),
			(err: ErrorResponse) => void Axios.error(err, t("error-messages.something-went-wrong"))
		);

		void getDocuments();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{alertMsg && (
				<Col span={24}>
					<Alert type="info" message={alertMsg} showIcon style={{ marginBottom: 24 }} />
				</Col>
			)}

			<Row gutter={[40, 40]}>
				<Col span={24}>
					<LoanDetails application={application} />
				</Col>

				{isSigned && (
					<Col span={24}>
						<Card>
							<SuccessCardWrapper>
								<CheckCirclOutlinedIcon />
								<Title level={3}>{t("application.signatures-complete-title")}</Title>
								<Text type="secondary">{t("application.signatures-complete-subtitle")}</Text>
							</SuccessCardWrapper>
						</Card>
					</Col>
				)}

				{stepsToDisplay.map((step, index) => {
					switch (step) {
						case Step.TIN:
							return (
								<Col span={24}>
									<Card
										title={
											<TitleWrapper>
												{completedTinStep && <CheckCircleIcon />}
												<Text>
													{`${index + 1}. `} {t("application.signatures-step-tin-title")}
												</Text>
											</TitleWrapper>
										}
									>
										<Row>
											<Col span={9}>
												<Form form={tinForm} layout="vertical" onFinish={handleUpdateTin}>
													<TinFormItem
														name="taxid"
														label={t("application.signatures-tin-label")}
														inputProps={{ placeholder: t("form.tax-number-placeholder") }}
														errorMsg={t("error-messages.tax-number-invalid")}
														initialValue={signatory.taxid}
													/>
													<ThemedButton
														type={completedTinStep ? "ghost" : "primary"}
														htmlType="submit"
													>
														{signatory.taxid ? t("update") : t("save")}
													</ThemedButton>
												</Form>
											</Col>
										</Row>
									</Card>
								</Col>
							);
						case Step.VIDEO:
							return (
								<Col span={24}>
									<Card
										title={
											<TitleWrapper>
												{completedVideoStep && <CheckCircleIcon />}
												<Text>
													{!isSigned && `${index + 1}. `}
													{signatory.requirements?.is_loan_signature_required
														? t("application.signatures-step-video-and-signature-title")
														: t("application.signatures-step-video-title")}
												</Text>
											</TitleWrapper>
										}
									>
										{completedVideoStep ? (
											<Text type="secondary">
												{`${t("application.completed-and-signed-on")} `}
												<strong>
													{dayjs(signatory.requirements?.signature_completed_at).format(
														yearMonthDay
													)}
												</strong>
											</Text>
										) : (
											<Space
												direction="vertical"
												size={24}
												style={{
													width: "100%",
												}}
											>
												<Text
													style={{
														whiteSpace: "pre-line",
													}}
												>
													{signatory.requirements?.is_loan_signature_required ? (
														<>
															{t("application.signatures-step-video-and-sign-content-1")}
															<ThemedExternalLink href={loanContractUrl} download>
																{t("application.signatures-step-video-content-2")}
															</ThemedExternalLink>
														</>
													) : (
														t("application.signatures-step-video-content-1")
													)}
													{t("application.signatures-step-video-content-3")}
												</Text>
												<IdentificationWrapper>
													<ThemedButton
														type="primary"
														icon={<VideoCameraOutlined />}
														href={signatory.requirements?.digital_signature_url}
														target="_blank"
													>
														{signatory.requirements?.is_loan_signature_required
															? t("application.start-video-identification-and-sign")
															: t("application.start-video-identification")}
													</ThemedButton>
													<img src={IdNowLogo} alt="id-now-logo" height={24} />
												</IdentificationWrapper>
											</Space>
										)}
									</Card>
								</Col>
							);
						case Step.GUARANTOR_AGREEMENT:
							return (
								<Col span={24}>
									<Card
										title={
											<TitleWrapper>
												{completedGuarantorAgreementStep && <CheckCircleIcon />}
												<Text>
													{!isSigned && `${index + 1}. `}
													{t("application.signatures-step-guarantor-title")}
												</Text>
											</TitleWrapper>
										}
									>
										{completedGuarantorAgreementStep ? (
											<>
												<Alert
													type="info"
													message={t("application.signatures-step-guarantor-alert")}
													showIcon
													style={{
														marginBottom: 24,
														border: "none",
													}}
												/>

												{guarantorAgreementFile ? (
													<IdentificationWrapper>
														<GuarantorAgreementFileWrapper>
															<FilePdfIcon />
															{guarantorAgreementFile.document_name}
															<Button
																type="text"
																size="small"
																onClick={handleDownload}
																icon={<DownloadOutlined />}
															/>
															{!isSigned && (
																<Button
																	type="text"
																	size="small"
																	onClick={() => {
																		void handleDelete();
																	}}
																	icon={<CloseOutlined />}
																/>
															)}
														</GuarantorAgreementFileWrapper>
													</IdentificationWrapper>
												) : (
													<div style={{ display: "flex", alignItems: "center" }}>
														<CloseCircleOutlined
															style={{ color: "var(--ant-error-color)", marginRight: 4 }}
														/>
														<Text type="danger">
															{t("error-messages.error-getting-guarantor-agreement")}
														</Text>
													</div>
												)}
											</>
										) : (
											<Space direction="vertical" size={24}>
												<Text
													style={{
														whiteSpace: "pre-line",
													}}
												>
													{t("application.signatures-step-guarantor-content-1")}
													{t("application.signatures-step-guarantor-content-2")}
													<ThemedExternalLink
														href={signatory.requirements?.guarantor_contract_url}
														download
													>
														{t("application.signatures-step-guarantor-content-3")}
													</ThemedExternalLink>
													{t("application.signatures-step-guarantor-content-4")}
													{t("application.signatures-step-guarantor-content-5")}
												</Text>
												<label htmlFor="guarantor-agreement-upload">
													<InputUpload
														ref={guarantorAgreementUploadRef}
														id="guarantor-agreement-upload"
														type="file"
														onChange={(evt) => {
															void handleUploadGuarantorAgreement(evt);
														}}
													/>
													<ThemedButton
														type="primary"
														icon={<UploadOutlined />}
														onClick={() => guarantorAgreementUploadRef?.current?.click()}
														loading={loadingUpload}
													>
														{t("application.upload-signed-agreement")}
													</ThemedButton>
												</label>
											</Space>
										)}
									</Card>
								</Col>
							);
						case Step.TC:
							return (
								<Col span={24}>
									<Card
										title={
											<TitleWrapper>
												<Text>
													{`${index + 1}. `}
													{t("application.signatures-step-terms-title")}
												</Text>
											</TitleWrapper>
										}
									>
										<Alert
											type="info"
											showIcon
											message={t("application.signatures-complete-previous-steps")}
											style={{ marginBottom: 16 }}
										/>
										<Form form={submitForm} layout="vertical" onFinish={handleSubmitApplication}>
											<TermsWrapper>
												<Form.Item
													name="terms1"
													valuePropName="checked"
													rules={[
														{
															validator: (_, value) =>
																value
																	? Promise.resolve()
																	: Promise.reject(
																			t("error-messages.terms-and-conditions-invalid")
																	  ),
														},
													]}
												>
													<Checkbox disabled={termsDisabled}>
														<Text>{t("application.signatures-step-terms-1")}</Text>
													</Checkbox>
												</Form.Item>
												<Form.Item
													name="terms2"
													valuePropName="checked"
													rules={[
														{
															validator: (_, value) =>
																value
																	? Promise.resolve()
																	: Promise.reject(
																			t("error-messages.terms-and-conditions-invalid")
																	  ),
														},
													]}
												>
													<Checkbox disabled={termsDisabled}>
														<Text>{t("application.signatures-step-terms-2")}</Text>
													</Checkbox>
												</Form.Item>
												<ThemedButton
													type="primary"
													size="large"
													style={{
														alignSelf: "center",
													}}
													htmlType="submit"
													loading={loadingSubmit}
													disabled={termsDisabled}
												>
													{t("application.submit-signature")}
												</ThemedButton>
											</TermsWrapper>
										</Form>
									</Card>
								</Col>
							);
					}
				})}
			</Row>
		</>
	);
};

export default Signatures;
