import styled, { css } from "styled-components";
import { Button as AntdButton } from "antd";

const ThemedButton = styled(AntdButton)`
	${(props) =>
		props.type === "primary" &&
		css`
			span {
				color: ${({ theme }) => !props.disabled && theme.color_cta_text};
			}
		`}

	${(props) =>
		props.type === "link" &&
		css`
			border: 0;
			box-shadow: none;

			span {
				color: ${({ theme }) => theme.color_link};
			}

			&:hover {
				opacity: 0.8;
			}
		`}
`;

export default ThemedButton;
