import { UserAction, UserActions } from "src/store/actions/userActions";

const IS_AUTHENTICATED_KEY = "persist:isAuthenticated";

export interface UserState {
	isAuthenticated: boolean;
	isProfileLoading: boolean;
	userId: string;
	email: string;
	emailVerified: boolean;
}

const defaultState: UserState = {
	isAuthenticated: localStorage.getItem(IS_AUTHENTICATED_KEY) === "true",
	isProfileLoading: false,
	userId: "",
	email: "",
	emailVerified: false,
};

const userReducer = function (state = defaultState, action: UserAction) {
	switch (action.type) {
		case UserActions.LOGIN:
			localStorage.setItem(IS_AUTHENTICATED_KEY, "true");
			return {
				...state,
				isAuthenticated: true,
			};
		case UserActions.LOGOUT:
			localStorage.removeItem(IS_AUTHENTICATED_KEY);
			return {
				...state,
				isAuthenticated: false,
			};
		case UserActions.SET_PROFILE_LOADING:
			return {
				...state,
				isLoading: action.payload,
			};
		case UserActions.SET_PROFILE:
			return {
				...state,
				...action.payload,
				isLoading: false,
			};
		case UserActions.RESET_PROFILE:
			return {
				...state,
				isProfileLoading: false,
				userId: "",
				email: "",
				emailVerified: false,
			};
	}

	return state;
};

export default userReducer;
