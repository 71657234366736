import { useState } from "react";
import { Typography, Form, Space, Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import EmailFormItem from "@ui/form/form-items/email-form-item/EmailFormItem";

import InputPassword from "src/components/form/InputPassword";
import { Routes } from "src/Router";
import { ContentCard } from "src/components/layout/LayoutComponents";

import { Button } from "./style/AuthenticationComponents";
import ThemedLink from "src/components/themed-ui/ThemedLink";
import { loginUser } from "src/utils/api/authentication";
import { usePageTitle } from "src/hooks/usePageTitle";

const { Title, Text } = Typography;

interface LoginCredentials {
	email: string;
	password: string;
}

const Login = () => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const [form] = Form.useForm();

	usePageTitle(t("pageTitle.login"));

	const onFinish = ({ email, password }: LoginCredentials) => {
		setLoading(true);
		loginUser(email, password)
			.then(() => {
				if (searchParams.get("application") === "true") {
					navigate(Routes.applicationResult);
				} else {
					navigate(Routes.dashboardApplications);
				}
			})
			.finally(() => setLoading(false));
	};

	return (
		<>
			<ContentCard>
				<Space direction="vertical" size={24}>
					<Title level={3}>{t("login")}</Title>
					<Form form={form} layout="vertical" onFinish={onFinish}>
						<EmailFormItem />
						<InputPassword
							name="password"
							rules={[
								{
									required: true,
									message: t("error-messages.no-empty"),
								},
							]}
						/>
						<Row>
							<Col span={24} style={{ textAlign: "center" }}>
								<Button type="primary" size="large" htmlType="submit" loading={loading}>
									{t("login")}
								</Button>
							</Col>
						</Row>
					</Form>
				</Space>
				<Text style={{ textAlign: "center", marginTop: 16 }}>
					{t("authentication.forgot-password-link-1")}{" "}
					<ThemedLink to={Routes.forgotPassword}>
						{t("authentication.forgot-password-link-2")}
					</ThemedLink>
				</Text>
			</ContentCard>
			<Space direction="vertical" size={4} align="center" style={{ width: "100%", marginTop: 40 }}>
				<Text style={{ textAlign: "center" }}>{t("authentication.new-application-link-1")}</Text>
				<ThemedLink to={Routes.applicationLoanInfo}>
					{t("authentication.new-application-link-2")}
				</ThemedLink>
			</Space>
		</>
	);
};

export default Login;
