import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Form, Space, Row, Col } from "antd";
import { useTranslation } from "react-i18next";

import EmailFormItem from "@ui/form/form-items/email-form-item/EmailFormItem";

import InputPassword from "src/components/form/InputPassword";
import ThemedLink from "src/components/themed-ui/ThemedLink";
import { Routes } from "src/Router";
import { Axios, ErrorResponse } from "src/utils/Axios";
import { ContentCard } from "src/components/layout/LayoutComponents";
import { loginUser } from "src/utils/api/authentication";
import { User } from "@teylor-tools/Api";
import { Button } from "./style/AuthenticationComponents";
import TermsAndConditionsCheckboxes from "src/components/form/TermsAndConditionsCheckboxes";
import { usePageTitle } from "src/hooks/usePageTitle";

const { Title, Text } = Typography;

interface RegistrationInput {
	email: string;
	password: string;
	terms: boolean;
	termsPartner: boolean;
}

const Register = () => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const [form] = Form.useForm();

	usePageTitle(t("pageTitle.register"));

	const onFinish = ({ email: emailAddress, password, terms, termsPartner }: RegistrationInput) => {
		setLoading(true);
		const email = emailAddress.toLowerCase();
		Axios.post<User.UsersCreate.RequestBody, User.UsersCreate.ResponseBody>("/user/users", {
			email,
			password,
			accepted_terms: terms,
			accepted_partner_terms: termsPartner,
		})
			.then(
				() => {
					loginUser(email, password).then(
						() => {
							navigate(Routes.dashboardApplications);
						},
						(error: ErrorResponse) => {
							void Axios.error(error, t("error-messages.login-wrong-email-or-password"));
						}
					);
				},
				(error: ErrorResponse) => {
					void Axios.error(error, t("error-messages.registration-error"));
				}
			)
			.finally(() => setLoading(false));
	};

	return (
		<>
			<ContentCard>
				<Space direction="vertical" size={24}>
					<Title level={3}>{t("authentication.register")}</Title>
					<Form form={form} layout="vertical" onFinish={onFinish}>
						<EmailFormItem />
						<InputPassword name="password" />
						<InputPassword
							name="passwordRepeat"
							label={t("authentication.password-repeat")}
							errorMessage={t("error-messages.password-repeat-invalid")}
							rules={[
								{
									required: true,
									validator: (_, passwordRepeat) =>
										passwordRepeat === form.getFieldValue("password")
											? Promise.resolve()
											: Promise.reject(t("error-messages.password-repeat-invalid")),
								},
							]}
						/>

						<TermsAndConditionsCheckboxes />

						<Row>
							<Col span={24} style={{ textAlign: "center" }}>
								<Button type="primary" size="large" htmlType="submit" loading={loading}>
									{t("authentication.register")}
								</Button>
							</Col>
						</Row>
					</Form>
				</Space>
			</ContentCard>
			<Space direction="vertical" size={4} align="center" style={{ width: "100%", marginTop: 40 }}>
				<Text style={{ textAlign: "center" }}>{t("authentication.already-have-account")}</Text>
				<ThemedLink to={Routes.login}>{t("authentication.click-to-login")}</ThemedLink>
			</Space>
		</>
	);
};

export default Register;
