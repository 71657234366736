import { Outlet } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";

import PageHeader from "src/components/layout/page-header/PageHeader";
import Error from "src/components/Error";
import { Layout, Content } from "src/components/layout/LayoutComponents";

const ApplicationPageLayout = () => {
	return (
		<Layout>
			<PageHeader />
			<Content>
				<ErrorBoundary FallbackComponent={Error}>
					<Outlet />
				</ErrorBoundary>
			</Content>
		</Layout>
	);
};

export default ApplicationPageLayout;
