import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Col, message, Modal, Row } from "antd";
import { PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import Text from "antd/es/typography/Text";
import styled from "styled-components";
import RepresentativeModal from "@ui/representative-modal/RepresentativeModal";

import ThemedButton from "src/components/themed-ui/ThemedButton";
import CompanyRepresentative from "src/pages/dashboard/components/CompanyRepresentative";
import { ApplicationStatuses, Signatory, SignatoryWithId } from "@teylor-tools/Api";
import { Axios, ErrorResponse } from "src/utils/Axios";
import CompanyRepresentativeEmpty from "src/pages/dashboard/components/CompanyRepresentativeEmpty";
import { mdScreen, minWidth } from "src/breakpoints";
import CompanyRepresentativeInfo from "src/pages/dashboard/components/CompanyRepresentativeInfo";
import Alert from "src/components/Alert";

interface Props {
	representatives: SignatoryWithId[];
	applicationId: string;
	applicationStatus: ApplicationStatuses;
	updateRepresentatives: () => void;
	getPendingItems: () => void;
	ownershipPercentageExceeded: boolean;
	noSoleSignatory: boolean;
}

const ButtonWrapper = styled(Col)`
	@media (min-width: ${minWidth(mdScreen)}) {
		text-align: right;
	}
`;

const CompanyRepresentativesList = ({
	representatives,
	applicationId,
	applicationStatus,
	updateRepresentatives,
	getPendingItems,
	ownershipPercentageExceeded,
	noSoleSignatory,
}: Props) => {
	const [isRepresentativeModalVisible, setIsRepresentativeModalVisible] = useState<boolean>(false);
	const [isInfoModalVisible, setIsInfoModalVisible] = useState<boolean>(false);

	const { t } = useTranslation();

	const addRepresentative = (signatory: Signatory) => {
		Axios.post(`/user/applications/${applicationId}/signatories`, {
			data: [signatory],
		}).then(
			() => {
				void message.success(t("dashboard.representative-added"));
				void updateRepresentatives();
				getPendingItems();
			},
			(err: ErrorResponse) => void Axios.error(err, t("error-messages.error-try-again"))
		);
	};

	const openAddModal = () => {
		setIsRepresentativeModalVisible(true);
	};

	const closeModal = () => {
		setIsRepresentativeModalVisible(false);
	};

	return (
		<>
			{isRepresentativeModalVisible && (
				<RepresentativeModal
					save={addRepresentative}
					isVisible={isRepresentativeModalVisible}
					close={closeModal}
				/>
			)}

			{representatives.length ? (
				<>
					{noSoleSignatory && (
						<Alert
							type="error"
							message={t("error-code.full.err_application_signatories_sole_or_atleast_2")}
							showIcon
							style={{ marginBottom: 24 }}
						/>
					)}

					{ownershipPercentageExceeded && (
						<Alert
							type="error"
							message={t("error-code.full.err_share_percentage_greater_then_100")}
							showIcon
							style={{ marginBottom: 24 }}
						/>
					)}
					{applicationStatus === ApplicationStatuses.Open && (
						<div style={{ marginBottom: 24 }}>
							<Row align="middle" gutter={[0, 16]}>
								<Col xs={{ span: 24, order: 2 }} md={{ span: 16, order: 1 }}>
									<Text type="secondary">
										<QuestionCircleOutlined
											style={{
												marginRight: 4,
											}}
										/>
										{t("dashboard.not-sure-who-add")}{" "}
										<ThemedButton
											type="link"
											onClick={() => setIsInfoModalVisible(true)}
											style={{
												padding: 0,
											}}
										>
											{t("dashboard.not-sure-who-add-link-text")}
										</ThemedButton>
									</Text>
								</Col>
								<ButtonWrapper xs={{ span: 24, order: 1 }} md={{ span: 8, order: 2 }}>
									<ThemedButton type="primary" icon={<PlusOutlined />} onClick={openAddModal}>
										{t("dashboard.add-representative")}
									</ThemedButton>
								</ButtonWrapper>
							</Row>
						</div>
					)}
					<Row gutter={[24, 24]}>
						{representatives.map((rep, index) => (
							<Col xs={24} md={12} key={index}>
								<CompanyRepresentative
									representative={rep}
									onDeleteOrUpdate={() => {
										updateRepresentatives();
										getPendingItems();
									}}
									onUpdateTin={() => updateRepresentatives()}
									applicationStatus={applicationStatus}
								/>
							</Col>
						))}
					</Row>
				</>
			) : (
				<CompanyRepresentativeEmpty addRepresentative={openAddModal} />
			)}

			<Modal
				visible={isInfoModalVisible}
				onCancel={() => setIsInfoModalVisible(false)}
				onOk={() => setIsInfoModalVisible(false)}
				cancelButtonProps={{
					style: {
						display: "none",
					},
				}}
				width={633}
			>
				<CompanyRepresentativeInfo />
			</Modal>
		</>
	);
};

export default CompanyRepresentativesList;
