import { UploadFile } from "antd/es/upload/interface";

import { DocumentCategory as Category } from "@teylor-tools/Api";
import { Documents } from "src/pages/dashboard/components/DocumentsUpload/DocumentsUpload";

export enum ActionType {
	SET_ALL_DOCUMENTS,
	ADD_DOCUMENT,
	UPDATE_DOCUMENT_STATUS,
	UPDATE_DOCUMENT_ID,
	REMOVE_DOCUMENT,
}

export type Action =
	| { type: ActionType.SET_ALL_DOCUMENTS; newDocs: Documents }
	| { type: ActionType.ADD_DOCUMENT; payload: { newDoc: UploadFile; category: Category } }
	| {
			type: ActionType.UPDATE_DOCUMENT_STATUS;
			payload: { category: Category; docId: string; newStatus: string };
	  }
	| {
			type: ActionType.UPDATE_DOCUMENT_ID;
			payload: { oldId: string; newId: string; category: Category };
	  }
	| { type: ActionType.REMOVE_DOCUMENT; payload: { id: string; category: Category } };

export const documentsUploadReducer = (state: Documents, action: Action) => {
	switch (action.type) {
		case ActionType.SET_ALL_DOCUMENTS:
			return { ...state, ...action.newDocs };
		case ActionType.ADD_DOCUMENT:
			return {
				...state,
				[action.payload.category]: [...state[action.payload.category], action.payload.newDoc],
			};
		case ActionType.UPDATE_DOCUMENT_STATUS: {
			const updatedDocuments = (state[action.payload.category] as UploadFile[]).map((doc) => {
				if (doc.uid === action.payload.docId) {
					return { ...doc, status: action.payload.newStatus };
				}
				return doc;
			});
			return { ...state, [action.payload.category]: updatedDocuments };
		}
		case ActionType.UPDATE_DOCUMENT_ID: {
			const { oldId, newId, category } = action.payload;
			const updatedDocuments = (state[category] as UploadFile[]).map((doc) => {
				if (doc.uid === oldId) {
					return { ...doc, uid: newId };
				}
				return doc;
			});
			return { ...state, [action.payload.category]: updatedDocuments };
		}
		case ActionType.REMOVE_DOCUMENT: {
			const documents = (state[action.payload.category] as UploadFile[]).filter(
				(doc) => doc.uid !== action.payload.id
			);
			return { ...state, [action.payload.category]: documents };
		}
		default:
			return { ...state };
	}
};
