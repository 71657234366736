import { Input, InputProps, Form } from "antd";
import { FormItemProps } from "antd/lib/form/FormItem";
import { useTranslations } from "../../../translations/translations";

interface Props extends FormItemProps {
	inputProps?: InputProps;
}

const HouseNumberFormItem = ({ inputProps, ...props }: Props) => {
	const t = useTranslations();

	return (
		<Form.Item
			label={t.houseNumber}
			{...props}
			rules={[
				{
					type: "string",
					max: 10,
					pattern: /^[A-Z0-9 _/]*$/i,
					message: t.fieldInvalid,
				},
				...(props.required
					? [
							{
								required: true,
								message: t.fieldRequired,
							},
					  ]
					: []),
				...(props.rules || []),
			]}
			validateFirst
		>
			<Input maxLength={10} placeholder={t.houseNumberPlaceholder} {...inputProps} />
		</Form.Item>
	);
};

export default HouseNumberFormItem;
