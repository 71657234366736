import { useState, Dispatch, Fragment } from "react";

import styled from "styled-components";
import { useTranslation } from "react-i18next";
import {
	Card,
	Row,
	Col,
	Typography,
	Space,
	Button,
	Divider as AntdDivider,
	message,
	Modal,
	Form,
	Input,
} from "antd";
import { ClockCircleOutlined, EditOutlined } from "@ant-design/icons";
import { isValid } from "iban";
import dayjs from "dayjs";

import ThemedButton from "src/components/themed-ui/ThemedButton";
import { ApplicationTabs, PendingItem } from "src/pages/dashboard/DashboardApplicationOverview";
import { Axios, ErrorResponse } from "src/utils/Axios";
import { UserApplication, User } from "@teylor-tools/Api";
import { Status } from "../utils/status";
import LoanDetails from "./LoanDetails";
import TinFormItem, { parseTin } from "@ui/form/form-items/tin-form-item/TinFormItem";
import { yearMonthDay } from "@teylor-tools/utils/dateFormats";

const { Text } = Typography;

const Property = styled(Col)`
	text-align: right;
`;

const PendingCardTitleWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const PendingCardSubtitle = styled(Text)`
	font-size: 14px;
	font-weight: 400;
	margin-top: 8px;
`;

const PendingItemWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const Divider = styled(AntdDivider)`
	margin: 16px 0;
`;

const AddTin = styled(Text)`
	color: ${({ theme }) => theme.color_link};
	cursor: pointer;
`;

const EditIcon = styled(EditOutlined)`
	color: ${({ theme }) => theme.color_link};
	margin-left: 4px;
`;

const BANK_DETAILS_FORM = "bank-details-form";
const TIN_FORM = "tin-form";

interface BankDetails {
	bankName: string;
	iban: string;
}

interface Props {
	application: UserApplication;
	status: Status;
	pendingItems: PendingItem[];
	getPendingItems: () => void;
	getApplication: () => void;
	setActiveTab: Dispatch<React.SetStateAction<ApplicationTabs>>;
}

const ApplicationOverview = ({
	application,
	status,
	pendingItems,
	getApplication,
	setActiveTab,
	getPendingItems,
}: Props) => {
	const { t } = useTranslation();
	const [loadingResendVerificationEmail, setLoadingResendVerificationEmail] = useState(false);
	const [isBankModalVisible, setIsBankModalVisible] = useState(false);
	const [isTinModalVisible, setIsTinModalVisible] = useState(false);

	const {
		applicationId,
		bankName,
		iban,
		taxIdNumber,
		companyName,
		companyType,
		street,
		houseNumber,
		companyPostCode,
		companyCountry,
		companyLocation,
		hrNumber,
		companyFoundationDate,
	} = application;

	const [form] = Form.useForm();

	const hasBankDetails = !!(bankName && iban);

	const resendVerificationEmail = () => {
		setLoadingResendVerificationEmail(true);
		Axios.post(`/user/account/resend_conf_email`, {})
			.then(
				() => void message.success(t("application.verification-email-send")),
				(error: ErrorResponse) => void Axios.error(error, t("error-messages.something-went-wrong"))
			)
			.finally(() => setLoadingResendVerificationEmail(false));
	};

	const handleUpdateBankInformation = ({ bankName, iban }: BankDetails) => {
		if (!applicationId) return;
		Axios.patch<BankDetails, UserApplication>(`/user/applications/${applicationId}/bankinfo`, {
			bankName,
			iban,
		}).then(
			() => {
				getApplication();
				setIsBankModalVisible(false);
				getPendingItems();
			},
			(error: ErrorResponse) => {
				void Axios.error(error, t("error-messages.something-went-wrong"));
			}
		);
	};

	const handleUpdateTin = ({ taxIdNumber }: { taxIdNumber: string }) => {
		Axios.patch<
			User.ApplicationsPartialUpdate.RequestBody,
			User.ApplicationsPartialUpdate.ResponseBody
		>(`/user/applications/${applicationId}`, { taxIdNumber: parseTin(taxIdNumber) }).then(
			() => {
				getApplication();
				setIsTinModalVisible(false);
				getPendingItems();
			},
			(error: ErrorResponse) => {
				void Axios.error(error, t("error-messages.something-went-wrong"));
			}
		);
	};

	return (
		<>
			<Row gutter={[40, 40]}>
				<Col span={24}>
					<LoanDetails application={application} />
				</Col>
				{!!pendingItems?.length && (
					<Col span={24}>
						<Card
							title={
								<PendingCardTitleWrapper>
									<Text type="danger">
										<ClockCircleOutlined
											style={{
												marginRight: 8,
											}}
										/>
										{t("application.pending-items")}
									</Text>
									<PendingCardSubtitle type="secondary">
										{t("application.pending-items-subtitle")}
									</PendingCardSubtitle>
								</PendingCardTitleWrapper>
							}
						>
							{pendingItems.map((item, idx) => {
								switch (item) {
									case PendingItem.EMAIL_VERIFICATION:
										return (
											<Fragment key={idx}>
												{!!idx && <Divider />}
												<PendingItemWrapper>
													<Text>{t("application.confirm-email")}</Text>
													<Button
														onClick={resendVerificationEmail}
														loading={loadingResendVerificationEmail}
													>
														{t("application.resend-email")}
													</Button>
												</PendingItemWrapper>
											</Fragment>
										);
									case PendingItem.BANK_INFORMATION:
										return (
											<Fragment key={idx}>
												{!!idx && <Divider />}
												<PendingItemWrapper>
													<Text>{t("application.add-bank-information")}</Text>
													<Button onClick={() => setIsBankModalVisible(true)}>{t("add")}</Button>
												</PendingItemWrapper>
											</Fragment>
										);
									case PendingItem.COMPANY_TIN:
										return (
											<Fragment key={idx}>
												{!!idx && <Divider />}
												<PendingItemWrapper>
													<Text>{t("application.add-tin")}</Text>
													<Button onClick={() => setIsTinModalVisible(true)}>{t("add")}</Button>
												</PendingItemWrapper>
											</Fragment>
										);
									case PendingItem.REPRESENTATIVES:
										return (
											<Fragment key={idx}>
												{!!idx && <Divider />}
												<PendingItemWrapper>
													<Text>{t("application.add-representatives")}</Text>
													<Button onClick={() => setActiveTab(ApplicationTabs.REPRESENTATIVES)}>
														{t("add")}
													</Button>
												</PendingItemWrapper>
											</Fragment>
										);
									case PendingItem.DOCUMENTS:
										return (
											<Fragment key={idx}>
												{!!idx && <Divider />}
												<PendingItemWrapper>
													<Text>{t("application.upload-documents")}</Text>
													<Button onClick={() => setActiveTab(ApplicationTabs.DOCUMENTS)}>
														{t("application.upload")}
													</Button>
												</PendingItemWrapper>
											</Fragment>
										);
									case PendingItem.SIGNATURE:
										return (
											<Fragment key={idx}>
												{!!idx && <Divider />}
												<PendingItemWrapper>
													<Text>{t("application.complete-digital-signature")}</Text>
													<Button onClick={() => setActiveTab(ApplicationTabs.SIGNATURES)}>
														{t("application.sign")}
													</Button>
												</PendingItemWrapper>
											</Fragment>
										);
								}
							})}
						</Card>
					</Col>
				)}
				<Col span={24}>
					<Card title={t("application.company-information")}>
						<Row gutter={32}>
							<Col xs={24} sm={12}>
								<Row gutter={8}>
									<Property span={8}>
										<Text type="secondary">{t("application.name")}:</Text>
									</Property>
									<Col>{companyName}</Col>
								</Row>
								<Row gutter={8}>
									<Property span={8}>
										<Text type="secondary">{t("application.legal-form")}:</Text>
									</Property>
									<Col>{companyType}</Col>
								</Row>
								<Row gutter={8}>
									<Property span={8}>
										<Text type="secondary">{t("application.address")}:</Text>
									</Property>
									<Col
										style={{
											whiteSpace: "pre-line",
										}}
									>{`${street || ""} ${houseNumber || ""} \n${companyPostCode || ""}, ${
										companyLocation || ""
									}\n${companyCountry || ""}`}</Col>
								</Row>
							</Col>
							<Col xs={24} sm={12}>
								<Row gutter={8}>
									<Property span={8}>
										<Text type="secondary">{t("application.hr-number")}:</Text>
									</Property>
									<Col>{hrNumber || "-"}</Col>
								</Row>
								<Row gutter={8}>
									<Property span={8}>
										<Text type="secondary">{t("application.founded")}:</Text>
									</Property>
									<Col>{dayjs(companyFoundationDate).format(yearMonthDay)}</Col>
								</Row>
								<Row gutter={8}>
									<Property span={8}>
										<Text type="secondary">{t("application.tax-number")}:</Text>
									</Property>
									<Col>
										{taxIdNumber ? (
											<>
												{taxIdNumber}
												{status === Status.NOT_COMPLETED && (
													<EditIcon
														onClick={() => {
															setIsTinModalVisible(true);
														}}
													/>
												)}
											</>
										) : (
											<AddTin onClick={() => setIsTinModalVisible(true)}>
												{t("application.add-tin")}
											</AddTin>
										)}
									</Col>
								</Row>
							</Col>
						</Row>
					</Card>
				</Col>
				<Col span={24}>
					<Card
						title={t("application.bank-information")}
						extra={
							hasBankDetails &&
							status === Status.NOT_COMPLETED && (
								<ThemedButton
									type="link"
									icon={<EditOutlined />}
									onClick={() => setIsBankModalVisible(true)}
								>
									{t("application.change")}
								</ThemedButton>
							)
						}
					>
						{hasBankDetails ? (
							<>
								<Row gutter={8}>
									<Property span={4}>
										<Text type="secondary">{t("application.bank-name")}:</Text>
									</Property>
									<Col>{bankName}</Col>
								</Row>
								<Row gutter={8}>
									<Property span={4}>
										<Text type="secondary">{t("application.iban")}:</Text>
									</Property>
									<Col>{iban}</Col>
								</Row>
							</>
						) : (
							<Space direction="vertical" size={16} align="center" style={{ width: "100%" }}>
								<Text type="secondary">{t("application.no-information-added")}</Text>
								<ThemedButton type="primary" onClick={() => setIsBankModalVisible(true)}>
									{t("application.add-bank-information")}
								</ThemedButton>
							</Space>
						)}
					</Card>
				</Col>
			</Row>
			<Modal
				title={t("application.company-bank-information")}
				visible={isBankModalVisible}
				onCancel={() => setIsBankModalVisible(false)}
				cancelText={t("cancel")}
				okText={t("save")}
				okButtonProps={{ htmlType: "submit", form: BANK_DETAILS_FORM }}
			>
				<Form
					id={BANK_DETAILS_FORM}
					form={form}
					layout="vertical"
					onFinish={handleUpdateBankInformation}
					initialValues={{
						bankName: bankName || "",
						iban: iban || "",
					}}
				>
					<Form.Item
						rules={[
							{
								required: true,
								min: 1,
								max: 100,
								message: t("error-messages.bank-name-invalid"),
							},
						]}
						label={t("application.bank-name")}
						name="bankName"
					>
						<Input maxLength={100} placeholder={t("application.bank-name-placeholder")} />
					</Form.Item>
					<Form.Item
						rules={[
							{
								min: 1,
								required: true,
								message: t("error-messages.no-empty"),
							},
							{
								validator: (_, iban: string = "") => {
									return !iban.includes("_") && isValid(iban)
										? Promise.resolve()
										: Promise.reject(t("error-messages.iban-invalid"));
								},
							},
						]}
						label={t("application.iban-label")}
						name="iban"
					>
						<Input maxLength={34} placeholder={t("application.iban-placeholder")} />
					</Form.Item>
				</Form>
			</Modal>
			<Modal
				title={t("application.company-tin")}
				visible={isTinModalVisible}
				onCancel={() => setIsTinModalVisible(false)}
				cancelText={t("cancel")}
				okText={t("save")}
				okButtonProps={{ htmlType: "submit", form: TIN_FORM }}
			>
				<Form
					id={TIN_FORM}
					form={form}
					layout="vertical"
					onFinish={handleUpdateTin}
					initialValues={{ taxIdNumber: taxIdNumber || "" }}
				>
					<TinFormItem
						name="taxIdNumber"
						rules={[{ required: true, message: t("form.field-required") }]}
						label={t("application.tin-for", {
							name: companyName,
						})}
						inputProps={{ placeholder: t("application.tin-placeholder") }}
						errorMsg={t("error-messages.tax-number-invalid")}
					/>
				</Form>
			</Modal>
		</>
	);
};

export default ApplicationOverview;
