import { useState } from "react";
import { Trans } from "react-i18next";
import styled from "styled-components";
import { Card, Row, Col, Typography, Form, message, Tag as AntdTag, Spin } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { ContentWrapper } from "src/components/layout/LayoutComponents";

import InputPassword from "src/components/form/InputPassword";
import { Axios, ErrorResponse } from "src/utils/Axios";
import { useSelector } from "react-redux";
import { RootState } from "src/store/reducers/rootReducer";
import ThemedButton from "src/components/themed-ui/ThemedButton";
import { usePageTitle } from "src/hooks/usePageTitle";
import Alert from "src/components/Alert";

const { Text } = Typography;

const Tag = styled(AntdTag)`
	border-radius: 50px;
`;

interface PasswordChangeParams {
	oldPassword: string;
	newPassword: string;
}

const DashboardSettings = () => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [loadingResendVerificationEmail, setLoadingResendVerificationEmail] = useState(false);
	const [form] = Form.useForm();
	const partnerState = useSelector((state: RootState) => state.partnerState);
	const { emailVerified, email, isProfileLoading } = useSelector(
		(state: RootState) => state.userState
	);

	usePageTitle(t("pageTitle.settings"));

	const resendVerificationEmail = () => {
		setLoadingResendVerificationEmail(true);
		Axios.post(`/user/account/resend_conf_email`, {})
			.then(
				() => void message.success(t("application.verification-email-send")),
				(error: ErrorResponse) => void Axios.error(error, t("error-messages.something-went-wrong"))
			)
			.finally(() => setLoadingResendVerificationEmail(false));
	};

	const onFinish = ({ oldPassword, newPassword }: PasswordChangeParams) => {
		setLoading(true);

		Axios.post(`/user/account/change_password`, {
			old_password: oldPassword,
			new_password: newPassword,
		})
			.then(
				() => {
					form.resetFields();
					void message.success(t("settings.password-change-success"));
				},
				(error: ErrorResponse) => {
					void Axios.error(error, t("error-messages.something-went-wrong"));
				}
			)
			.finally(() => {
				setLoading(false);
			});
	};

	const getEmailCardContent = () => {
		if (isProfileLoading) return <Spin />;

		if (!email) return <Alert message={t("error-messages.something-went-wrong")} type="error" />;

		return (
			<>
				<Text>{email}</Text>
				{emailVerified ? (
					<Tag color="green" style={{ marginLeft: 8 }}>
						{t("settings.verified")}
					</Tag>
				) : (
					<ThemedButton
						type="link"
						icon={<ReloadOutlined />}
						onClick={resendVerificationEmail}
						loading={loadingResendVerificationEmail}
					>
						{t("settings.resendVerificationEmail")}
					</ThemedButton>
				)}
			</>
		);
	};

	return (
		<ContentWrapper>
			<Row gutter={[24, 24]}>
				<Col span={24}>
					<Card title={t("settings.email")}>{getEmailCardContent()}</Card>
				</Col>
				<Col span={24}>
					<Card title={t("settings.password")}>
						<Form form={form} layout="vertical" onFinish={onFinish}>
							<InputPassword
								name="oldPassword"
								label={t("settings.password-old")}
								rules={[{ required: true }]}
							/>
							<InputPassword name="newPassword" label={t("settings.password-new")} />
							<InputPassword
								name="repeatPassword"
								label={t("settings.password-repeat")}
								rules={[
									{
										required: true,
										validator: (_, repeatPassword) =>
											repeatPassword === form.getFieldValue("newPassword")
												? Promise.resolve()
												: Promise.reject(t("error-messages.password-repeat-invalid")),
									},
								]}
							/>
							<ThemedButton type="primary" size="large" htmlType="submit" loading={loading}>
								{t("settings.change-password")}
							</ThemedButton>
						</Form>
					</Card>
				</Col>
				<Col span={24}>
					<Card title={t("settings.delete-account")}>
						<Text type="secondary">
							<Trans
								i18nKey="settings.delete-account-subtitle"
								values={{
									phone: partnerState.customer_support_phone,
									email: partnerState.customer_support_email,
								}}
							/>
						</Text>
					</Card>
				</Col>
			</Row>
		</ContentWrapper>
	);
};

export default DashboardSettings;
