import { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { Col, Row, Spin, Typography } from "antd";
import { MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import ApplicationSteps from "src/pages/application-form/components/ApplicationSteps";
import { ContentWrapper } from "src/components/layout/LayoutComponents";
import ApplicationFormCard from "src/pages/application-form/components/ApplicationFormCard";
import ApplicationFormButton from "src/pages/application-form/components/ApplicationFormButton";
import { Routes } from "src/Router";
import {
	emptyApplication,
	useApplicationForm,
} from "src/providers/application-form-provider/useApplicationForm";
import { Axios, ErrorResponse } from "src/utils/Axios";
import { ApplicationStatuses, UserApplication, User } from "@teylor-tools/Api";
import { RootState } from "src/store/reducers/rootReducer";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import { usePageTitle } from "src/hooks/usePageTitle";
import { parseTin } from "@ui/form/form-items/tin-form-item/TinFormItem";

const { Title, Text } = Typography;

const SpinWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-grow: 1;
`;

const Space = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 24px;
	text-align: center;
`;

const Box = styled.div`
	border: solid 1px var(--ant-primary-color);
	padding: 16px 24px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	text-align: center;
	height: 100%;
	white-space: pre-line;
`;

const ContactBox = styled.div`
	border: solid 1px var(--ant-primary-color);
	padding: 32px;
`;

const ContactItem = styled.div`
	display: flex;
	justify-content: center;
	gap: 8px;
	align-items: center;
`;

const IconStyle = css`
	font-size: 18px;
	color: var(--ant-primary-color);
`;

const MailIcon = styled(MailOutlined)`
	${IconStyle}
`;

const PhoneIcon = styled(PhoneOutlined)`
	${IconStyle}
`;

const MANDATORY_FIELDS = [
	"loanSize",
	"loanDuration",
	"companyName",
	"companyType",
	"companyPostCode",
	"hrNumber",
	"businessPurpose",
	"allBanksLoanPurpose",
	"purposeDescription",
];

export const getMonthlyRate = ({
	pricing,
	monthlyRate,
	representative_monthly_rate,
}: UserApplication) => {
	return (
		pricing?.offer_representative_monthly_rate || monthlyRate || representative_monthly_rate || null
	);
};

const ApplicationResult = () => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(true);
	const [offer, setOffer] = useState<UserApplication>();
	const navigate = useNavigate();
	const { applicationForm, setApplicationForm } = useApplicationForm();
	const partnerState = useSelector((state: RootState) => state.partnerState);
	const { defaultCurrency } = useSelector((state: RootState) => state.configState);
	const { currency } = useFormatter(defaultCurrency);

	usePageTitle(t("pageTitle.new-application"));

	const isFormValid = Object.keys(applicationForm)
		.filter((key) => MANDATORY_FIELDS.includes(key))
		.every((key) => applicationForm[key]);

	const monthlyRate = offer && getMonthlyRate(offer);

	useEffect(() => {
		// If user has accessed this page with no application form data,
		// redirect him to the settings page.
		if (!isFormValid) {
			navigate(Routes.dashboardSettings);
			return;
		}

		Axios.post<User.ApplicationsCreate.RequestBody, User.ApplicationsCreate.ResponseBody>(
			"/user/applications",
			{
				...applicationForm,
				currency: defaultCurrency,
				taxIdNumber: parseTin(applicationForm.taxIdNumber),
			}
		)
			.then(
				(response) => {
					setOffer(response.data.application);
				},
				(error: ErrorResponse) => {
					void Axios.error(error);
				}
			)
			.finally(() => {
				setLoading(false);
				// reset application form after response is received
				setApplicationForm(emptyApplication);
			});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (loading)
		return (
			<>
				<ApplicationSteps stepIndex={4} />
				<SpinWrapper>
					<Spin size="large" />
				</SpinWrapper>
			</>
		);

	if (!offer || offer.status === ApplicationStatuses.OfferRejected)
		return (
			<>
				<ApplicationSteps stepIndex={4} />
				<ContentWrapper>
					<ApplicationFormCard>
						<Space>
							<Title level={3}>{t("form.offer-rejected-title")}</Title>
							<Text>{t("form.offer-rejected-text-1")}</Text>
							{partnerState.customer_support_email && partnerState.customer_support_phone && (
								<>
									<Text strong>{t("form.offer-rejected-text-2")}</Text>
									<ContactBox>
										<ContactItem
											style={{
												marginBottom: 16,
											}}
										>
											<MailIcon />
											<Text strong>{partnerState.customer_support_email}</Text>
										</ContactItem>
										<ContactItem>
											<PhoneIcon />
											<Text strong>{partnerState.customer_support_phone}</Text>
										</ContactItem>
									</ContactBox>
								</>
							)}
							<Row>
								<Col span={24}>
									<ApplicationFormButton
										type="ghost"
										size="large"
										onClick={() => navigate(Routes.index)}
									>
										{t("form.offer-rejected-return")}
									</ApplicationFormButton>
								</Col>
							</Row>
						</Space>
					</ApplicationFormCard>
				</ContentWrapper>
			</>
		);

	return (
		<>
			<ApplicationSteps stepIndex={4} />
			<ContentWrapper>
				<ApplicationFormCard>
					<Space>
						<Title level={3}>{t("form.offer-success-title")}</Title>
						<Row gutter={[16, 16]}>
							<Col sm={8} xs={24}>
								<Box>
									<Text type="secondary">{t("form.offer-loan-amount")}</Text>
									<Title
										level={4}
										style={{
											marginTop: 12,
										}}
									>
										{currency(offer.loanSize, { showFractions: false })}
									</Title>
								</Box>
							</Col>
							<Col sm={8} xs={24}>
								<Box>
									<Text type="secondary">{t("form.offer-loan-duration")}</Text>
									<Title
										level={4}
										style={{
											marginTop: 12,
										}}
									>
										{offer.loanDuration} {t("form.months")}
									</Title>
								</Box>
							</Col>
							<Col sm={8} xs={24}>
								<Box>
									<Text type="secondary">{t("form.offer-monthly-rate")}</Text>
									<Title
										level={4}
										style={{
											marginTop: 12,
										}}
									>
										{monthlyRate ? currency(monthlyRate) : "-"}
									</Title>
								</Box>
							</Col>
						</Row>
						<Text type="secondary">
							{t("form.offer-success-offer-id")} {offer.shortApplicationId}
						</Text>
						<Text>{t("form.offer-success-text-1")}</Text>
						<Text type="secondary">{t("form.offer-success-text-2")}</Text>

						<Row>
							<Col span={24}>
								<ApplicationFormButton
									type="primary"
									size="large"
									onClick={() =>
										offer.applicationId &&
										navigate(
											generatePath(Routes.dashboardApplicationOverview, {
												id: offer.applicationId,
											})
										)
									}
								>
									{t("form.complete-application")}
								</ApplicationFormButton>
							</Col>
						</Row>
					</Space>
				</ApplicationFormCard>
			</ContentWrapper>
		</>
	);
};

export default ApplicationResult;
