import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Typography, Form, Space, Row, Col } from "antd";
import { useTranslation } from "react-i18next";

import InputPassword from "src/components/form/InputPassword";
import { Routes } from "src/Router";
import { Axios, ErrorResponse } from "src/utils/Axios";
import { ContentCard } from "src/components/layout/LayoutComponents";
import { User } from "@teylor-tools/Api";
import { Button, CheckCircleIcon } from "./style/AuthenticationComponents";
import TermsAndConditionsCheckboxes from "src/components/form/TermsAndConditionsCheckboxes";
import { usePageTitle } from "src/hooks/usePageTitle";

const { Title, Text } = Typography;

interface RegistrationInput {
	password: string;
	terms: boolean;
	termsPartner: boolean;
}

const RegisterInvitation = () => {
	const { t } = useTranslation();
	const [registerSuccess, setRegisterSuccess] = useState(false);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const { token } = useParams() as { token: string };
	const [form] = Form.useForm();

	usePageTitle(t("pageTitle.register"));

	const onFinish = ({ password, terms, termsPartner }: RegistrationInput) => {
		setLoading(true);
		Axios.post<
			User.AccountInviteAcceptCreate.RequestBody,
			User.AccountInviteAcceptCreate.ResponseBody
		>("/user/account/invite_accept", {
			password,
			token,
			accepted_terms: terms,
			accepted_partner_terms: termsPartner,
		})
			.then(
				() => {
					setRegisterSuccess(true);
				},
				(error: ErrorResponse) => {
					void Axios.error(error, t("error-messages.registration-error"));
				}
			)
			.finally(() => setLoading(false));
	};

	if (registerSuccess) {
		return (
			<ContentCard>
				<CheckCircleIcon />
				<Title level={3} style={{ textAlign: "center" }}>
					{t("authentication.registration-success-title")}
				</Title>
				<Text type="secondary" style={{ textAlign: "center" }}>
					{t("authentication.registration-success-subtitle")}
				</Text>
				<Button
					type="primary"
					size="large"
					style={{ marginTop: 48 }}
					onClick={() => navigate(Routes.login)}
				>
					{t("authentication.go-to-login")}
				</Button>
			</ContentCard>
		);
	}

	return (
		<ContentCard>
			<Space direction="vertical" size={24}>
				<div>
					<Title level={3}>{t("authentication.set-password")}</Title>
					<Text type="secondary">{t("authentication.set-password-subtitle")}</Text>
				</div>
				<Form form={form} layout="vertical" onFinish={onFinish}>
					<InputPassword name="password" label={t("authentication.password")} />
					<InputPassword
						name="passwordRepeat"
						label={t("authentication.password-repeat")}
						errorMessage={t("error-messages.password-repeat-invalid")}
						rules={[
							{
								required: true,
								validator: (_, passwordRepeat) =>
									passwordRepeat === form.getFieldValue("password")
										? Promise.resolve()
										: Promise.reject(t("error-messages.password-repeat-invalid")),
							},
						]}
					/>

					<TermsAndConditionsCheckboxes />

					<Row>
						<Col span={24} style={{ textAlign: "center" }}>
							<Button type="primary" size="large" htmlType="submit" loading={loading}>
								{t("authentication.set-password-button")}
							</Button>
						</Col>
					</Row>
				</Form>
			</Space>
		</ContentCard>
	);
};

export default RegisterInvitation;
