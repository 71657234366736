import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Card, Row, Col, Typography, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

import { UserApplication } from "@teylor-tools/Api";
import { useFormatter } from "@teylor-tools/hooks/formatter";

const { Text } = Typography;

const LoanDetailsBox = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const InfoIcon = styled(InfoCircleOutlined)`
	margin-left: 4px;
`;

interface Props {
	application: UserApplication;
}

const LoanDetails = ({ application }: Props) => {
	const { t } = useTranslation();
	const { currency } = useFormatter(application.currency);
	const { loanSize, loanDuration, pricing } = application;

	const isIndicative = !application.pricing.final_interest_rate;
	const colSpan = { xs: 24, sm: isIndicative ? 8 : 6 };

	return (
		<Card title={t("application.loan-details")}>
			<Row gutter={[16, 16]}>
				<Col {...colSpan}>
					<LoanDetailsBox>
						<Text strong>{currency(loanSize, { showFractions: false })}</Text>
						<Text type="secondary">{t("application.loan-amount")}</Text>
					</LoanDetailsBox>
				</Col>
				<Col {...colSpan}>
					<LoanDetailsBox>
						<Text strong>
							{loanDuration} {t("months")}
						</Text>
						<Text type="secondary">{t("application.term")}</Text>
					</LoanDetailsBox>
				</Col>
				<Col {...colSpan}>
					{isIndicative ? (
						<LoanDetailsBox>
							<Text strong>
								{isIndicative && pricing.offer_representative_monthly_rate
									? currency(pricing.offer_representative_monthly_rate)
									: "-"}
							</Text>
							<Text type="secondary">
								{t("application.indicative-monthly-rate")}
								<Tooltip title={t("application.indicative-monthly-rate-tooltip")}>
									<InfoIcon />
								</Tooltip>
							</Text>
						</LoanDetailsBox>
					) : (
						<LoanDetailsBox>
							<Text strong>
								{pricing.monthly_payment ? currency(pricing?.monthly_payment) : "-"}
							</Text>
							<Text type="secondary">{t("application.monthly-rate")}</Text>
						</LoanDetailsBox>
					)}
				</Col>
				{!isIndicative && (
					<Col {...colSpan}>
						<LoanDetailsBox>
							<Text strong>
								{pricing?.interest_rate ? `${(pricing.interest_rate * 100).toFixed(2)} %` : "-"}
							</Text>
							<Text type="secondary">{t("application.interest-rate")}</Text>
						</LoanDetailsBox>
					</Col>
				)}
			</Row>
		</Card>
	);
};

export default LoanDetails;
