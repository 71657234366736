import styled from "styled-components";
import { Link } from "react-router-dom";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Layout as AntdLayout, Typography, Divider as AntdDivider } from "antd";
import { useTranslation } from "react-i18next";

import ThemedLink from "src/components/themed-ui/ThemedLink";
import { ContentCard } from "src/components/layout/LayoutComponents";
import { Routes } from "src/Router";

import { useSelector } from "react-redux";

import { Layout, Content, ContentWrapperNarrow } from "src/components/layout/LayoutComponents";
import PageHeader from "src/components/layout/page-header/PageHeader";
import { RootState } from "src/store/reducers/rootReducer";
import { usePageTitle } from "src/hooks/usePageTitle";

const { Title, Text } = Typography;
const { Footer: AntdFooter } = AntdLayout;

const Footer = styled(AntdFooter)`
	background-color: #fbfbfb;
	text-align: center;
`;

const FooterLink = styled(Link)`
	margin: 4px;
	color: #00000073;
`;

const Divider = styled(AntdDivider)`
	margin: 32px 0;
`;

const QuestionCircleIcon = styled(QuestionCircleOutlined)`
	color: var(--teylor-light-gray);
	font-size: 112px;
	margin-top: 8px;
	margin-bottom: 24px;
`;

const NotFoundPage = () => {
	const { t } = useTranslation();
	const isLoggedIn = useSelector((state: RootState) => state.userState.isAuthenticated);
	const configLoaded = useSelector((state: RootState) => state.partnerState.configLoaded);

	usePageTitle(t("pageTitle.notFound"));

	if (!configLoaded) {
		return (
			<Layout>
				<Content style={{ height: "100vh", justifyContent: "center" }}>
					<ContentWrapperNarrow>
						<ContentCard style={{ margin: 0 }}>
							<QuestionCircleIcon />
							<Title level={3} style={{ textAlign: "center" }}>
								{t("not-found-page.title")}
							</Title>
							<Text strong type="secondary" style={{ textAlign: "center" }}>
								{t("not-found-page.subtitle")}
							</Text>
						</ContentCard>
					</ContentWrapperNarrow>
				</Content>
			</Layout>
		);
	}

	return (
		<Layout>
			<PageHeader transparentBg />
			<Content>
				<ContentWrapperNarrow>
					<ContentCard>
						<QuestionCircleIcon />
						<Title level={3} style={{ textAlign: "center" }}>
							{t("not-found-page.title")}
						</Title>
						<Text strong type="secondary" style={{ textAlign: "center" }}>
							{t("not-found-page.subtitle")}
						</Text>
						<Divider />
						<Text type="secondary">{t("not-found-page.links")}</Text>
						<ThemedLink to={Routes.index}>{t("not-found-page.link-1")}</ThemedLink>
						<ThemedLink to={Routes.applicationLoanInfo}>{t("not-found-page.link-2")}</ThemedLink>
					</ContentCard>
				</ContentWrapperNarrow>
			</Content>
			{!isLoggedIn && (
				<Footer>
					<FooterLink to="/">{t("terms-and-conditions")}</FooterLink>
					<Text type="secondary">|</Text>
					<FooterLink to="/">{t("privacy-policy")}</FooterLink>
					<Text type="secondary">|</Text>
					<FooterLink to="/">{t("imprint")}</FooterLink>
				</Footer>
			)}
		</Layout>
	);
};

export default NotFoundPage;
